// import {PubSub} from './PubSub.js';
import {BehaviorSubject} from 'rxjs';
import {buf} from 'crc-32';

class ThreadPool {
    constructor(size){
        this.size = size;
        this.workerScript = new URL('./CRC32Work.js', import.meta.url);
        this.workers = [];
        this.taskQueue = [];
        this.result=[];
        this.pubSub = new BehaviorSubject(0);

        for(let i=0;i<size;i++){
            let worker = new Worker(this.workerScript);
            worker.onmessage = (e)=>{
                
            }
            this.workers.push({worker,busy:false});
        }
    }

    async exec(paramas){//paramas:ArrayBuffer[]
        //console.log(paramas);
        this.taskQueue=paramas;
        //console.log(this.taskQueue);
        return await new Promise((resolve,reject)=>{
            this.pubSub.subscribe((count)=>{
                //console.log(this.pubSub.value,this.taskQueue.length);
                if(count<this.size && this.taskQueue.length){
                    //console.log(count);
                    let canWorkCount = this.size - count;
                    if(canWorkCount>this.taskQueue.length){
                        canWorkCount = this.taskQueue.length;
                    }
                    //console.log(canWorkCount);
                    const canUseWorkers = this.workers.filter(worker=>!worker.busy);
                    //console.log(canUseWorkers);
                    const paramasRun = this.taskQueue.splice(0,canWorkCount);
                    //console.log(paramasRun);
                    this.pubSub.next(this.pubSub.value+canWorkCount);
                    canUseWorkers.forEach((worker,index)=>{
                        worker.busy = true;
                        let nowWorker=worker.worker;
                        let nowParamas = paramasRun[index];
                        //console.log(nowParamas);
                        new Promise((resolve,reject)=>{
                            nowWorker.onmessage=({data})=>{
                                worker.busy = false;
                                //console.log(data);
                                resolve(data);
                            }
                            nowWorker.onerror=(e)=>{
                                reject(e);
                            }
                            nowWorker.postMessage(nowParamas.buffer,[nowParamas.buffer]);
                        }).then((res)=>{
                            res.crc=buf(new Uint8Array(res.chunk));
                            this.result[nowParamas.index]=res;
                        }).catch((e)=>{
                            //console.log(e);
                            this.result[nowParamas.index]=e;
                        }).finally(()=>{
                            this.pubSub.next(this.pubSub.value-1);
                        });
                    });
                }
                if(this.pubSub.value===0 && this.taskQueue.length ===0){
                    //console.log(this.result);
                    resolve(this.result);
                }
            });
            
        });
    }
}


export {ThreadPool};