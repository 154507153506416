import styled from "styled-components";
import { gsap } from 'gsap';
import { observer } from "mobx-react";
import { useLayoutEffect, useRef } from "react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { isRenderStore } from "../../mobx/render/isRender";


const Content=styled.div({});
const Container = styled.div`
  width: 60%;
  height: 3.5%;
  position: absolute;
  left: 20%;
  top: 92%;
  opacity: 0;
  &::before{
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(3px);
    background: linear-gradient(to right,transparent 0% , white 50%,transparent 100%);
  }
  ${Content}{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-family: one;
    text-align: center;
    line-height:3.2vh;
    font-weight: 400;
    font-size: 1vw;
    cursor: pointer;
  }
`;

function FifthCheck({nagative}) {

  const checkRef=useRef();
  useLayoutEffect(()=>{
    let ctx=gsap.context(()=>{
      timelineStore.fifthLine.fifthCheckDo(checkRef);
    },checkRef.current);
    return ()=>{
      ctx.revert();
    }
  },[]);
  if(isRenderStore.fifthRender.fifthUnloadStart){
    timelineStore.fifthLine.fifthCheckUnload(checkRef);
  }
  return (
    <Container ref={checkRef}>
        <Content onClick={()=>{nagative('/projects');}}>Check More</Content>
    </Container>
  );
}
const FifthCheckOb=observer(FifthCheck);
export { FifthCheckOb };