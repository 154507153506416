import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

class SpaceTimeLineStore {
    spaceTimeLine=gsap.timeline();
    inState = null;
    outState = null;
    constructor() {
        makeAutoObservable(this);
    }

    setInState = (state) => {
        this.inState = state;
    }
    setOutState = (state) => {
        this.outState = state;
    }

    spaceBlockDo = (blockRef, colordata) => {
        this.spaceTimeLine.addLabel('logstart',0.45);
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.spaceTimeLine.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: colordata,
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1' : '');
        }
    }
    spaceWhiteFontDo = (fontRef) => {
        this.spaceTimeLine.to(fontRef.current, {
            left: '0',
            duration: 1,
        }, 'logstart');
        this.spaceTimeLine.to(fontRef.current, {
            opacity: 1,
            duration: 1,
        }, 'logstart+=0.2');
    }
    spaceBlackFontDo = (fontRef) => {
        this.spaceTimeLine.to(fontRef.current, {
            right: '0',
            duration: 1,
        }, 'logstart');
        this.spaceTimeLine.to(fontRef.current, {
            opacity: 1,
            duration: 1,
        }, 'logstart+=0.2');
    }
    spaceInit=(ref,isIn)=>{
        if(ref.current && isIn){
            this.setInState(Flip.getState(ref.current,{props:['opacity']}));
            ref.current.style.opacity="1";
            Flip.from(this.inState,{
                duration:0.5,
            });
        }else if(ref.current && !isIn){
            this.setOutState(Flip.getState(ref.current,{props:['opacity']}));
            ref.current.style.top="1";
            Flip.from(this.outState,{
                duration:0.5,
            });
        }
    }
    
}

const spaceTimeLine = new SpaceTimeLineStore();
export {spaceTimeLine };