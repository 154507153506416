import styled from "styled-components";
import { SecondActOb } from "../components/second/SecondAct";
import { SecondTitleOb } from "../components/second/SecondTitle";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { timelineStore } from "../mobx/timeline/timeLine";
import { isRenderStore } from "../mobx/render/isRender";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { useEffect, useState } from "react";
import { service } from "../mobx/service/service";



const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;



export function SecondPage({nagative}) {
  const [actData, setActData] = useState(null);
  useEffect(()=>{
    service.editpage
      .getAllActivityMethod()
      .then((res) => {
        setActData(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        alert("获取活动失败");
      });
  },[]);
  return (
    <Container onWheel={(e)=>{
      if(e.deltaY>0){
        isRenderStore.secondRender.setUpOrDown(1);
      }else{
        isRenderStore.secondRender.setUpOrDown(0);
      }
      isRenderStore.secondRender.wheelDown();
    }}>
      <NormalFontBOb
        isFront={true}
        animation={timelineStore.secondline.secondFontDo}
        $width={"55%"}
        $height={"19%"}
        $align={"flex-start"}
        $justify={"space-evenly"}
        $top={'3.5%'}
        $left={'-10%'}
        $letterspace={'0.3vw'}
        $fontsize={'5.3vw'}
      ></NormalFontBOb>
      <NormalBlockOb
        $width={"100%"}
        $height={"81%"}
        $position={"absolute"}
        $top={'19%'}
        colordata={["black", "rgb(96, 119, 175)", "rgb(102, 127, 191)"]}
        number={8}
        animation={timelineStore.secondline.secondBlockDo}
        islay={false}
        isunload={true}
      ></NormalBlockOb>
      <SecondActOb
        $width={'40%'}
        $height={'36%'}
        $left={'30%'}
        $top={'calc(14% + 100px)'}
        isunload={true}
      ></SecondActOb>
      {actData && <SecondTitleOb actData={actData} nagative={nagative}></SecondTitleOb>}
    </Container>
  );
}
