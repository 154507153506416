import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import search from "../../assets/png/search.png";
import upload from "../../assets/png/upload.png";
import de from "../../assets/png/delete.png";
import { ThreadPool } from "../../utils/ThreadPool.js";
import { service } from "../../mobx/service/service.js";

const Title = styled.section({});
const Context = styled.section({});
const RowContainer = styled.section({});
const Team = styled.section({});
const Permission = styled.section({});
const Persons = styled.section({});
const Imgs = styled.section({});
const ImgFilter = styled.div({});
const Container = styled.div`
  position: absolute;
  left: 24%;
  top: 25%;
  width: 55%;
  height: 72%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  //background-color: aliceblue;
  ${Title}, ${Context} {
    position: relative;
    font-family: one;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 0.85vw;
      font-weight: 600;
      margin-right: 1vw;
      margin-top: 0.4vh;
      width: 7%;
    }
    textarea {
      resize: none;
      outline: none;
      border: 1px solid rgb(177, 175, 175);
      border-radius: 0.5vh;
      padding: 0.5vh 1vh;
      font-family: one;
    }
    .limit {
      position: absolute;
      color: rgb(141, 138, 138);
    }
  }
  ${Title} {
    textarea {
      width: 85%;
      height: 1.5vh;
    }
    .limit {
      right: 5%;
    }
  }
  ${Context} {
    textarea {
      width: 70%;
      height: 10vh;
    }
    .limit {
      bottom: 5%;
      right: 20%;
    }
  }
  ${RowContainer} {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${Team}, ${Permission}, ${Persons} {
      font-family: one;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      span {
        font-size: 0.85vw;
        font-weight: 600;
        margin-right: 1vw;
        margin-top: 0.4vh;
        width: 14%;
      }
      .switch {
        width: 40%;
        height: 2.5vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(185, 183, 183);
        border: 1px solid rgb(177, 175, 175);
        border-radius: 0.5vh;
        div {
          width: 47%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.65vw;
          border-radius: 0.5vh;
          color: white;
          cursor: pointer;
          transition: all 0.3s;
        }
        .choose {
          background-color: white;
          color: black;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
        }
      }
      .list {
        position: relative;
        width: 40%;
        height: 2.5vh;
        background-color: white;
        border: 1px solid rgb(177, 175, 175);
        border-radius: 0.5vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.65vw;
        .listchildren {
          position: absolute;
          z-index: 3;
          top: 110%;
          overflow: hidden;
          width: 100%;
          height: 0;
          background-color: white;
          transition: height 0.3s;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          border-radius: 0.5vh;
          div {
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.65vw;
          }
        }
        .icon {
          position: absolute;
          right: 5%;
          width: 0;
          height: 0;
          border-left: 0.5vh solid transparent;
          border-right: 0.5vh solid transparent;
          border-top: 0.5vh solid black;
        }
      }
    }
    ${Persons} {
      width: 50%;
      position: relative;
      span {
        width: 14%;
      }
      .select {
        position: relative;
        width: 60%;
        height: 2.5vh;
        border: 1px solid rgb(177, 175, 175);
        border-radius: 0.5vh;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        img {
          width: 1.5vh;
          height: 1.5vh;
          margin-right: 1vh;
          padding-left: 1vh;
          border-left: 1px solid rgb(177, 175, 175);
          cursor: pointer;
        }
        input {
          font-family: one;
          width: 80%;
          height: 100%;
          outline: none;
          border: none;
          padding-left: 1vh;
          font-size: 0.65vw;
          border-top-left-radius: 0.5vh;
          border-bottom-left-radius: 0.5vh;
        }
        &:hover {
          .person {
            display: block;
          }
        }
        .person {
          width: 100%;
          height: 18vh;
          position: absolute;
          top: 105%;
          background-color: #fff;
          border-radius: 0.8vh;
          border: 1px solid rgba(56, 55, 55, 0.4);
          z-index: 3;
          display: none;
          overflow: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }
          .pchoose {
            width: 100%;
            border-bottom: 1px solid rgba(56, 55, 55, 0.4);
            .tips {
              width: 50%;
              margin: 0 auto;
              text-align: center;
            }
          }
          .pdata,
          .pchoose {
            width: 90%;
            padding: 0 5%;
            .pitem {
              width: 100%;
              height: 3vh;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .psection {
                width: 60%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                img {
                  width: 1.5vh;
                  height: 1.5vh;
                  margin-right: 1vh;
                  border-radius: 50%;
                  padding: 0;
                  border: 1px solid rgba(177, 175, 175, 0.4);
                }
              }
              .padd {
                width: 20%;
                height: 2vh;
                background-color: rgb(39, 114, 240);
                color: white;
                font-size: 0.65vw;
                font-family: one;
                border: none;
                border-radius: 0.5vh;
                cursor: pointer;
                transition: all 0.3s;
              }
            }
          }
        }
      }
      .filezip {
        width: 40%;
        height: 2.5vh;
        border: 1px solid rgb(177, 175, 175);
        border-radius: 0.5vh;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        input {
          visibility: hidden;
        }
      }
      .avaters {
        position: absolute;
        display: none;
        width: 55%;
        height: 2.5vh;
        margin-left: 1vw;
      }
    }
    button {
      width: 5vw;
      height: 3vh;
      background-color: rgb(39, 114, 240);
      color: white;
      font-size: 0.85vw;
      font-family: one;
      border: none;
      border-radius: 0.5vh;
      cursor: pointer;
      transition: all 0.3s;
      margin-left: calc(7% + 1vw);
    }
  }
  ${Imgs} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    font-family: one;
    .left {
      width: 7%;
      margin-right: 1vw;
      span {
        font-size: 0.85vw;
        font-weight: 600;
        margin-top: 0.4vh;
      }
    }
    .right {
      width: 85%;
      height: 17vh;
      //background-color: aqua;
      overflow-x: auto;
      display: flex;
      flex-direction: row;
      ${ImgFilter} {
        position: relative;
        width: 16vw;
        height: 90%;
        flex-shrink: 0;
        border: 1px solid rgb(177, 175, 175);
        border-radius: 0.5vh;
        cursor: pointer;
        margin-right: 1vw;
        input {
          border-radius: 0.5vh;
        }
        img,
        .appear,
        video {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 0.5vh;
        }
        img,
        video {
          z-index: 1;
        }
        .appear {
          z-index: 2;
          background-color: rgb(185, 183, 183);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .load {
            width: 2vw;
            height: 2vw;
            background-image: url(${upload});
            background-size: 100% 100%;
          }
          span {
            font-size: 0.65vw;
            margin-top: 0.4vh;
          }
        }
        .delete {
          position: absolute;
          width: 100%;
          height: 3vh;
          background-image: linear-gradient(to bottom, #2e2d2d, transparent);
          top: 0;
          right: 0;
          z-index: 3;
          display: none;
          justify-content: flex-end;
          .image {
            width: 1.5vh;
            height: 1.5vh;
            margin-right: 1vh;
            margin-top: 0.7vh;
            background-image: url(${de});
            background-size: 100% 100%;
          }
        }
      }
    }
  }
`;
function createChunks(arrayBuffer) {
  // 使用单独常量保存预设切片大小 2MB
  const chunkSize = 1024 * 1024 * 3; // 2MB
  const chunks = [];
  let start = 0;
  let index = 0;
  while (start < arrayBuffer.byteLength) {
    // 注意使用 byteLength 属性
    // 计算切片的结束位置，不能超过原始 buffer 的长度
    let end = Math.min(start + chunkSize, arrayBuffer.byteLength);
    // 从 ArrayBuffer 中切出一部分
    let curChunk = arrayBuffer.slice(start, end);
    chunks.push({
      buffer: curChunk, // 存储切片的 ArrayBuffer
      index: index, // 索引
    });
    index++;
    start += chunkSize; // 更新起始位置
  }
  // console.log(arrayBuffer, chunks);
  // console.log(chunks.length);
  return chunks;
}

function ImgFilters({
  isVideo = false,
  text = "上传图片",
  seindex,
  imgShow,
  setImgShow,
  data,
  videoData,
  setVideoData,
  imgData,
  setImgData,
  setControl,
  control,
}) {
  const inputRef = useRef(null);
  const imgRef = useRef(null);
  const appearRef = useRef(null);
  const deleteRef = useRef(null);
  return (
    <ImgFilter
      onClick={() => {
        inputRef.current.click();
      }}
    >
      {seindex === 0 ? null : (
        <div className="delete" ref={deleteRef}>
          <div
            className="image"
            onClick={(e) => {
              e.stopPropagation();
              let newdata = [...imgShow];
              imgRef.current.src = null;
              imgRef.current.style.zIndex = 1;
              appearRef.current.style.zIndex = 2;
              deleteRef.current.style.display = "none";
              if (seindex === 4 || newdata.length === 2) {
                newdata[seindex].data = "1";
                setImgShow([...newdata]);
              } else {
                setImgShow(
                  newdata.filter((item, index) => {
                    return index !== seindex;
                  })
                );
              }
            }}
          ></div>
        </div>
      )}
      <input
        type="file"
        ref={inputRef}
        accept={
          isVideo ? "video/mp4, video/webm, video/ogg" : ".jpg, .jpeg, .png"
        }
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
              const arrayBuffer = e.target.result;
              const uint8Array = new Uint8Array(arrayBuffer);

              const blob = new Blob([uint8Array], { type: file.type });
              const url = URL.createObjectURL(blob);
              imgRef.current.src = url;
              imgRef.current.style.zIndex = 2;
              appearRef.current.style.zIndex = 1;
              if (seindex !== 0) {
                deleteRef.current.style.display = "flex";
              }
              let newdata = [...imgShow];
              if (newdata[seindex].data !== "1" || seindex === 4) {
                newdata[seindex].data = url;
                newdata[seindex].filename = file.name;
                setImgShow([...newdata]);
              } else {
                newdata[seindex].data = url;
                newdata[seindex].filename = file.name;
                setImgShow([
                  ...newdata,
                  {
                    filename: "",
                    text: `上传`,
                    uniqe: url,
                    isActive: false,
                    isDone: false,
                    data: "1",
                  },
                ]);
              }
              setControl({ ...control, img: true });
              if (isVideo) {
                // 切片上传,图片不需要分片
                const chunks = createChunks(arrayBuffer);
                const threadPool = new ThreadPool(
                  Math.round(chunks.length / 2)
                );
                threadPool.exec(chunks).then((res) => {
                  // console.log(file);
                  // console.log(res);
                  let allHash = [];
                  res.map((item, index) => {
                    allHash.push(item.crc);
                    const uint8Array = new Uint8Array(item.chunk);
                    const blob = new Blob([uint8Array.buffer]);
                    service.project.uploadChunkMethod({
                      index: index,
                      totalName: file.name,
                      hash: item.crc,
                      file: blob,
                    });
                    return null;
                  });
                  setVideoData(
                    [
                      {
                        totalName: file.name,
                        totalHash: allHash,
                        total: res.length,
                        url: "",
                      },
                    ].concat(videoData)
                  );
                  setControl({ ...control, video: true });
                });
              } else {
                setImgData(
                  [
                    {
                      imgType: file.type ? file.type.split("/")[1] : ".png",
                      imgName: file.name,
                      imgFile: blob,
                    },
                  ].concat(imgData)
                );
              }
            };
            reader.readAsArrayBuffer(file);
          }
        }}
      />
      {isVideo ? (
        <video ref={imgRef} controls src={data} type="video/mp4"></video>
      ) : (
        <img ref={imgRef} src={data} alt="" />
      )}
      <div className="appear" ref={appearRef}>
        <div className="load"></div>
        <span>{text}</span>
      </div>
    </ImgFilter>
  );
}

const simgsData = [
  {
    filename: "",
    text: "第一张图片将作为作品封面",
    isActive: true,
    uniqe: "zzz",
    isDone: false,
    data: "1",
  },
];
const svideosData = [
  {
    filename: "",
    text: "请至少上传一个视频,需要H264编码",
    isActive: true,
    uniqe: "vvv",
    isDone: false,
    data: "1",
  },
];

function SpaceProject() {
  const [team, setTeam] = useState("game");
  const [permission, setPermission] = useState("请选择作品权限");
  const [imgShow, setImgShow] = useState(simgsData);
  const [videoShow, setVideoShow] = useState(svideosData);
  const listRef = useRef(null);
  const zipRef = useRef(null);

  const [videoData, setVideoData] = useState([]);
  const [imgData, setImgData] = useState([]);
  const [zipData, setZipData] = useState([]);
  const [normalData, setNormalData] = useState({
    title: "",
    desc: "",
  });
  const [pData, setPData] = useState([]);
  const [pInitData, setPInitData] = useState([]);
  const [pCData, setPCData] = useState([]);
  const [control, setControl] = useState({
    zip: false,
    img: false,
    video: false,
  });

  useEffect(() => {
    service.user
      .getAllUserNormalMethod()
      .then((res) => {
        // console.log(res);
        setPData(res.data.data);
        setPInitData(res.data.data);
      })
      .catch((error) => {
        alert("未知错误,请联系管理员.");
      });
  }, []);

  return (
    <Container>
      <Title>
        <span>项目名称</span>
        <textarea
          maxLength={100}
          value={normalData.title}
          onChange={(e) => {
            setNormalData({ ...normalData, title: e.target.value });
          }}
        ></textarea>
        <div className="limit">{normalData.title.length}/100</div>
      </Title>
      <RowContainer>
        <Team>
          <span>分类</span>
          <div className="switch">
            <div
              className={team === "game" ? "choose" : null}
              onClick={() => setTeam("game")}
            >
              游戏
            </div>
            <div
              className={team === "art" ? "choose" : null}
              onClick={() => setTeam("art")}
            >
              美术
            </div>
          </div>
        </Team>
        <Permission>
          <span>权限</span>
          <div
            className="list"
            tabIndex={0}
            onFocus={() => {
              listRef.current.style.height = "10vh";
              listRef.current.style.border = "1px solid rgb(211, 211, 211)";
            }}
            onBlur={() => {
              listRef.current.style.height = "0";
              listRef.current.style.border = "none";
            }}
          >
            {permission}
            <div className="icon"></div>
            <div className="listchildren" ref={listRef}>
              <div onClick={() => setPermission("public")}>公开</div>
              <div onClick={() => setPermission("private")}>私有</div>
              <div onClick={() => setPermission("team")}>团队可见</div>
            </div>
          </div>
        </Permission>
      </RowContainer>
      <RowContainer>
        <Persons>
          <span>协作者</span>
          <div className="select">
            <input
              type="text"
              placeholder="输入姓名搜索"
              onChange={(e) => {
                setPData(
                  pInitData.filter((item) => {
                    return item.user_name.includes(e.target.value);
                  })
                );
              }}
            />
            <img src={search} alt="" />
            <div className="person">
              <div className="pchoose">
                {pCData.length > 0 ? (
                  pCData.map((item, index) => {
                    return (
                      <div className="pitem" key={index}>
                        <section className="psection">
                          <img src={item.user_avater} alt="" />
                          {item.user_name}-{item.user_level}
                        </section>
                        <button
                          className="padd"
                          onClick={() => {
                            setPCData(
                              pCData.filter((sitem) => {
                                return sitem.user_name !== item.user_name;
                              })
                            );
                          }}
                        >
                          删除
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <div className="tips">请选择协作者</div>
                )}
              </div>
              <div className="pdata">
                {pData.map((item, index) => {
                  return (
                    <div className="pitem" key={index}>
                      <section className="psection">
                        <img src={item.user_avater} alt="" />
                        {item.user_name}-{item.user_level}
                      </section>
                      <button
                        className="padd"
                        onClick={() => {
                          if (
                            pCData.some((sitem) => {
                              return sitem.user_name === item.user_name;
                            })
                          ) {
                            alert("已经添加过了");
                            return;
                          }
                          setPCData([...pCData, item]);
                        }}
                      >
                        添加
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="avaters"></div>
        </Persons>
        <Persons>
          <span>压缩包</span>
          <div
            className="filezip"
            onClick={() => {
              zipRef.current.click();
            }}
          >
            <input
              type="file"
              accept=".zip,.7z,.rar"
              ref={zipRef}
              onChange={(e) => {
                const file = e.target.files[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = function (e) {
                    const arrayBuffer = e.target.result;
                    const chunks = createChunks(arrayBuffer);
                    const threadPool = new ThreadPool(
                      Math.round(chunks.length / 2)
                    );
                    threadPool.exec(chunks).then((res) => {
                      // console.log(file);
                      // console.log(res);
                      let allHash = [];
                      res.map((item, index) => {
                        allHash.push(item.crc);
                        const uint8Array = new Uint8Array(item.chunk);
                        const blob = new Blob([uint8Array.buffer]);
                        service.project.uploadChunkMethod({
                          index: index,
                          totalName: file.name,
                          hash: item.crc,
                          file: blob,
                        });
                        return null;
                      });
                      setZipData({
                        totalName: file.name,
                        totalHash: allHash,
                        total: res.length,
                        url: "",
                      });
                      setControl({ ...control, zip: true });
                    });
                  };

                  reader.readAsArrayBuffer(file);
                }
              }}
            />
          </div>
        </Persons>
      </RowContainer>
      <Imgs>
        <section className="left">
          <span>图片</span>
        </section>
        <section className="right">
          {imgShow.map((item, index) => {
            return (
              <ImgFilters
                text={item.text}
                seindex={index}
                key={item.uniqe}
                setImgShow={setImgShow}
                imgShow={imgShow}
                data={item.data}
                imgData={imgData}
                setImgData={setImgData}
                setControl={setControl}
                control={control}
              />
            );
          })}
        </section>
      </Imgs>
      <Imgs>
        <section className="left">
          <span>视频</span>
        </section>
        <section className="right">
          {/* <ImgFilters text={"至少请上传一个视频"} isVideo={true} seindex={0} />
          <ImgFilters text={"（可选）"} isVideo={true} /> */}
          {videoShow.map((item, index) => {
            return (
              <ImgFilters
                text={item.text}
                seindex={index}
                key={item.uniqe}
                setImgShow={setVideoShow}
                imgShow={videoShow}
                data={item.data}
                isVideo={true}
                videoData={videoData}
                setVideoData={setVideoData}
                setControl={setControl}
                control={control}
              />
            );
          })}
        </section>
      </Imgs>
      <Context>
        <span>项目简介</span>
        <textarea
          maxLength={1000}
          value={normalData.desc}
          onChange={(e) => {
            setNormalData({ ...normalData, desc: e.target.value });
          }}
        ></textarea>
        <div className="limit">{normalData.desc.length}/1000</div>
      </Context>
      <RowContainer>
        {control.zip &&
        control.img &&
        control.video &&
        permission !== "请选择作品权限" &&
        normalData.title.length > 0 &&
        normalData.desc.length > 0 ? (
          <button
            onClick={() => {
              const realImgData = [];
              const imgArray = {
                imgType: [],
                imgName: [],
                imgFile: [],
              };
              imgData.map((item) => {
                imgShow.map((sitem) => {
                  if (sitem.filename === item.imgName) {
                    realImgData.push(item);
                  }
                  return null;
                });
                return null;
              });
              realImgData.map((item) => {
                imgArray.imgType.push(item.imgType);
                imgArray.imgName.push(item.imgName);
                imgArray.imgFile.push(item.imgFile);
                return null;
              });

              const realVideoData = [];
              videoData.map((item) => {
                videoShow.map((sitem) => {
                  if (sitem.filename === item.totalName) {
                    realVideoData.push(item);
                  }
                  return null;
                });
                return null;
              });

              const personData = [];
              pCData.map((item) => {
                personData.push(item.user_username);
                return null;
              });
              //上传其他东西

              const data={
                title: normalData.title,
                description: normalData.desc,
                imgName: imgArray.imgName,
                permission: permission,
                persons: personData,
                team: team,
              };
              const formData = new FormData();
              Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
              });
              imgArray.imgFile.forEach(file => {
                formData.append('files', file);
              });
              imgArray.imgName.map(name => {
                formData.append('imgName', name);
                return null;
              });
    
              service.project
                .createProjectMethod(formData)
                .then((res) => {
                  // 合并
                  realVideoData.map((item) => {
                    service.project.mergeChunkMethod({
                      ...item,
                      title: normalData.title,
                      type: "video",
                    });
                    return null;
                  });
                  service.project.mergeChunkMethod({
                    ...zipData,
                    title: normalData.title,
                    type: "download",
                  });
                  //合并完成
                  alert("上传成功");
                  window.location.reload();
                }).catch((error)=>{
                  alert("上传失败");
                  window.location.reload();
                });
            }}
          >
            提交
          </button>
        ) : (
          <button style={{ width: "8vw" }}>缺失内容或提交中</button>
        )}
      </RowContainer>
    </Container>
  );
}

export { SpaceProject };
