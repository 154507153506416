import styled from "styled-components";
import team from '../../assets/png/team.png';

const Container = styled.div`
    width: 80%;
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img{
        width: 20%;
        height: auto;
        /* flex-shrink: 0; */
    }
    section{
        margin-left: 1vh;
        h1{
            font-size: 1.3vw;
            font-family: art;
            margin-bottom: 1.3vh;
        }
        h2{
            font-size: 0.75vw;
            font-family: jhun;
        }
    }
`;

function SignTitle({text}) {

    return (
        <Container>
            <img src={team} alt="" />
            <section>
                <h1>ART & AI TEAM</h1>
                <h2>{text}</h2>
            </section>
        </Container>
    );
}

export { SignTitle };