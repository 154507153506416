import gsap from "gsap";
import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { timelineStore } from "../../mobx/timeline/timeLine";

const InputBlock = styled.div({});
const Container = styled.div`
    position: absolute;
    opacity: 0;
    width: 25%;
    height: 65%;
    left: 37.5%;
    top: 20%;
    background-color: white;
    border-radius: 1vw;
    box-shadow: 10px 10px 11px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    ${InputBlock}{
        width: 80%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
`;

function SignIn({Title,Context,Button}) {

    const ref=useRef();
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            timelineStore.signline.signInit(ref,true);
        }, ref);
        return () => {
            timelineStore.signline.signDelet(ref,true);
            ctx.revert();
        };
      }, []);

    return (
        <Container ref={ref}>
            {Title}
            <InputBlock>
                {Context}
            </InputBlock>
            {Button}
        </Container>
    );
}

export { SignIn };