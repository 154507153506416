import styled from "styled-components";

import { SecondDetailTitle } from "../components/second/SecondDetailTitle";
import { SecondContext } from "../components/second/SecondContext";
import { SecondBanner } from "../components/second/SecondBanner";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { timelineStore } from "../mobx/timeline/timeLine";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { NormalActOb } from "../components/norml/NormalAct";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { service } from "../mobx/service/service";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
  position: absolute;
`;

export function SecondDetail() {
  const params = useParams();
  const [actData, setActData] = useState(null);
  useEffect(() => {
    service.editpage
      .getActivityMethod({ id: params.id })
      .then((res) => {
        //console.log(res.data.data);
        setActData(res.data.data);
      })
      .catch((error) => {
        alert("获取活动失败");
      });
  }, [params]);
  return (
    <Container>
      {actData && (
        <>
          <NormalBlockOb
            $width={"100%"}
            $height={"25%"}
            number={8}
            islay={false}
            animation={timelineStore.secondline.secondBlockDo}
            colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
          ></NormalBlockOb>
          <NormalFontBOb
            $width={"40%"}
            $height={"25%"}
            $left={"-44%"}
            $top={"-4.5%"}
            $fontsize={"5.3vw"}
            animation={timelineStore.secondline.detailFontDo}
          ></NormalFontBOb>
          <NormalActOb
            $width={"40%"}
            $height={"36%"}
            $left={"9%"}
            $top={"calc(10.5% + 110px)"}
            animation={timelineStore.secondline.detailActDo}
            $img={actData.mainimg}
          ></NormalActOb>
          <SecondDetailTitle
            main={actData.maintitle}
            detail={actData.childtitle}
          ></SecondDetailTitle>
          <SecondContext context={actData.context}></SecondContext>
          <SecondBanner banner={actData.bannerimg}></SecondBanner>
        </>
      )}
    </Container>
  );
}
