import { makeAutoObservable } from 'mobx';
import {firstTimeLine} from './firstTimeLine';
import {secondTimeLine} from './secondTimeLine';
import {thirdTimeline} from './thirdTimeline';
import {fourthTimeLine} from './fourthTimeLine';
import {optionTimeLine} from './optionTimeLine';
import {fifthTimeLine} from './fifthTimeLine';
import { signTimeLine } from './signTimeLine';
import { projectTimeLine } from './projectTimeLine';
import { spaceTimeLine } from './spaceTimeLine';


class TimelineStore {
    firstline = firstTimeLine;
    secondline = secondTimeLine;
    thirdline=thirdTimeline;
    fourthline=fourthTimeLine;
    fifthLine=fifthTimeLine;
    optionline=optionTimeLine;
    signline=signTimeLine;
    projectline=projectTimeLine;
    spaceline=spaceTimeLine;

    constructor() {
        makeAutoObservable(this);
    }
    
}

const timelineStore = new TimelineStore();
export { timelineStore };