import styled from "styled-components";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { NormalFontWOb } from "../components/norml/NormalFontW";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { timelineStore } from "../mobx/timeline/timeLine";
import { ProjectsCheck } from "../components/project/ProjectsCheck";
import { Projects } from "../components/project/Projects";
import { ProjectsNext } from "../components/project/ProjectsNext";
import { useEffect, useState } from "react";
import { service } from "../mobx/service/service";
import { SpaceLoad } from "../components/space/SpaceLoad";
import { Square } from "../components/loading/Square";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  //height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;

function ProjectsShow() {
  const [isDone, setIsDone] = useState(false);
  const [team, setTeam] = useState("game");
  const [indexNum, setIndex] = useState(1);
  const [count, setCount] = useState(200);
  const [projectData, setProject] = useState(null);
  const navgation = useNavigate();
  useEffect(() => {
    service.project
      .getProjectCountMethod({ team: team })
      .then((res) => {
        setCount(res.data.prcount);
      })
      .catch((error) => {
        console.log(error);
      });
    service.project
      .getProjectByTeamMethod({ team: team, page: indexNum })
      .then((res) => {
        //console.log(res);
        setIsDone(true);
        setProject(res.data.project);
      });
  }, [team, indexNum]);
  return (
    <Container>
      {isDone && projectData? (
        <>
          <NormalBlockOb
            $position={"fixed"}
            $zIndex={2}
            $width={"100%"}
            $height={"22vh"}
            number={8}
            islay={false}
            animation={timelineStore.projectline.projectBlockDo}
            colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
          ></NormalBlockOb>
          <NormalFontWOb
            $position={"fixed"}
            value={"OUR WORKS"}
            $left={"-44%"}
            $top={"-1%"}
            $width={"50%"}
            $height={"25%"}
            $fontsize={"6vw"}
            animation={timelineStore.projectline.projectWhiteFontDo}
          ></NormalFontWOb>
          <NormalFontBOb
            $position={"fixed"}
            isFront={false}
            $width={"50%"}
            $height={"25%"}
            $top={"-4.3%"}
            $right={"10%"}
            $justify={"flex-start"}
            $align={"flex-end"}
            $fontsize={"6vw"}
            animation={timelineStore.projectline.projectBlackFontDo}
          ></NormalFontBOb>
          <ProjectsCheck setTeam={setTeam}></ProjectsCheck>
          <Projects
            projectData={projectData}
            navgation={navgation}
          ></Projects>
          <ProjectsNext
            indexNum={indexNum}
            setIndex={setIndex}
            count={count}
          ></ProjectsNext>
        </>
      ) : (
        <SpaceLoad Context={<Square></Square>}></SpaceLoad>
      )}
    </Container>
  );
}

export { ProjectsShow };
