import styled from "styled-components";

import { NormalFontWOb } from "../components/norml/NormalFontW";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { timelineStore } from "../mobx/timeline/timeLine";
import { FifthProjectOb } from "../components/fifth/FifthProject";
import { FifthCheckOb } from "../components/fifth/FifthCheck";
import { isRenderStore } from "../mobx/render/isRender";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  position: relative;
  overflow: hidden;
`;

export function FifthPage({nagative}) {
  return (
    <Container onWheel={(e)=>{
      if(e.deltaY>0){
        isRenderStore.fifthRender.setUpOrDown(1);
    }else{
        isRenderStore.fifthRender.setUpOrDown(0);
        isRenderStore.fifthRender.wheelDown();
    }
    }}>
      <NormalFontWOb
        value={"TEAM PORTFOLIO"}
        $top={"-1%"}
        $width={"75%"}
        $height={"25%"}
        $left={"calc(1.5% - 100px)"}
        animation={timelineStore.fifthLine.fifthTeamDo}
        $fontsize={"6vw"}
        $fontColor={"black"}
        $fontWeight={550}
      ></NormalFontWOb>
      <NormalFontBOb
        $width={"55%"}
        $height={"25%"}
        $right={"calc(-1.5% + 100px)"}
        $top={"-4.5%"}
        isFront={false}
        animation={timelineStore.fifthLine.fifthFontDo}
        $letterspace={"0.5rem"}
        $fontsize={"5.7vw"}
        $align={"flex-end"}
        $fontColor={"rgb(106, 106, 106)"}
      ></NormalFontBOb>
       <NormalBlockOb
        $width={"100%"}
        $height={"77%"}
        $position={"absolute"}
        $bottom={"0%"}
        colordata={["black", "rgb(96, 119, 175)", "rgb(102, 127, 191)"]}
        number={8}
        animation={timelineStore.fifthLine.fifthBlockDo}
        islay={false}
        isunload={true}
      ></NormalBlockOb>
      <FifthProjectOb></FifthProjectOb>
      <FifthCheckOb nagative={nagative}></FifthCheckOb>
    </Container>
  );
}
