import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { isRenderStore } from "../../mobx/render/isRender";

const Activity = styled.div`
  width: 99%;
  height: 99%;
  background-color: black;
  background-image: url(${(props) => props.$img});
  background-size: 100% auto;
  background-position: center center;
`;
const Container = styled.div`
  opacity: 0;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  background-color: white;
  z-index: 10;
  position: absolute;
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  display: flex;
  align-items: center;
  justify-content: center;
`;

function NormalAct({
  animation,
  iscomplete = false,
  $width,
  $height,
  $left,
  $top,
  $right,
  $bottom,
  $img=null,
}) {
  const actRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (animation) {
        animation(actRef);
      }
    });
    return () => ctx.revert();
  }, [animation]);

  if (isRenderStore.secondRender.secondUnloadStart) {
    timelineStore.secondline.secondActTitleUnload(actRef);
  } else if (isRenderStore.thirdRender.thirdUnloadStart && !iscomplete) {
    timelineStore.thirdline.thirdActContextUnload(actRef);
  } else if (isRenderStore.thirdRender.thirdUnloadStart && iscomplete) {
    timelineStore.thirdline.thirdComplete(actRef);
  }else if(isRenderStore.fourthRender.fourthUnloadStart){
    timelineStore.fourthline.fourthActUnload(actRef);
  }

  return (
    <Container
      ref={actRef}
      $width={$width}
      $height={$height}
      $left={$left}
      $top={$top}
      $right={$right}
      $bottom={$bottom}
    >
      <Activity $img={$img}></Activity>
    </Container>
  );
}

const NormalActOb = observer(NormalAct);
export { NormalActOb };
