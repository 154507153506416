import styled from "styled-components";
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { isRenderStore } from "../../mobx/render/isRender";
import { timelineStore } from "../../mobx/timeline/timeLine";

const Child = styled.div`
  opacity: ${(props) => props.$opacity};
`;
const Container = styled.div`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  position: ${(props) => props.$position};
  z-index: ${(props) => props.$zIndex};
  bottom: ${(props) => props.$bottom};
  right: ${(props) => props.$right};
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  background-color: rgb(208, 208, 208);
  display: flex;
  flex-direction: row;
  box-shadow: ${(props) =>props.$shadow};
  ${Child} {
    width: 12.5%;
    height: 100%;
    flex: 1;
  }
`;

function NormalBlock({
  $width,
  $height,
  $position,
  $bottom,
  $right,
  $top,
  $left,
  $opacity,
  colordata,
  number,
  animation,
  islay,
  isunload,
  $zIndex,
  $shadow,
}) {
  const blockRef = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      if (!islay) {
        requestAnimationFrame(() => {
          animation(blockRef, colordata);
        });
      }
    }, blockRef);

    return () => {
      ctx.revert();
    };
  }, [colordata, animation, islay]);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (islay) {
        animation(blockRef, colordata);
      }
    }, blockRef);

    return () => {
      ctx.revert();
    };
  }, [colordata, animation, islay]);

  if (isRenderStore.firstRender.firstUnloadStart && isunload) {
    timelineStore.firstline.firstBlockTwoUnload(blockRef);
  }else if(isRenderStore.secondRender.secondUnloadStart){
    timelineStore.secondline.secondBlockUnload(blockRef);
  }else if(isRenderStore.thirdRender.thirdUnloadStart){
    timelineStore.thirdline.thirdBlockUnload(blockRef);
  }else if(isRenderStore.fourthRender.fourthUnloadStart){
    timelineStore.fourthline.fourthBlockUnload(blockRef);
  }else if(isRenderStore.fifthRender.fifthUnloadStart){
    timelineStore.fifthLine.fifthBlockUnload(blockRef);
  }

  return (
    <Container
      $zIndex={$zIndex}
      ref={blockRef}
      $width={$width}
      $height={$height}
      $position={$position}
      $bottom={$bottom}
      $right={$right}
      $top={$top}
      $left={$left}
      $shadow={$shadow}
    >
      {Array.from({ length: number }).map((v, i) => (
        <Child key={i} $opacity={$opacity}></Child>
      ))}
    </Container>
  );
}
const NormalBlockOb = observer(NormalBlock);
export { NormalBlockOb };
