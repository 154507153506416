import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';

class FirstTimelineStore {
    firstTimeline = gsap.timeline();
    unloadTimeline = gsap.timeline();
    isTimelineDone = false;
    isLoadDone = false;


    constructor() {
        makeAutoObservable(this);
    }

    setTimelineDone = (value) => {
        this.isTimelineDone = value;
    }
    setLoadDone = (value) => {
        this.isLoadDone = value;
    }
    init = () => {
        this.isTimelineDone = false;
        this.isLoadDone = false;
        this.firstTimeline.kill();
        this.unloadTimeline.kill();
        this.firstTimeline = gsap.timeline();
        this.unloadTimeline = gsap.timeline();
    }

    firstBlockOneDofirst = (blockRef) => {
        this.firstTimeline.addLabel('start', 0.3);
        this.firstTimeline.addLabel('fontstart', 0.5);
        this.firstTimeline.addLabel('restart', 1.4);
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.firstTimeline.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ['white', 'black', 'rgb(96, 119, 175)', 'rgb(102, 127, 191)'],
                },
                duration: 0.4,
            }, i > 0 ? '<+=0.1' : 'start');
        }
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.firstTimeline.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ['rgb(101, 116, 150)', 'rgb(189, 189, 189)', 'rgb(208, 208, 208)'],
                    
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1' : 'restart');
        }
        this.firstTimeline.to(blockRef.current, {
            zIndex: 0,
            duration: 0.1,
        }, 'restart+=0.5');
        
    }


    firstBlockOneDosecond = (blockRef) => {
        this.firstTimeline.addLabel('firstblockonedone', '+=1.2');
        this.firstTimeline.addLabel('firstfontonestart', '+=0.2');
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.firstTimeline.to(blockRef.current.children[i], {
                backgroundColor: 'rgb(102, 127, 191)',
                duration: 0.1,
            }, 'firstblockonedone');
        }

        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.firstTimeline.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ['rgb(101, 116, 150)', 'rgb(189, 189, 189)', 'rgb(208, 208, 208)'],
                },
                duration: 0.3,
            }, i > 0 ? 'firstblockonedone+=' + i * 0.1.toString() : 'firstblockonedone');
        }
        this.firstTimeline.to(blockRef.current, {
            opacity: 0,
            duration: 0.1,
        }, 'firstblockonedone+=0.5');
    }

    firstFontOneDo = (fontRef) => {
        this.firstTimeline.to(fontRef.current.children[0], {
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
        }, 'fontstart');
        this.firstTimeline.to(fontRef.current.children[1], {
            opacity: 1,
            duration: 1,
            ease: "power2.inOut",
        }, '<+=0.4');
        this.firstTimeline.to(fontRef.current.children[0], {
            y: '5vw',
            duration: 1,
            ease: "power2.inOut",
        }, 'restart+=0.5');
        this.firstTimeline.to(fontRef.current.children[1], {
            y: '5vw',
            duration: 1,
            ease: "power2.inOut",
        }, 'restart+=0.7').add('firstfonttwostart', '-=0.5');
    }

    firstBlockTwoDo = (blockRef) => {
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.firstTimeline.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ['black', 'rgb(59, 73, 112)', 'rgb(101, 127, 191)'],
                    opacity: [0, 0.5, 1],
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1'  : 'restart+=0.55');
        }
    }

    firstFontTwoDo = (fontRef) => {
        for (let i = 0; i < fontRef.current.children.length; i++) {
            this.firstTimeline.to(fontRef.current.children[i], {
                duration: 1.3,
                text: {
                    value: i === 0 ? '______' : '【美人团队】是由一群热爱计算机与游戏的00后组成的一个专注于游戏开发的技术创新型团队。',
                },
            }, 'firstfonttwostart');
        }
    }

    firstOptionDo = (opRef) => {
        this.firstTimeline.to(opRef.current, {
            duration: 2,
            opacity: 1,
        }, 'firstfonttwostart+=2');
    }

    firstImpDo = (impRef) => {
        this.firstTimeline.to(impRef.current, {
            opacity: 1,
            duration: 2,
            onComplete: () => {
                this.setLoadDone(true);
            }
        }, 'firstfonttwostart');
    }

    firstBlockTwoUnload = (blockRef) => {
        this.unloadTimeline.addLabel('fonttwounloadstart', 0.3);
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.unloadTimeline.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ['rgb(137, 160, 209)', 'white', 'rgb(208, 208, 208)'],
                    opacity: [1, 0.5, 0],
                },
                duration: 0.6,
            }, i > 0 ? '<+=0.1' : '');
        }
    }

    firstFontOneUnload = (fontRef) => {
        this.unloadTimeline.to(fontRef.current.children[0], {
            y: '-5vw',
            opacity: 0,
            duration: 1.5,
            ease: "power2.inOut",
        }, 'fonttwounloadstart-=0.2');
        this.unloadTimeline.to(fontRef.current.children[1], {
            y: '-5vw',
            opacity: 0,
            duration: 1.5,
            onComplete: () => {
                this.setTimelineDone(true);
            },
            ease: "power2.inOut",
        }, 'fonttwounloadstart-=0.2');
    }
    firstFontTwoUnload = (fontRef) => {
        this.firstTimeline.to(fontRef.current.children[0], {
            duration: 1.5,
            opacity: 0,
            y: -150,
        }, 'fonttwounloadstart');
        this.firstTimeline.to(fontRef.current.children[1], {
            duration: 1.5,
            opacity: 0,
            y: -150,
        }, 'fonttwounloadstart');
    }
}

const firstTimeLine = new FirstTimelineStore();
export { firstTimeLine };