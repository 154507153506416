import styled from "styled-components";
import jiantou from '../../assets/png/jiantou.png';
import view from '../../assets/png/view.png';
import dianzan from '../../assets/png/dianzan.png';
import download from '../../assets/png/download.png';

const Front=styled.div({});
const Next=styled.div({});
const Middle=styled.div({});
const Container = styled.div`
    position: absolute;
    left: 10%;
    bottom: 0;
    width: 55%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ${Front}{
        width: 94%;
        padding-right: 5%;
        padding-left: 1%;
        height: 35%;
        font-family: one;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .frontone{
            width: 100%;
            height: 30%;
            span{
                font-size: 1.85vw;
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .fronttwo{
            width: 100%;
            height: 18%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span{
                font-size: 0.9vw;
                display: flex;
                flex-direction: row;
                align-items: center;
                img{
                    width: 1vw;
                    height: 1vw;
                    margin-bottom: 0.4vh;
                    margin-right: 0.2vw;
                    border-radius: 50%;
                }
            }
            button{
                background-color: transparent;
                border: none;
                font-size: 0.9vw;
                font-family: one;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                color: rgb(102, 127, 191);
                img{
                    width: 1vw;
                    height: 1vw;
                    margin-bottom: 0.4vh;
                }
            }
        }
        .frontthree{
            display: flex;
            flex-direction: row;
            span{
                font-size: 0.9vw;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                img{
                    width: 1vw;
                    height: 1vw;
                    margin-bottom: 0.4vh;
                    margin-right: 0.2vw;
                }
                margin-right: 1.5vw;
                a{
                    text-decoration: none;
                    font-size: 0.9vw;
                    color: black;
                }
            }
        }
    }
    ${Middle}{
        width: 100%;
        height: 1.7vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        .middleone{
            width: 0.3%;
            height: 100%;
            background-color: rgb(176, 176, 176);
            box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.3);
        }
        .middletwo{
            width: 99.7%;
            height: 20%;
            background-color: rgb(176, 176, 176);
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
        }
    }
    ${Next}{
        width: 94%;
        height: 56%;
        padding: 2% 3%;
        font-family: one;
        font-size: 0.85vw;
        line-height: 1vh;
        text-align: justify;
    }
`;

function ProjectContext({nagative,userid,title, description, date, author, persons,viewn, like,downloadn}) {

    return (
        <Container>
            <Front>
                <section className="frontone">
                    <span>{title}</span>
                </section>
                <section className="fronttwo">
                    <span>
                        <img src={author[0].avater} alt="" />
                        作者: {author[0].level}-{author[0].name}
                        &nbsp;
                        协作者:
                        &nbsp;
                        {persons[0].map((person, index) => {
                            return (
                                <span key={index}>
                                    <img src={person.avater} alt="" />
                                    {person.level}-{person.name}
                                    &nbsp;
                                </span>
                            );
                        })}
                    </span>
                    <button onClick={()=>{nagative(`/spaceperson/${userid}`);}}>
                        查看他的所有作品
                        &nbsp;
                        <img src={jiantou} alt="" />
                    </button>
                </section>
                <section className="frontthree">
                    <span>
                        <img src={view} alt="" />
                        {viewn}
                    </span>
                    <span>
                        <img src={dianzan} alt="" />
                        {like}
                    </span>
                    <span>
                        <img src={download} alt="" />
                        <a href={downloadn}>下载</a>
                    </span>
                </section>
            </Front>
            <Middle>
                <div className="middleone"></div>
                <div className="middletwo"></div>
            </Middle>
            <Next>
                {description}
                --{date}
            </Next>
        </Container>
    );
}

export { ProjectContext};