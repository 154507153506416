import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';

class FourthTimelineStore {

    fourthTimeLine = gsap.timeline();
    unLoadTimeLine = gsap.timeline();
    isTimeLineDone = false;
    isLoadDone = false;
    constructor() {
        makeAutoObservable(this);
    }

    setFourthTimeLineDone = (value) => {
        this.isTimeLineDone = value;
    }
    setFourthLoadDone = (value) => {
        this.isLoadDone = value;
    }
    init = () => {
        this.setFourthTimeLineDone(false);
        this.setFourthLoadDone(false);
        this.fourthTimeLine.kill();
        this.unLoadTimeLine.kill();
        this.fourthTimeLine = gsap.timeline();
        this.unLoadTimeLine = gsap.timeline();
    }

    fourthBlockDo = (blockRef, colordata) => {
        this.fourthTimeLine.addLabel('fontstart',0.4);
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.fourthTimeLine.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: colordata,
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1' : '');
        }
    }
    fourthBlockUnload = (blockRef) => {
        this.unLoadTimeLine.addLabel('fontunload',0.4);
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.unLoadTimeLine.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ["white", "rgb(178, 193, 226)",'rgb(208, 208, 208)'],
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1' : '');
        }
    }
    fourthFontDo = (fontRef) => {
        this.fourthTimeLine.to(fontRef.current, {
            y:50,
            duration: 1,
        },'fontstart');
        this.fourthTimeLine.to(fontRef.current, {
            opacity: 1,
            duration: 1,
        },'<+=0.3');
    }
    fourthFontUnload = (fontRef) => {
        this.unLoadTimeLine.to(fontRef.current, {
            x:100,
            opacity: 0,
            duration: 1,
        },'fontunload');
    }
    fourthKeyDo = (keyRef) => {
        this.fourthTimeLine.to(keyRef.current, {
            opacity: 1,
            duration: 1,
        },'fontstart+=0.7');
    
    }
    fourthKeyUnload = (keyRef) => {
        this.unLoadTimeLine.to(keyRef.current, {
            x:50,
            opacity: 0,
            duration: 1,
        },'fontunload+=0.3');
    
    }
    fourthActDo = (actRef) => {
        this.fourthTimeLine.to(actRef.current, {
            left:'53%',
            duration: 1,
        },'fontstart+=0.7');
        this.fourthTimeLine.to(actRef.current, {
            opacity: 1,
            duration: 1,
        },'fontstart+=0.8');
    
    }
    fourthActUnload = (actRef) => {
        this.unLoadTimeLine.to(actRef.current, {
            x:-200,
            duration: 1,
        },'fontunload+=0.6');
        this.unLoadTimeLine.to(actRef.current, {
            opacity: 0,
            duration: 1,
        },'fontunload+=0.7');
    
    
    }
    fourthNameDo = (nameRef) => {
        this.fourthTimeLine.to(nameRef.current, {
            y:100,
            opacity: 1,
            duration: 1,
        },'fontstart+=1');
    }
    fourthNameUnload = (nameRef) => {
        this.unLoadTimeLine.to(nameRef.current, {
            y:200,
            opacity: 0,
            duration: 1,
        },'fontunload+=0.6');
    }
    fourthSpecDo = (specRef) => {
        this.fourthTimeLine.to(specRef.current.children[0], {
            opacity: 1,
            duration: 1,
        },'fontstart+=1.1');
        this.fourthTimeLine.to(specRef.current, {
            width: '85%',
            duration: 1,
        },'fontstart+=1.2');
    }
    fourthSpecUnload = (specRef) => {
        this.unLoadTimeLine.to(specRef.current.children[0], {
            opacity: 0,
            duration: 1,
        },'fontunload+=0.5');
        this.unLoadTimeLine.to(specRef.current, {
            width: '0%',
            left:'0%',
            duration: 1,
        },'fontunload+=0.6');
    }
    fourthContextDo = (contextRef) => {
        this.fourthTimeLine.to(contextRef.current, {
            y:-100,
            opacity: 1,
            duration: 1,
        },'fontstart+=1.3');
    }
    fourthContextUnload = (contextRef) => {
        this.unLoadTimeLine.to(contextRef.current, {
            y:-200,
            opacity: 0,
            duration: 1,
        },'fontunload+=0.8');
    }
    fourthListDo = (listRef) => {
        this.fourthTimeLine.to(listRef.current, {
            opacity: 1,
            width: '42%',
            duration: 1,
            onComplete: () => {
                this.setFourthLoadDone(true);
            }
        },'fontstart+=1.4');
    }
    fourthListUnload = (listRef) => {
        this.unLoadTimeLine.to(listRef.current, {
            opacity: 0,
            left:'6%',
            width: '0%',
            duration: 1,
            onComplete: () => {
                this.setFourthTimeLineDone(true);
            }
        },'fontunload+=0.8');
    }
}
const fourthTimeLine = new FourthTimelineStore();
export { fourthTimeLine };