import styled from 'styled-components';
import {observer} from 'mobx-react';
import {isRenderStore} from '../mobx/render/isRender';
import { FirstHome } from '../modules/FirstHome';
import {SecondPage} from '../modules/SecondPage';
import { OptionOb } from '../modules/Option';
import { ThirdPage } from '../modules/ThirdPage';
import { FourthPage } from '../modules/FourthPage';
import { FifthPage } from '../modules/FifthPage';
import { useNavigate } from 'react-router-dom';



const Container=styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  position: relative;
  overflow: hidden;
`;

function All() {
    const nagative = useNavigate();
    return (
        <Container>
            {isRenderStore.firstRender.isFirstRender?<FirstHome></FirstHome>:''}
            {isRenderStore.secondRender.isSecondRender?<SecondPage nagative={nagative}></SecondPage>:''}
            {isRenderStore.thirdRender.isThirdRender?<ThirdPage></ThirdPage>:''}
            {isRenderStore.fourthRender.isFourthRender?<FourthPage></FourthPage>:''}
            {isRenderStore.fifthRender.isFifthRender?<FifthPage nagative={nagative}></FifthPage>:''}
            <OptionOb></OptionOb>
        </Container>
    );
}

const AllOb=observer(All);
export {AllOb};