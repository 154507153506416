import styled from "styled-components";
import { gsap } from "gsap";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { useLayoutEffect, useRef } from "react";

const Text = styled.textarea({});
const Container = styled.div`
  opacity: 0;
  width: 45%;
  height: 44%;
  position: absolute;
  bottom: 6%;
  left: 9%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Text} {
    width: 95%;
    height: 90%;
    resize: none;
    outline: none;
    border: none;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    font-family: one;
    font-size: 1.5em;
    text-align: justify;
    text-indent: 2em;
    padding: 10px;
    background-color: transparent;
    letter-spacing: 1px;
  }
`;

export function SecondContext({context}) {
  const contextRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      timelineStore.secondline.detailContextDo(contextRef);
    }, contextRef);

    return () => ctx.revert();
  }, []);
  return (
    <Container ref={contextRef}>
      <Text
        readOnly
        value={
          context
        }
      ></Text>
    </Container>
  );
}
