import { makeAutoObservable } from 'mobx';
import {httpPort} from '../../utils/env';
import axios from 'axios';

class InfoService {
    instance = axios.create({
        baseURL: `${httpPort}/profile`,
        timeout: 5000,
        headers: { 'X-Custom-Header': 'foobar' }
    });
    constructor() {
        makeAutoObservable(this);
    }
    getInfoMethod = async (data={}) => {
        return await this.instance({
            method: 'post',
            url: '/getinfo',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        }).catch((error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }
    updateProfileMethod = async (data) => {
        return await this.instance({
            method: 'post',
            url: '/updateprofile',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response) => {
            if (response.data.issuccess) {
                alert(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            alert('更改信息失败');
            window.location.reload();
        });
    }

    mannerGetInfoMethod = async (userdata) => {
        return await this.instance({
            method: 'post',
            url: '/mannergetinfo',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: userdata,
        }).then((response) => {
            return new Promise((resolve, reject) => {
                console.log(response);
                resolve(response);
            });
        }).catch((error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }
}

const infoService = new InfoService();
export { infoService };