import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { observer } from "mobx-react";
import { isRenderStore } from "../../mobx/render/isRender";


const Container = styled.div`
  position: absolute;
  z-index: 9;
  top: 61%;
  right: 15%;
  width: 0%;
  height: 1.5%;
  border-bottom: 0.7vh solid black;
  display: flex;
  flex-direction: row;
  justify-content: end;
  span {
    opacity: 0;
    font-family: one;
    font-size: 0.65vw;
    display: block;
    background-color: black;
    color: rgb(204, 204, 204);
    width: 3.5%;
    text-align: center;
  }
`;

function FourthSpec() {
  const specRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
        timelineStore.fourthline.fourthSpecDo(specRef);
    }, specRef);
    return () => ctx.revert();
  }, []);
  if(isRenderStore.fourthRender.fourthUnloadStart){
    timelineStore.fourthline.fourthSpecUnload(specRef);
  }
  return (
    <Container ref={specRef}>
      <span>美人团队</span>
    </Container>
  );
}
const FourthSpecOb = observer(FourthSpec);
export { FourthSpecOb };