import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

function ActEdit({ContextOne,ContextTwo}) {
    return (
        <Container>
            {ContextOne}
            {ContextTwo}
        </Container>
    );
}

export { ActEdit };