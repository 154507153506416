import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';

class ThirdTimeLineStore {
    thirdTimeline = gsap.timeline();
    unloadTimeline = gsap.timeline();
    isTimelineDone = false;
    isLoadDone = false;

    constructor() {
        makeAutoObservable(this);
    }
    setTimelineDone = (value) => {
        this.isTimelineDone = value;
    }
    setLoadDone = (value) => {
        this.isLoadDone = value;
    }
    init = () => {
        this.isTimelineDone = false;
        this.isLoadDone = false;
        this.thirdTimeline.kill();
        this.unloadTimeline.kill();
        this.thirdTimeline = gsap.timeline();
        this.unloadTimeline = gsap.timeline();
    }

    thirdBlockDo = (blockRef, colordata) => {
        this.thirdTimeline.add('fontabostart', '+=0.4');
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.thirdTimeline.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: colordata,
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1' : '');
        }
    }
    thirdFontDo = (fontRef) => {
        this.thirdTimeline.to(fontRef.current, {
            right: '0',
            duration: 1,
        }, 'fontstart');
        this.thirdTimeline.to(fontRef.current, {
            opacity: 1,
            duration: 1,
        }, 'fontstart+=0.2').add('restart','-=0.7');
    }
    thirdFontAboDo = (fontRef) => {
        this.thirdTimeline.to(fontRef.current, {
            left: '0',
            duration: 1,
        }, 'fontabostart');
        this.thirdTimeline.to(fontRef.current, {
            opacity: 1,
            duration: 1,
        }, 'fontabostart+=0.2').add('fontstart','-=0.5');
    }
    thirdToLeft =(ref)=>{
        this.thirdTimeline.to(ref.current,{
            left:'15%',
            opacity:1,
            duration:1,
        },'restart');
    }
    thirdActToLeft=(ref)=>{
        this.thirdTimeline.to(ref.current,{
            left:'15%',
            opacity:1,
            duration:1,
        },'restart+=0.1');
    }
    thirdToRight =(ref)=>{ 
        this.thirdTimeline.to(ref.current,{
            right:'15%',
            opacity:1,
            duration:1,
        },'restart+=0.2');
    }
    thirdActToRight=(ref)=>{
        this.thirdTimeline.to(ref.current,{
            right:'15%',
            opacity:1,
            duration:1,
            onComplete:()=>{
                this.setLoadDone(true);
            }
        },'restart+=0.3');
    }

    thirdBlockUnload=(blockRef)=>{
        this.unloadTimeline.add('abostart');
        this.unloadTimeline.add('fontstart','+=0.5');
        this.unloadTimeline.add('actstart','+=0.8');
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.unloadTimeline.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ['white','rgb(159, 178, 219)','rgb(208,208,208)'],
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1' : '');
        }
    }
    thirdFontAboUnload=(fontRef)=>{
        this.unloadTimeline.to(fontRef.current, {
            x:100,
            duration: 1,
        }, 'abostart');
        this.unloadTimeline.to(fontRef.current, {
            opacity: 0,
            duration: 1,
        }, 'abostart+=0.3');
    
    }
    thirdFontUnload=(fontRef)=>{
        this.unloadTimeline.to(fontRef.current, {
            x:100,
            opacity: 0,
            duration: 1,
        }, 'fontstart');
    
    }
    thirdActContextUnload=(ref)=>{
        this.unloadTimeline.to(ref.current,{
            y:-100,
            opacity:0,
            duration:1
        },'actstart');
    }
    thirdComplete=(ref)=>{
        this.unloadTimeline.to(ref.current,{
            y:-100,
            opacity:0,
            duration:1,
            onComplete:()=>{
                this.setTimelineDone(true);
            }
        },'actstart');
    }
}

const thirdTimeline = new ThirdTimeLineStore();
export { thirdTimeline };