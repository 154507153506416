import styled from "styled-components";
import person from "../../assets/svg/person.svg";
import persons from "../../assets/svg/persons.svg";
import setting from "../../assets/svg/settings.svg";
import project from "../../assets/svg/project.svg";
import signout from "../../assets/svg/signout.svg";
import page from "../../assets/svg/page.svg";

const ListChild = styled.div({});
const Container = styled.div`
  width: 16%;
  height: 75%;
  padding-top: 1.5%;
  position: absolute;
  box-shadow: 0px 15px 15px -15px rgba(0, 0, 0, 0.5) inset;
  top: 22%;
  background-color: rgb(185, 183, 183);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .active {
    background-color: rgb(102, 127, 191);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
    span {
      color: white;
    }
    img {
      filter: drop-shadow(white 200px 0);
      transform: translateX(-200px);
    }
  }
  ${ListChild} {
    width: 60%;
    padding: 0 10%;
    height: 2.2vw;
    margin-bottom: 0.8vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    img {
      width: 1.5vw;
      height: 1.5vw;
      margin-top: 0.7vh;
      margin-right: 1vh;
    }
    span {
      font-size: 0.85vw;
      font-family: one;
      margin-top: 0.4vh;
    }
    &:hover {
    }
  }
`;

function SpaceList({ permission, role, setActive, active }) {
  const normalData = [
    {
      name: "个人信息",
      src: person,
      act: 0,
    },
    {
      name: "设置",
      src: setting,
      act: 1,
    },
    ...(role !== "normal"
      ? [
          {
            name: "作品提交",
            src: project,
            act: 3,
          },
        ]
      : []),
    ...(permission !== "normal"
      ? [
          {
            name: "页面编辑",
            src: page,
            act: 4,
          },
          {
            name: "成员管理",
            src: persons,
            act: 5,
          },
          {
            name: "作品管理",
            src: persons,
            act: 9,
          },
          // {
          //   name: "设备管理",
          //   src: project,
          //   act: 8,
          // },
        ]
      : []),
    // {
    //   name: "我的设备",
    //   src: project,
    //   act: 7,
    // },
    {
      name: "返回首页",
      src: project,
      act: 6,
    },
    {
      name: "退出账号",
      src: signout,
      act: 2,
    },
  ];

  return (
    <Container>
      {normalData.map((item, index) => {
        return (
          <ListChild
            className={active === item.act ? "active" : ""}
            key={index}
            onClick={() => {
              setActive(item.act);
            }}
          >
            <img src={item.src} alt="" />
            <span>{item.name}</span>
          </ListChild>
        );
      })}
    </Container>
  );
}

export { SpaceList };
