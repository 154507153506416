import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { service } from "../mobx/service/service";
import { useEffect} from "react";


function Auth({children}) {
    const nagative=useNavigate();
    useEffect(()=>{
        service.info.getInfoMethod().then((res)=>{
            service.setSignIn(true);
        }).catch((error)=>{
            service.setSignIn(false);
            nagative('/sign');
        });
    },[nagative]);
    return (
        <>
            {service.getSignIn()?children():null}
        </>
    );
}

const AuthOb=observer(Auth);
export {AuthOb};