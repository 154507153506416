import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

const Title = styled.div({});
const Container = styled.div`
  position: absolute;
  width: 42%;
  height: 77%;
  right: 0;
  background-color: #fff;
  overflow: hidden;
  border-radius: 1vh;
  ${Title} {
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
    padding: 10px;
    font-family: one;
  }
`;

function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const DeviceMeomy = forwardRef(({data, setData}, ref) => {
//   const [data, setData] = useState();
  const conRef =useRef();
  useImperativeHandle(ref,()=>({
    ...ref.current,
    fullMeScreen(){
      conRef.current.requestFullscreen();
    }
  }));
  useEffect(() => {
    setData([
        {
          name: "浏览器内存占用",
          A: 120,
          B: 110,
          fullMark: 150,
        },
        {
          name: "设备内存占用",
          A: 98,
          B: 130,
          fullMark: 150,
        },
        {
          name: "JS堆栈占用",
          A: 86,
          B: 130,
          fullMark: 150,
        },
        {
          name: "性能指标",
          A: 99,
          B: 100,
          fullMark: 150,
        },
        {
          name: "网络占用",
          A: 85,
          B: 90,
          fullMark: 150,
        },
        {
          name: "RTT",
          A: 65,
          B: 85,
          fullMark: 150,
        },
      ]);
    const intervalId = setInterval(() => {
      setData((currentData) => {
        return [
          {
            name: "浏览器内存占用",
            A: getRandomInteger(65, 110),
            B: getRandomInteger(95, 145),
            fullMark: 150,
          },
          {
            name: "设备内存占用",
            A: getRandomInteger(65, 110),
            B: getRandomInteger(95, 145),
            fullMark: 150,
          },
          {
            name: "JS堆栈占用",
            A: getRandomInteger(55, 100),
            B: getRandomInteger(95, 125),
            fullMark: 150,
          },
          {
            name: "性能指标",
            A: getRandomInteger(65, 130),
            B: getRandomInteger(70, 145),
            fullMark: 150,
          },
          {
            name: "网络占用",
            A: getRandomInteger(45, 105),
            B: getRandomInteger(85, 145),
            fullMark: 150,
          },
          {
            name: "RTT",
            A: getRandomInteger(10, 50),
            B: getRandomInteger(65, 125),
            fullMark: 150,
          },
        ];
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [setData]);

  return (
    <Container ref={conRef}>
      <Title>当前设备内存状态</Title>
      <ResponsiveContainer width="100%" height="90%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis angle={30} domain={[0, 150]} />
          <Radar
            name="最低占用"
            dataKey="A"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
          <Radar
            name="瞬时占用"
            dataKey="B"
            stroke="#82ca9d"
            fill="#82ca9d"
            fillOpacity={0.6}
          />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </Container>
  );
});

export { DeviceMeomy };
