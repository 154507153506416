import styled from "styled-components";
import { observer } from "mobx-react";
import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import { isRenderStore } from "../../mobx/render/isRender";
import { timelineStore } from "../../mobx/timeline/timeLine";

const Conrtainer = styled.div`
  opacity: 0;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  position: absolute;
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  font-family: one;
  //font-size: 1.6rem;
  font-size: ${(props) => props.$fontSize};
  text-align: justify;
  text-indent: 2em;
  background-color: transparent;
  letter-spacing: 1px;
  vertical-align: middle;
  color: ${(props) => props.$color};
`;

function NormalContext({
  value,
  animation,
  $width,
  $height,
  $top,
  $left,
  $right,
  $bottom,
  $color,
  $fontSize,
}) {
  const contextRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (animation) {
        animation(contextRef);
      }
    }, contextRef);
    return () => {
      ctx.revert();
    };
  }, [animation]);
  if(isRenderStore.thirdRender.thirdUnloadStart){
    timelineStore.thirdline.thirdActContextUnload(contextRef);
  }else if(isRenderStore.fourthRender.fourthUnloadStart){
    timelineStore.fourthline.fourthContextUnload(contextRef);
  }

  return (
    <Conrtainer
      $width={$width}
      $height={$height}
      $top={$top}
      $bottom={$bottom}
      $right={$right}
      $left={$left}
      $color={$color}
      ref={contextRef}
      $fontSize={$fontSize}
    >
      {value}
    </Conrtainer>
  );
}
const NormalContextOb = observer(NormalContext);
export { NormalContextOb };
