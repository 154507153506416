import styled from "styled-components";

const InputLabel=styled.div({})
const Input=styled.input({})
const Container = styled.div`
    font-family: jhun;
    border: 1px solid #aeb4bf;
    padding: 5px;
    border-radius: 15px;
    position: relative;
    width: calc(${props=>props.$width} - 10px);
    height: 3.2vh;
    ${InputLabel}{
        position: absolute;
        top: -15px;
        left: 12px;
        background-color: #fff;
        padding: 5px;
        color: #aeb4bf;
    }
    ${Input}{
        width: 97%;
        height: 95%;
        outline: none;
        border: #aeb4bf 0px solid;
        font-family: jhun;
        font-size: 0.67vw;
        padding-left: 2%;
        letter-spacing: 0.1vw;
        margin-top: 0.15vh;
    }
`;

function NormalInput({text,placeholder,value,onChange,$width="100%"}) {

    return (
        <Container $width={$width}>
            <InputLabel>{text}</InputLabel>
            <Input type="text" value={value} onChange={onChange} placeholder={placeholder} />
        </Container>
    );
}

export { NormalInput };