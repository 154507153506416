import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';

class FifthTimelineStore {

    fifTimeLine=gsap.timeline();
    unloadTimeLine=gsap.timeline();
    isTimeLineDone=false;
    isLoadDone=false;
    constructor() {
        makeAutoObservable(this);
    }
    setFifTimeLineDone=(value)=>{
        this.isTimeLineDone=value;
    }
    setFifLoadDone=(value)=>{
        this.isLoadDone=value;
    }
    init=()=>{
        this.setFifTimeLineDone(false);
        this.setFifLoadDone(false);
        this.fifTimeLine.kill();
        this.unloadTimeLine.kill();
        this.fifTimeLine=gsap.timeline();
        this.unloadTimeLine=gsap.timeline();
    }

    fifthBlockDo=(blockRef,colordata)=>{
        this.fifTimeLine.add('fifthfontstart',0.2);
        for(let i=0;i<blockRef.current.children.length;i++){
            this.fifTimeLine.to(blockRef.current.children[i],{
                keyframes:{
                    backgroundColor:colordata,
                },
                duration:0.3,
            },i>0?'<+=0.1':'');
        }
    }
    fifthBlockUnload=(blockRef)=>{
        for(let i=0;i<blockRef.current.children.length;i++){
            if(i===blockRef.current.children.length-1){
                this.unloadTimeLine.to(blockRef.current.children[i],{
                    keyframes:{
                        backgroundColor:['white','rgb(159, 178, 219)','rgb(208,208,208)'],
                    },
                    duration:0.3,
                    onComplete:()=>{
                        this.setFifTimeLineDone(true);
                    }
                },i>0?'<+=0.1':'fontunload+=0.8');
            }
            this.unloadTimeLine.to(blockRef.current.children[i],{
                keyframes:{
                    backgroundColor:['white','rgb(159, 178, 219)','rgb(208,208,208)'],
                },
                duration:0.3,
            },i>0?'<+=0.1':'fontunload+=0.8');
        }
    }
    fifthTeamDo=(teamRef)=>{
        this.fifTimeLine.to(teamRef.current,{
            x:100,
            opacity:1,
            duration:1,
            ease:'power2.out',
        },'fifthfontstart+=0.3');
    }
    fifthTeamUnload=(teamRef)=>{
        this.unloadTimeLine.to(teamRef.current,{
            x:150,
            opacity:0,
            duration:1,
            ease:'power2.out',
        },'fontunload+=0.3');
    }
    fifthFontDo=(fontRef)=>{
        this.fifTimeLine.to(fontRef.current,{
            x:100,
            opacity:1,
            duration:1,
            ease:'power2.out',
        },'fifthfontstart+=0.4');
    }
    fifthFontUnload=(fontRef)=>{
        this.unloadTimeLine.to(fontRef.current,{
            x:150,
            opacity:0,
            duration:1,
            ease:'power2.out',
        },'fontunload+=0.4');
    }
    fifthProjectDo=(projectRef)=>{
        const first=[0];
        const second=[1,3];
        const third=[2,4,6];
        const fourth=[5,7];
        const fifth=[8];
        first.forEach((value)=>{
            this.fifTimeLine.to(projectRef.current.children[value],{
                opacity:1,
                duration:1,
                ease:'power2.out',
            },'fifthfontstart+=1');
        });
        second.forEach((value)=>{
            this.fifTimeLine.to(projectRef.current.children[value],{
                opacity:1,
                duration:1,
                ease:'power2.out',
            },'fifthfontstart+=1.1');
        });
        third.forEach((value)=>{
            this.fifTimeLine.to(projectRef.current.children[value],{
                opacity:1,
                duration:1,
                ease:'power2.out',
            },'fifthfontstart+=1.2');
        });
        fourth.forEach((value)=>{
            this.fifTimeLine.to(projectRef.current.children[value],{
                opacity:1,
                duration:1,
                ease:'power2.out',
            },'fifthfontstart+=1.3');
        });
        this.fifTimeLine.to(projectRef.current.children[fifth],{
            opacity:1,
            duration:1,
            ease:'power2.out',
        },'fifthfontstart+=1.4');
    }
    fifthProjectUnload=(projectRef)=>{
        this.unloadTimeLine.addLabel('start',0);
        this.unloadTimeLine.addLabel('fontunload',0.2);
        const second=[1,3];
        const third=[2,4,6];
        const fourth=[5,7];
        this.unloadTimeLine.to(projectRef.current.children[8],{
            opacity:0,
            duration:1,
            ease:'power2.out',
        },'start');
        for(let i=0;i<fourth.length;i++){
            this.unloadTimeLine.to(projectRef.current.children[fourth[i]],{
                opacity:0,
                duration:1,
                ease:'power2.out',
            },'start+=0.2');
        }
        for(let i=0;i<third.length;i++){
            this.unloadTimeLine.to(projectRef.current.children[third[i]],{
                opacity:0,
                duration:1,
                ease:'power2.out',
            },'start+=0.4');
        }
        for(let i=0;i<second.length;i++){
            this.unloadTimeLine.to(projectRef.current.children[second[i]],{
                opacity:0,
                duration:1,
                ease:'power2.out',
            },'start+=0.6');
        }
        this.unloadTimeLine.to(projectRef.current.children[0],{
            opacity:0,
            duration:1,
            ease:'power2.out',
        },'start+=0.8');
    }
    fifthCheckDo=(checkRef)=>{
        this.fifTimeLine.to(checkRef.current,{
            opacity:1,
            duration:1,
            ease:'power2.out',
            onComplete:()=>{
                this.setFifLoadDone(true);
            }
        },'fifthfontstart+=1.6');
    }
    fifthCheckUnload=(checkRef)=>{
        this.unloadTimeLine.to(checkRef.current,{
            opacity:0,
            duration:1,
            ease:'power2.out',
        },'fontunload');
    }
}
const fifthTimeLine=new FifthTimelineStore();
export {fifthTimeLine};