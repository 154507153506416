import styled from "styled-components";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { timelineStore } from "../mobx/timeline/timeLine";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectContext } from "../components/project/ProjectContext";
import { ProjectCommit } from "../components/project/ProjectCommit";
import { ProjectVimg } from "../components/project/ProjectVimg";
import { service } from "../mobx/service/service";
import { useEffect, useState } from "react";
import { SpaceLoad } from "../components/space/SpaceLoad";
import { Square } from "../components/loading/Square";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;

function ProjectPerson() {
  const params = useParams();
  const [projectData, setProjectData] = useState(null);
  const nagative = useNavigate();
  useEffect(() => {
    service.project
      .getOneProjectMethod({ id: params.id })
      .then((response) => {
        //console.log(response);
        setProjectData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.id]);

  return (
    <Container>
      {projectData? (
        <>
          <NormalBlockOb
            $width={"100%"}
            $height={"22%"}
            $shadow={"0px 0px 10px 5px rgba(0, 0, 0, 0.5)"}
            number={8}
            islay={false}
            animation={timelineStore.signline.signBlockDo}
            colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
          ></NormalBlockOb>
          <NormalFontBOb
            isFront={false}
            $width={"50%"}
            $height={"25%"}
            $top={"-2%"}
            $right={"10%"}
            $justify={"flex-start"}
            $align={"flex-end"}
            $fontsize={"6vw"}
            animation={timelineStore.signline.signBlackFontDo}
          ></NormalFontBOb>
          <ProjectContext
            title={projectData.title}
            description={projectData.description}
            date={projectData.date}
            author={projectData.author}
            persons={projectData.persons}
            viewn={projectData.view}
            like={projectData.like}
            downloadn={projectData.download}
            nagative={nagative}
            userid={projectData.user_id}
          ></ProjectContext>
          <ProjectCommit
            id={params.id}
            insert={service.commit.insertCommitMethod}
            getData={service.commit.getProjectCommitMethod}
            getRole={service.sign.getRoleMethod}
            nagative={nagative}
            userid={projectData.user_id}
          ></ProjectCommit>
          <ProjectVimg
            video={projectData.video}
            imgs={projectData.imgs}
          ></ProjectVimg>
        </>
      ) : (
        <SpaceLoad Context={<Square></Square>}></SpaceLoad>
      )}
    </Container>
  );
}

export { ProjectPerson };
