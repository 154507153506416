import styled from 'styled-components';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { useLayoutEffect, useRef } from 'react';
import {timelineStore} from '../../mobx/timeline/timeLine';
import {isRenderStore} from '../../mobx/render/isRender';
import {observer} from 'mobx-react';

gsap.registerPlugin(TextPlugin);
const FontLeft=styled.div({});
const FontRight=styled.div({});
const Container = styled.div`
    font-family: one;
    color: rgb(138, 135, 135);
    position: absolute;
    z-index: 99;
    top:58%;
    left: 30%;
    width: 25%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${FontLeft}{
        width: 15%;
        font-size: 1vw;
    }
    ${FontRight}{
        width: 75%;
        font-size: 0.8vw;
    }
`;

function FirstFontTwo(){

    const fontRef = useRef();
    useLayoutEffect(()=>{
        let ctx = gsap.context(()=>{
            timelineStore.firstline.firstFontTwoDo(fontRef);
        },fontRef);
        return ()=>ctx.revert();
    },[]);
    if(isRenderStore.firstRender.firstUnloadStart){
        timelineStore.firstline.firstFontTwoUnload(fontRef);
    }
    return (
        <Container ref={fontRef}>
            <FontLeft></FontLeft>
            <FontRight></FontRight>
        </Container>
    );
}

const FirstFontTwoOb=observer(FirstFontTwo);
export {FirstFontTwoOb};