import styled from 'styled-components';
import { gsap } from 'gsap';
import { useLayoutEffect, useRef } from 'react';
import {timelineStore} from '../../mobx/timeline/timeLine';

const ImpAll = styled.div`
    position: absolute;
    left: 43%;
    top: 43%;
    transform-origin: 50% 50%;
    transform: translate3d(-50%,0,0);
    animation: arrow-movement 2s ease-in-out infinite;
    &::before{
        transform: rotate(45deg) translateX(-10%);
        transform-origin: top left;
    }
    &::after{
        transform: rotate(-45deg) translateX(10%);
        transform-origin: top right;
    }
    &::before,&::after{
        background: #333232;
        content: "";
        display: block;
        height: 3px;
        position: absolute;
        top: 0;
        left: 0;
        width: 13px;
        box-shadow: 1px 1px 20px 0 #333232;
    }
    @keyframes arrow-movement{
        0%{opacity:0;top:92%}
        70%{opacity:1}
        100%{opacity:0}
    }
`;
const Container = styled.div`
    opacity: 0;
    position: absolute;
    top: 90%;
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    
`;

export function FirstImp() {

    const impRef = useRef();
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            timelineStore.firstline.firstImpDo(impRef);
        }, impRef);
        return () => ctx.revert();
    }, []);

    return (
        <Container ref={impRef}>
            <ImpAll style={{animation:'arrow-movement 2s 1s ease-in-out infinite'}}></ImpAll>
            <ImpAll></ImpAll>
        </Container>
    );
}