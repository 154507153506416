import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { observer } from "mobx-react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { isRenderStore } from "../../mobx/render/isRender";
//imgs
import one from '../../assets/png/show/one.png';
import two from '../../assets/png/show/two.png';
import three from '../../assets/png/show/three.png';
import four from '../../assets/png/show/four.png';
import five from '../../assets/png/show/five.png';
import six from '../../assets/png/show/six.png';
import seven from '../../assets/png/show/seven.png';
import eight from '../../assets/png/show/eight.png';
import nine from '../../assets/png/show/nine.png';


const Child = styled.div`
      background-image: url(${(props)=>props.$img});
`;
const Container = styled.div`
  width: 60%;
  height: 60%;
  position: absolute;
  left: 20%;
  top: 28%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${Child} {
    opacity: 0;
    border: 0.4vw solid white;
    width: 32%;
    height: 32.5%;
    background-size: cover;
    background-position: center center;
    filter: blur(0.4vw) brightness(150%) grayscale(80%);
  }
  .choose {
    filter: blur(0px);
  }
`;

const data = [
  {id:0,img:one},
  {id:1,img:two},
  {id:2,img:three},
  {id:3,img:four},
  {id:4,img:five},
  {id:5,img:six},
  {id:6,img:seven},
  {id:7,img:eight},
  {id:8,img:nine},
];
const cleanData = [];

function FifthProject() {
  const proRef = useRef();
  useLayoutEffect(() => {
    const resetchoose = () => {
      Array.from(proRef.current.children).forEach((item) => {
        item.classList.remove("choose");
      });
    };
    const choose = () => {
      const ramChild = proRef.current.children[Math.floor(Math.random() * 9)];
      resetchoose();
      ramChild.classList.add("choose");
    };
    let seid = setInterval(choose, 5000);
    const moveevent = (item) => {
      clearInterval(seid);
      resetchoose();
      item.classList.add("choose");
    };
    const resetevent = () => {
      seid = setInterval(choose, 5000);
    };
    Array.from(proRef.current.children).forEach((item) => {
      item.addEventListener("mouseenter", moveevent.bind(null, item));
      item.addEventListener("mouseleave", resetevent);
      cleanData.push(item);
    });
    let ctx = gsap.context(() => {
      timelineStore.fifthLine.fifthProjectDo(proRef);
    }, proRef.current);

    return () => {
      cleanData.forEach((item) => {
        item.removeEventListener("mouseenter", moveevent);
        item.removeEventListener("mouseleave", resetevent);
      });
      clearInterval(seid);
      ctx.revert();
    };
  }, []);

    if (isRenderStore.fifthRender.fifthUnloadStart) {
      timelineStore.fifthLine.fifthProjectUnload(proRef);
    }

  return (
    <Container ref={proRef}>
      {data.map((item, index) => (
        <Child className={`${index}`} key={index} $img={item.img}></Child>
      ))}
    </Container>
  );
}

const FifthProjectOb = observer(FifthProject);
export { FifthProjectOb };
