import { makeAutoObservable } from 'mobx';
import { signService } from './signService';
import { infoService } from './infoService';
import { projectService } from './projectService';
import { userService } from './userService';
import { foroleService } from './foroleService';
import { socketService } from './socketService';
import { commitService } from './commitService';
import { editpageService } from './editpageService';


class Service {
    isSignIn=false;
    sign=signService;
    info=infoService;
    project=projectService;
    user=userService;
    forole=foroleService;
    socket=socketService;
    commit = commitService;
    editpage = editpageService;
    constructor() {
        makeAutoObservable(this);
    }
    setSignIn=(value)=>{
        this.isSignIn=value;
    }
    getSignIn=()=>{
        return this.isSignIn;
    }
}

const service = new Service();
export { service};