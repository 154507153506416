import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { service } from "../../mobx/service/service";

// css in js
const CanvasContainer = styled.div({});
const Container = styled.div`
  font-family: jhun;
  border-radius: 15px;
  position: relative;
  width: calc(100% + 0px);
  height: calc(3.2vh + 10px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  ${CanvasContainer} {
    width: 40%;
    height: 100%;
    border: 1px solid #aeb4bf;
    border-radius: 0.8vh;
    canvas {
      cursor: pointer;
    }
  }
`;

function VerifyCode({ Context }) {
  // eslint-disable-next-line no-unused-vars
  const [captcha, setCaptcha] = useState(null);
  const canvasRef = useRef(null);
  const canvasConRef = useRef(null);
  const dataRef = useRef(null);

  // 绘制验证码
  const drawCaptcha = (captchaText) => {
    canvasRef.current.width = canvasConRef.current.clientWidth;
    canvasRef.current.height = canvasConRef.current.clientHeight;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // 清除之前的绘图

    // 设置背景噪点
    const numberOfDots = 100;
    for (let i = 0; i < numberOfDots; i++) {
      ctx.fillStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
        Math.random() * 255
      }, 0.5)`;
      ctx.fillRect(
        Math.random() * canvas.width,
        Math.random() * canvas.height,
        2,
        2
      );
    }

    // 设置文字样式
    ctx.font = "1vw 'one', Courier, monospace ";
    ctx.fillStyle = "#0a2933";
    ctx.textBaseline = "middle";

    let xPosition = 10; // Start at 10 pixels to give some padding
    const charWidth = canvas.width / 8;

    // 绘制每个字符，增加扭曲和颜色变化
    Array.from(captchaText).forEach((char, index) => {
      const x = xPosition + index * charWidth + (Math.random() * 10 - 5);
      const y = canvas.height / 2 + (Math.random() * 10 - 5);
      const angle = (Math.random() - 0.5) * (Math.PI / 6); // Random angle between -15 to +15 degrees

      ctx.save();
      ctx.translate(x, y);
      ctx.rotate(angle);
      ctx.fillText(char, 0, 0);
      ctx.restore();
    });

    // 添加视觉干扰线
    for (let i = 0; i < 5; i++) {
      ctx.strokeStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
        Math.random() * 255
      }, 0.8)`;
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineWidth = Math.random() * 2 + 1;
      ctx.stroke();
    }
  };

  // 获取验证码
  const fetchCaptcha = () => {
    service.sign.getVerifyCodeMethod().then((data) => {
      setCaptcha(data);
      drawCaptcha(data);
      dataRef.current = data;
    });
  };

  // 初始化验证码,并注册resize事件，达到验证码自适应效果
  useEffect(() => {
    fetchCaptcha();
    const observer = new ResizeObserver((e) => {
      if (dataRef.current) {
        drawCaptcha(dataRef.current);
      }
    });
    observer.observe(canvasConRef.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      {Context}
        <CanvasContainer ref={canvasConRef}>
          <canvas ref={canvasRef} onClick={fetchCaptcha}></canvas>
        </CanvasContainer>
    </Container>
  );
}

export { VerifyCode };
