// import { io } from "socket.io-client";
// import {wsPort} from '../../utils/env';
import { makeAutoObservable } from 'mobx';

class SocketService {
    // socket = io(wsPort,{
    //     query: {
    //         token: localStorage.getItem('token')
    //     },
    // });
    constructor() {
        makeAutoObservable(this);
    }
}

const socketService = new SocketService();
export { socketService };