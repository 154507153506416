import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

class SignTimeLineStore {
    signTimeLine = gsap.timeline();
    inState = null;
    outState = null;
    constructor() {
        makeAutoObservable(this);
    }

    setInState = (state) => {
        this.inState = state;
    }
    setOutState = (state) => {
        this.outState = state;
    }

    signBlockDo = (blockRef, colordata) => {
        this.signTimeLine.addLabel('logstart', 0.45);
        if (blockRef.current.children) {
            for (let i = 0; i < blockRef.current.children.length; i++) {
                this.signTimeLine.to(blockRef.current.children[i], {
                    keyframes: {
                        backgroundColor: colordata,
                    },
                    duration: 0.3,
                }, i > 0 ? '<+=0.1' : '');
            }
        }

    }
    signWhiteFontDo = (fontRef) => {
        this.signTimeLine.to(fontRef.current, {
            left: '0',
            duration: 1,
        }, 'logstart');
        this.signTimeLine.to(fontRef.current, {
            opacity: 1,
            duration: 1,
        }, 'logstart+=0.2');
    }
    signBlackFontDo = (fontRef) => {
        this.signTimeLine.to(fontRef.current, {
            right: '0',
            duration: 1,
        }, 'logstart');
        this.signTimeLine.to(fontRef.current, {
            opacity: 1,
            duration: 1,
        }, 'logstart+=0.2');
    }
    signInit = (ref, isIn) => {
        if (ref.current && isIn) {
            this.setInState(Flip.getState(ref.current, { props: ['opacity'] }));
            ref.current.style.opacity = "1";
            Flip.from(this.inState, {
                duration: 0.5,
            });
        } else if (ref.current && !isIn) {
            this.setOutState(Flip.getState(ref.current, { props: ['opacity'] }));
            ref.current.style.top = "1";
            Flip.from(this.outState, {
                duration: 0.5,
            });
        }
    }
    signDelet = (ref, isIn) => {
        if (ref.current && isIn) {
            this.setInState(Flip.getState(ref.current, { props: ['opacity'] }));
            ref.current.style.opacity = "0";
            Flip.from(this.inState, {
                duration: 0.5,
            });
        } else if (ref.current && !isIn) {
            this.setOutState(Flip.getState(ref.current, { props: ['opacity'] }));
            ref.current.style.top = "0";
            Flip.from(this.outState, {
                duration: 0.5,
            });
        }
    }
}

const signTimeLine = new SignTimeLineStore();
export { signTimeLine };