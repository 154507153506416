import { useEffect, useState } from "react";
import styled from "styled-components";
import { service } from "../../mobx/service/service";

const ProfileContainer = styled.div({});
const AuthContainer = styled.div({});
const Child = styled.div({});
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 95;
  background-color: rgba(58, 56, 56, 0.5);
  ${ProfileContainer} {
    position: absolute;
    z-index: 96;
    top: 23%;
    left: 18%;
    width: 45%;
    height: 50%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #000;
    padding: 20px;
  }
  ${AuthContainer} {
    position: absolute;
    z-index: 96;
    bottom: 24%;
    right: 13%;
    width: 20%;
    height: 28%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-shrink: 0;
    ${Child} {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      font-family: one;
      margin-right: 2vw;
      span {
        font-size: 0.85vw;
        margin-right: 1vw;
        margin-top: 0.5vh;
        font-weight: 600;
      }
      input {
        font-family: one;
        font-size: 0.65vw;
        outline: none;
        border: 1px solid rgb(177, 175, 175);
        width: 12vw;
        height: 2.5vh;
        padding-left: 0.5vh;
        border-radius: 0.5vh;
      }
    }
    .upass {
      width: 5vw;
      height: 3vh;
      background-color: rgb(39, 114, 240);
      color: white;
      font-size: 0.85vw;
      font-family: one;
      border: none;
      border-radius: 0.5vh;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: rgb(249, 204, 226);
        color: rgb(39, 114, 240);
      }
    }
  }
  .goback {
    position: absolute;
    right: 13%;
    top: calc(23% + 20px);
    width: 4.5vw;
    height: 4vh;
    border-radius: 0.5vh;
    border: none;
    box-shadow: 3px 0 10px 0 #000;
    cursor: pointer;
    font-family: one;
    font-size: 0.8vw;
    background-color: #fff;
  }
`;

function PersonEdit({ getinfo, setControl, control, InfoUpdate }) {
  const [userData, setUserData] = useState({});
  const [isDone, setIsDone] = useState(false);
  const [password, setPassword] = useState("");
  useEffect(() => {
    getinfo({
      userid: control.userid,
      username: control.username,
    })
      .then((response) => {
        setUserData(response.data);
        setIsDone(true);
      })
      .catch((error) => {
        alert("获取信息失败");
      });
  }, [getinfo, control]);

  return (
    <Container>
      <ProfileContainer>
        {isDone &&<InfoUpdate
          $top={"11%"}
          $left={"14%"}
          $width={"80%"}
          $height={"80%"}
          userid={control.userid}
          isman={userData.gender}
          avater={userData.avater}
          name={userData.name}
          nickname={userData.nickname}
          school={userData.schoolnumber}
          level={userData.level}
          title={userData.title}
          genderr={userData.gender}
          permission={"pro"}
          update={service.info.updateProfileMethod}
          isManner={true}
        ></InfoUpdate>}
      </ProfileContainer>
      <button
        className="goback"
        onClick={() => {
          setControl({ ...control, isEdit: false });
        }}
      >
        返回
      </button>
      <AuthContainer>
        <Child>
          <span>密码</span>
          <input 
            type="text" 
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Child>
        <button 
            className="upass"
            onClick={()=>{
                console.log(password);
                service.user.mannerUpdatePasswordMethod({
                    userid: control.userid,
                    password: password,
                });
            }}
        >提交修改</button>
      </AuthContainer>
    </Container>
  );
}

export { PersonEdit };
