import { useLayoutEffect, useRef} from "react";
import styled from "styled-components";
import { timelineStore } from "../../mobx/timeline/timeLine";

const OpTwo = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  width: 87%;
  height: 22%;
  background-color: rgb(56, 56, 56);
  border-left: 0.4vw solid rgb(135, 152, 198);
  box-shadow: -10px 10px 9px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: left 1s;
  overflow: hidden;
  span {
    display: block;
    font-size: 2vw;
    font-family: art;
    font-weight: 900;
  }
  .ter {
    font-size: 1.2vw;
    display: none;
    position: absolute;
    right: 0;
  }
  .aa{
    position: absolute;
    left: 23%;
  }
  &:hover {
    .aa{
      left: 5%;
    }
  }
`;

export function OptionTwo() {
  const opRef = useRef();
  const fontRef=useRef();
  useLayoutEffect(() => {}, []);

  return (
    <OpTwo ref={opRef} 
    onMouseEnter={() => {
      timelineStore.optionline.optionTwoDo(opRef);
      timelineStore.optionline.optionTwoFontDo(fontRef);
    }} 
    onMouseLeave={() => {
      timelineStore.optionline.optionTwoLeave(opRef);
      timelineStore.optionline.optionTwoFontLeave(fontRef);
    }}>
      <span className="aa">A</span>
      <span className="ter" ref={fontRef}>Terminal</span>
    </OpTwo>
  );
}
