import { makeAutoObservable, reaction } from 'mobx';
import { timelineStore } from '../timeline/timeLine';
import { pageIndex } from './pageIndex';

class IsSecondRenderStore {

    isSecondRender = false;
    secondLoadDone = false;
    secondUnloadStart = false;
    upOrdown = null;
    constructor() {
        makeAutoObservable(this);
    }

    wheelDown = () => {
        if (this.secondLoadDone) {
            this.setSecondUnloadStart(true);
        }
    }
    setIsRender = (value) => {
        this.isSecondRender = value;
    }
    setSecondUnloadStart = (value) => {
        this.secondUnloadStart = value;
    }
    setSecondLoadDone = (value) => {
        this.secondLoadDone = value;
    }
    setIsDetail = (value) => {
        this.isDetail = value;
    }
    init = () => {
        this.setSecondUnloadStart(false);
        this.setSecondLoadDone(false);
        timelineStore.secondline.init();
    }
    setNextPage = () => {
        if (this.upOrdown) {
            pageIndex.setPageIndex(pageIndex.index + 1);
        } else {
            pageIndex.setPageIndex(pageIndex.index - 1);
        }
    }
    setUpOrDown = (value) => {
        this.upOrdown = value;
    }

}
const isSecond = new IsSecondRenderStore();
reaction(() => timelineStore.secondline.isLoadDone, (value, oldvalue) => {
    if (value) {
        isSecond.setSecondLoadDone(true);
    }
    pageIndex.setIsSet(value);
});
reaction(() => timelineStore.secondline.isTimeLineDone, (value, oldvalue) => {
    if (value) {
        isSecond.setIsRender(false);
        isSecond.setNextPage();
        isSecond.init();
    }
    pageIndex.setIsSet(value);
});
export { isSecond };