import { useLayoutEffect, useRef } from "react";
import {gsap} from 'gsap';
import styled from "styled-components";
import { timelineStore } from "../../mobx/timeline/timeLine";

const FontOne=styled.div({});
const FontTwo=styled.div({});
const Container = styled.div`
    font-family: one;
    width: 43%;
    height: 36%;
    position: absolute;
    top: 10.5%;
    right: 11%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    overflow: hidden;
    ${FontOne} {
        position: relative;
        left: -100%;
        opacity: 0;
        font-size: 2.5vw;
        margin-top: -2%;
        white-space: normal;
        word-break: break-all;
        line-height: 3vw;
        height: 30%;
        width: 80%;
        color: white;
    }
    ${FontTwo} {
        position: relative;
        left: -100%;
        opacity: 0;
        font-size: 1.3vw;
        margin-top: 1%;
        color: black;
    }
`;

export function SecondDetailTitle({main,detail}) {

    const titleRef=useRef();
    useLayoutEffect(()=>{
        let ctx=gsap.context(()=>{
            timelineStore.secondline.detailTitleDo(titleRef);
        },titleRef);

        return ()=>ctx.revert();
    },[]);

    return (
        <Container ref={titleRef}>
            <FontOne>{main}</FontOne>
            <FontTwo>-- {detail}</FontTwo>
        </Container>
    );
}