import { useState } from "react";
import styled from "styled-components";

const GroupContainer = styled.div({});
const GroupList = styled.div({});
const GroupItem = styled.div({});
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 95;
  background-color: rgba(58, 56, 56, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  ${GroupContainer} {
    width: 15%;
    height: 30%;
    background-color: #fff;
    border-radius: 1vh;
    font-family: one;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 0 10px 0 #000;
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 55%;
      span {
        display: block;
        width: 100%;
        font-size: 1.4vh;
        letter-spacing: 0.1vw;
      }
      ${GroupList} {
        display: flex;
        height: 12vh;
        width: 50%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
        margin-left: 3vw;
        ${GroupItem} {
          width: 100%;
          height: 2vh;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .choose {
            position: relative;
            width: 1.5vh;
            height: 1.5vh;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #757272;
            cursor: pointer;
          }
          .act {
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 0.9vh;
              height: 0.9vh;
              border-radius: 50%;
              background-color: rgb(102, 127, 191);
            }
          }
        }
      }
    }
    .bottom {
      display: flex;
      width: 90%;
      justify-content: space-evenly;
      button {
        width: 5vh;
        height: 2.5vh;
        background-color: rgb(39, 114, 240);
        color: white;
        font-size: 1.4vh;
        font-family: one;
        border: none;
        border-radius: 0.5vh;
        cursor: pointer;
      }
    }
  }
`;

const peData = [
    { name: "public", id: 0 },
    { name: "private", id: 1 },
    { name: "team", id: 2 },
];

function ProjectPermission({control, setControl,update}) {
  const [act, setAct] = useState("public");
  return (
    <Container>
      <GroupContainer>
        <section className="top">
          <span>当前权限为:{control.permission}</span>
          <span>更改为:</span>
          <GroupList>
            {
                peData.map((item) => {
                    return (
                    <GroupItem key={item.id}>
                        <p>{item.name}</p>
                        <div
                        className={act === item.name ? "choose act" : "choose"}
                        onClick={() => {
                            setAct(item.name);
                        }}
                        ></div>
                    </GroupItem>
                    );
                })
            }
          </GroupList>
        </section>
        <section className="bottom">
          <button
            onClick={()=>{
                if(control.permission!==act){
                    update({
                        id: control.id,
                        permission: act,
                    });
                    
                }else{
                    alert("新分组与当前分组相同");
                }
            }}
          >确认</button>
          <button
            onClick={() => {
              setControl({ ...control, isPermission: false });
            }}
          >
            取消
          </button>
        </section>
      </GroupContainer>
    </Container>
  );
}

export { ProjectPermission };
