import { makeAutoObservable, reaction} from 'mobx';
import {isFirst} from './isFirstRender';
import {isSecond} from './isSecondRender';
import {isThird} from './isThirdRender';
import {isFourth} from './isFourthRender';
import {isFifth} from './isFifthRender';
import {isOptionRender} from './isOptionRender';
import {pageIndex} from './pageIndex';

class IsRenderStore {

    firstRender=isFirst;
    secondRender=isSecond;
    thirdRender=isThird;
    fourthRender=isFourth;
    fifthRender=isFifth;
    optionRender=isOptionRender;
    pages=[this.firstRender,this.secondRender,this.thirdRender,this.fourthRender,this.fifthRender];
    constructor() {
        makeAutoObservable(this);
    }
    setPageIndex=(value)=>{
        this.pageIndex=value;
    }
    
}
const isRenderStore = new IsRenderStore();

reaction(()=>pageIndex.index,(value,oldvalue)=>{
    isRenderStore.pages[oldvalue].setIsRender(false);
    isRenderStore.pages[oldvalue].init();
    isRenderStore.pages[value].setIsRender(true);
});


export { isRenderStore };