import { makeAutoObservable } from 'mobx';
import {httpPort} from '../../utils/env';
import axios from 'axios';

class ProjectService {
    instance = axios.create({
        baseURL: `${httpPort}/project`,
        timeout: 15000,
        headers: { 'X-Custom-Header': 'foobar' }
    });
    constructor() {
        makeAutoObservable(this);
    }
    getUserProjectMethod = async(data={})=>{
       return await this.instance({
            method: 'post',
            url: '/userproject',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
    uploadChunkMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: '/uploadchunk',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            //console.log(response);
        }).catch((error)=>{
            //console.log(error);
        });
    }
    mergeChunkMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: '/mergechunk',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            //console.log(response);
        }).catch((error)=>{
            //console.log(error);
        });
    }

    createProjectMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: '/uploadproject',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            console.log(response);
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            console.log(error);
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }

    getOneProjectMethod = async(data)=>{
        return await this.instance({
            method: 'get',
            url: '/detailproject',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data,
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            console.log(error);
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }

    getProjectCountMethod = async(data)=>{
        return await this.instance({
            method: 'get',
            url: '/projectcount',
            headers: {
                'Content-Type': 'application/json',
            },
            params: data,
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            console.log(error);
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });

    }
    getProjectByTeamMethod = async(data)=>{
        return await this.instance({
            method: 'get',
            url: '/teamproject',
            headers: {
                'Content-Type': 'application/json',
            },
            params: data,
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            console.log(error);
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });

    }
    deleteProjectMethod = async(data={})=>{
        return await this.instance({
            method: 'post',
            url: '/deleteproject',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            if(response.data.issuccess){
                alert('删除成功');
                window.location.reload();
            }
        }).catch((error)=>{
            alert('删除失败');
        });

    }
    mannerUpdateProjectPermissionMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: '/mannerupdateproject',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            if(response.data.issuccess){
                alert('更新成功');
                window.location.reload();
            }
        }).catch((error)=>{
            alert('更新失败');
        });
    }
    getAllProjectMethod = async()=>{
        return await this.instance({
            method: 'get',
            url: '/allproject',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            console.log(error);
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
}

const projectService = new ProjectService();
export { projectService };