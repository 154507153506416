import { useState } from "react";
import styled from "styled-components";

const Left = styled.div({});
const Right = styled.div({});
const Search = styled.div({});
const List = styled.div({});
const ListChild = styled.div({});
const Container = styled.div`
  position: absolute;
  left: 24%;
  top: 27%;
  width: 55%;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${Left} {
    width: 30%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      display: block;
      font-family: one;
    }
    .name {
      font-size: 1.5vw;
      font-weight: 600;
    }
    .nick {
      font-size: 1.2vw;
      color: rgb(111, 119, 128);
      margin-top: -1.5vh;
      margin-bottom: 2vh;
    }
    .title {
      font-size: 1vw;
      color: black;
      width: 90%;
      height: 70%;
    }
    .date {
      font-size: 0.8vw;
    }
    .apart {
      width: 100%;
      height: 3px;
      background-color: rgb(175, 188, 202);
      margin-bottom: 1vh;
    }
  }
  ${Right} {
    width: 70%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${Search} {
      width: 100%;
      height: 8%;
      border-bottom: 2px solid rgb(175, 188, 202);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      input {
        border: 2px solid rgb(175, 188, 202);
        outline: none;
        background-color: transparent;
        width: 60%;
        height: 50%;
        border-radius: 0.5vh;
        padding: 0 1vh;
        font-family: one;
        font-size: 0.6vw;
        &:focus {
          box-shadow: 0 0 0 2px rgb(102, 127, 191);
        }
      }
      button {
        background-color: rgb(246, 248, 250);
        border: 2px solid rgb(175, 188, 202);
        border-radius: 0.5vh;
        height: 54%;
        cursor: pointer;
        font-family: one;
        font-size: 0.6vw;
        font-weight: 600;
        &:hover {
          background-color: rgb(232, 235, 238);
        }
      }
      .search {
        width: 8%;
      }
      .choosefa,
      .choosepe {
        position: relative;
        width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .sort {
          width: 0;
          height: 0;
          margin-left: 1vh;
          border-left: 0.5vh solid transparent;
          border-right: 0.5vh solid transparent;
          border-top: 0.5vh solid rgb(0, 0, 0);
        }
      }
      .choosefa {
        &:hover {
          .choose {
            display: block;
            width: 100%;
            height: 10vh;
            background-color: rgb(246, 248, 250);
            top: 105%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            span {
              height: 33%;
            }
          }
        }
      }
      .choosepe {
        width: 8%;
        &:hover {
          .type {
            width: 100%;
            height: 10vh;
            background-color: rgb(246, 248, 250);
            top: 105%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            span {
              height: 25%;
            }
          }
        }
      }
      .choose,
      .type {
        position: absolute;
        height: 0;
        //transition: all 0.5s;
        overflow: hidden;
        span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .chooseact {
          background-color: rgb(134, 137, 143);
          color: white;
        }
      }
    }
    ${List} {
      width: 100%;
      height: 88%;
      overflow: auto;
      scrollbar-width: none;
      padding: 1% 0;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      ${ListChild} {
        border-bottom: 2px solid rgb(175, 188, 202);
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .left {
          width: 70%;
          height: 80%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          span {
            font-family: one;
            display: block;
            font-family: one;
          }
          .pname {
            font-size: 1.1vw;
            font-weight: 600;
            color: rgb(9, 105, 218);
            display: flex;
            flex-direction: row;
            align-items: center;
            p {
              margin-left: 1.5vh;
              font-size: 0.55vw;
              width: 3vw;
              background-color: white;
              border-radius: 1vh;
              text-align: center;
            }
          }
          .ptitle {
            font-size: 0.65vw;
            color: rgb(111, 119, 128);
            /* margin-bottom: 1vh; */
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pdate {
            font-size: 0.55vw;
            margin-top: 0.5vh;
            color: rgb(111, 119, 128);
          }
        }
        .right {
          width: 18%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          button {
            background-color: white;
            border: 2px solid rgb(175, 188, 202);
            border-radius: 1vh;
            width: 45%;
            height: 3vh;
            font-family: one;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              background-color: rgb(232, 235, 238);
            }
          }
        }
      }
    }
  }
`;
const choose = [
  {
    name: "默认排序",
    act: 0,
  },
  {
    name: "时间排序",
    act: 1,
  },
  {
    name: "名称排序",
    act: 2,
  },
];
const type = [
  {
    name: "全部",
    act: 0,
  },
  {
    name: "私有",
    act: 1,
    permission: "private",
  },
  {
    name: "公开",
    act: 2,
    permission: "public",
  },
  {
    name: "团队可见",
    act: 3,
    permission: "team",
  },
];

function SpaceShow({
  name,
  nickname,
  title,
  date,
  projects,
  level,
  nagative,
  deletepr,
  isGuide = false,
}) {
  const [listData, setListData] = useState(projects);
  const [searchData, setSearchData] = useState("");
  const [chooseAct, setChooseAct] = useState(0);
  const [typeAct, setTypeAct] = useState(0);

  return (
    <Container>
      <Left>
        <span className="name">{name}</span>
        <span className="nick">{nickname}</span>
        <span className="title">{title}</span>
        <div className="apart"></div>
        <span className="date">
          于 {date} 加入 | {level}级
        </span>
      </Left>
      <Right>
        <Search>
          <input
            type="text"
            placeholder="查找你的作品"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
          ></input>
          <button
            className="search"
            onClick={() => {
              //console.log(searchData);
              setListData(
                projects.filter((project) => project.title.includes(searchData))
              );
            }}
          >
            查询
          </button>
          <button className="choosefa">
            排序 <div className="sort"></div>
            <div className="choose">
              {choose.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={chooseAct === item.act ? "chooseact" : ""}
                    onClick={() => {
                      setChooseAct(item.act);
                      switch (item.act) {
                        case 0:
                          setListData(projects);
                          break;
                        case 1:
                          setListData(
                            projects.sort((a, b) => {
                              return a.date - b.date;
                            })
                          );
                          break;
                        case 2:
                          setListData(
                            projects.sort((a, b) => {
                              return a.title.localeCompare(b.title);
                            })
                          );
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    {item.name}
                  </span>
                );
              })}
            </div>
          </button>
          <button className="choosepe">
            type <div className="sort"></div>
            {!isGuide && (
              <div className="type">
                {type.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={typeAct === item.act ? "chooseact" : ""}
                      onClick={() => {
                        setTypeAct(item.act);
                        switch (item.act) {
                          case 0:
                            setListData(projects);
                            break;
                          case 1:
                            setListData(
                              projects.filter((project) => {
                                return project.permission === "private";
                              })
                            );
                            break;
                          case 2:
                            setListData(
                              projects.filter((project) => {
                                return project.permission === "public";
                              })
                            );
                            break;
                          case 3:
                            setListData(
                              projects.filter((project) => {
                                return project.permission === "team";
                              })
                            );
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      {item.name}
                    </span>
                  );
                })}
              </div>
            )}
          </button>
        </Search>
        <List>
          {listData.map((project, index) => {
            return (
              <ListChild key={index}>
                <section className="left">
                  <span className="pname">
                    {project.title}
                    <p>{project.permission}</p>
                  </span>
                  <span className="ptitle">{project.description}</span>
                  <span className="pdate">{project.date}</span>
                </section>
                <section className="right">
                  <button
                    onClick={() => {
                      nagative(`/projectperson/${project.id}`);
                    }}
                  >
                    View
                  </button>
                  {!isGuide && <button onClick={() => {
                    // deletepr({id:project.id,title:project.title});
                    const res=window.confirm('确定删除该作品吗？');
                    if(res){
                      deletepr({id:project.id,title:project.title});
                    }
                  }}>Delete</button>}
                </section>
              </ListChild>
            );
          })}
        </List>
      </Right>
    </Container>
  );
}

export { SpaceShow };
