import styled from "styled-components";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { timelineStore } from "../mobx/timeline/timeLine";
import { SpaceAvater } from "../components/space/SpaceAvater";
import { SpaceList } from "../components/space/SpaceList";
import { useEffect, useState } from "react";
import { service } from "../mobx/service/service";
import { useNavigate } from "react-router-dom";
import { Square } from "../components/loading/Square";
import { SpaceLoad } from "../components/space/SpaceLoad";
import { SpaceShow } from "../components/space/SpaceShow";
import { SpaceInfo } from "../components/space/SpaceInfo";
import { SpaceProject } from "../components/space/SpaceProject";
import { MannerPerson } from "./MannerPerson";
import { DeviceShow } from "./DeviceShow";
import { DeviceManner } from "../components/device/DeviceManner";
import { MannerProject } from "./MannerProject";
import { MannerPage } from "./MannerPage";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;

function PersonSpace() {
  const nagative = useNavigate();
  const [active, setActive] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const [profileData, setProfileData] = useState();
  const [permissionData, setPermission] = useState();
  const [roleData, setRole] = useState();
  const [projectData, setProject] = useState([]);
  useEffect(() => {
    service.info
      .getInfoMethod()
      .then((res) => {
        setProfileData(res.data);
      })
      .catch(() => {
        //alert("获取信息失败,请重新登录");
        localStorage.removeItem("token");
        nagative("/sign");
      });
    service.sign
      .getPermissionMethod()
      .then((res) => {
        setPermission(res.data.permission);
      })
      .catch((error) => {
        //alert("获取权限失败,请重新登录");
        localStorage.removeItem("token");
        nagative("/sign");
      });
    service.project
      .getUserProjectMethod()
      .then((res) => {
        setProject(res.data);
      })
      .catch((error) => {
        //alert("获取项目失败,请重新登录");
        localStorage.removeItem("token");
        nagative("/sign");
      });
    service.sign
      .getRoleMethod()
      .then((res) => {
        setRole(res.data);
        setIsDone(true);
      })
      .catch((error) => {
        alert("请重新登录");
        localStorage.removeItem("token");
        nagative("/sign");
      });
      if(active===6){
        nagative("/");
      }
  }, [nagative, active]);

  function spaceContext(actid) {
    switch (actid) {
      case 0:
        return (
          <SpaceShow
            name={profileData.name}
            nickname={profileData.nickname}
            title={profileData.title}
            date={profileData.time}
            projects={projectData}
            level={profileData.level}
            nagative={nagative}
            deletepr={service.project.deleteProjectMethod}
          ></SpaceShow>
        );
      case 1:
        return (
          <SpaceInfo
            isman={profileData.gender}
            avater={profileData.avater}
            name={profileData.name}
            nickname={profileData.nickname}
            school={profileData.schoolnumber}
            level={profileData.level}
            title={profileData.title}
            genderr={profileData.gender}
            permission={permissionData.permission}
            update={service.info.updateProfileMethod}
          ></SpaceInfo>
        );
      case 2:
        localStorage.removeItem("token");
        //nagative("/sign");
        window.location.reload();
        break;
      case 3:
        return (
          <SpaceProject
          ></SpaceProject>
        );
      case 4:
        return (
          <MannerPage
            nagative={nagative}
          ></MannerPage>
        );
      case 5:
        return (
          <MannerPerson
            permission={permissionData.permission}
            school={profileData.schoolnumber}
          ></MannerPerson>
        );
        // case 7:
        //   return (
        //     <DeviceShow
        //     ></DeviceShow>
        //   );
        // case 8:
        //   return (
        //     <DeviceManner
        //     ></DeviceManner>
        //   );
        case 9:
          return (
            <MannerProject
              nagative={nagative}
            ></MannerProject>
          );
      default:
        break;
    }
  }

  return (
    <Container>
      {isDone &&profileData && permissionData && roleData && projectData? (
        <>
          <NormalBlockOb
            $width={"100%"}
            $height={"22%"}
            $shadow={"0px 0px 10px 5px rgba(0, 0, 0, 0.5)"}
            number={8}
            islay={false}
            animation={timelineStore.signline.signBlockDo}
            colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
          ></NormalBlockOb>
          <NormalFontBOb
            isFront={false}
            $width={"50%"}
            $height={"25%"}
            $top={"-2%"}
            $right={"10%"}
            $justify={"flex-start"}
            $align={"flex-end"}
            $fontsize={"6vw"}
            animation={timelineStore.signline.signBlackFontDo}
          ></NormalFontBOb>
          <SpaceAvater
            role={roleData.rolename}
            forwards={roleData.forwardsname}
            name={profileData.name}
            nickname={profileData.nickname}
            avater={profileData.avater}
          ></SpaceAvater>
          <SpaceList
            isDone={isDone}
            permission={permissionData.permission}
            role={roleData.rolename}
            setActive={setActive}
            active={active}
          ></SpaceList>
          {spaceContext(active)}
        </>
      ) : (
        <SpaceLoad Context={<Square></Square>}></SpaceLoad>
      )}
    </Container>
  );
}

export { PersonSpace };
