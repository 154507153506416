import { makeAutoObservable, reaction } from 'mobx';
import { timelineStore } from '../timeline/timeLine';
import { pageIndex } from './pageIndex';

class IsFirstRenderStore {

    isFirstRender = true;
    firstLoadDone = false;
    firstUnloadStart = false;
    upOrdown = null;
    constructor() {
        makeAutoObservable(this);
    }

    wheelDown = () => {
        if (this.firstLoadDone) {
            this.setFirstUnloadStart(true);
        }
    }

    setIsRender = (value) => {
        this.isFirstRender = value;
    }
    setFirstUnloadStart = (value) => {
        this.firstUnloadStart = value;
    }
    setFirstLoadDone = (value) => {
        this.firstLoadDone = value;
    }
    init = () => {
        this.firstLoadDone = false;
        this.firstUnloadStart = false;
        timelineStore.firstline.init();
    }
    setNextPage = () => {
        if (this.upOrdown) {
            pageIndex.setPageIndex(pageIndex.index + 1);
        } else {
            pageIndex.setPageIndex(pageIndex.index - 1);
        }
    }
    setUpOrDown = (value) => {
        this.upOrdown = value;
    }

}
const isFirst = new IsFirstRenderStore();

reaction(() => timelineStore.firstline.isTimelineDone, (value, oldvalue) => {
    if (value) {
        isFirst.setIsRender(false);
        isFirst.setNextPage();
        isFirst.init();
    }
    pageIndex.setIsSet(value);
});
reaction(() => timelineStore.firstline.isLoadDone, (value, oldvalue) => {
    if (value) {
        isFirst.setFirstLoadDone(true);
    }
    pageIndex.setIsSet(value);
});

export { isFirst };