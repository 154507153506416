import { makeAutoObservable, reaction } from 'mobx';
import { timelineStore } from '../timeline/timeLine';
import { pageIndex } from './pageIndex';

class IsThirdRenderStore {

    isThirdRender = false;
    thirdLoadDone = false;
    thirdUnloadStart = false;
    upOrdown = null;
    constructor() {
        makeAutoObservable(this);
    }
    wheelDown = () => {
        if (this.thirdLoadDone) {
            this.setThirdUnloadStart(true);
        }
    }

    setIsRender = (value) => {
        this.isThirdRender = value;
    }
    setThirdUnloadStart = (value) => {
        this.thirdUnloadStart = value;
    }
    setThirdLoadDone = (value) => {
        this.thirdLoadDone = value;
    }
    init = () => {
        this.setThirdUnloadStart(false);
        this.setThirdLoadDone(false);
        timelineStore.thirdline.init();
    }
    setNextPage = () => {
        if (this.upOrdown) {
            pageIndex.setPageIndex(pageIndex.index + 1);
        } else {
            pageIndex.setPageIndex(pageIndex.index - 1);
        }
    }
    setUpOrDown = (value) => {
        this.upOrdown = value;
    }
}

const isThird = new IsThirdRenderStore();

reaction(() => timelineStore.thirdline.isLoadDone, (value) => {
    if (value) {
        isThird.setThirdLoadDone(true);
    }
    pageIndex.setIsSet(value);
});
reaction(() => timelineStore.thirdline.isTimelineDone, (value) => {
    if (value) {
        isThird.setIsRender(false);
        isThird.setNextPage();
        isThird.init();
    }
    pageIndex.setIsSet(value);
});

export { isThird };