import styled from "styled-components";

const Loading = styled.div({});
const Container = styled.div`
    position: absolute;
    z-index: 1;
    width: 5vw;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    ${Loading}{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 0.4vh solid transparent;
        border-top-color:rgb(102, 127, 191);
        animation: loading 3s linear infinite;
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 0.4vh solid transparent;
            border-top-color:rgb(178, 193, 226);
            position: absolute;
            top: 0.3vh;
            left: -4%;
            animation: loading 2s linear infinite;
        }
        &::after{
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 0.4vh solid transparent;
            border-top-color:white;
            position: absolute;
            top: 1vh;
            left: -4%;
            animation: loading 1.5s linear infinite;
        }
    }
    @keyframes loading {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
`;


function Square() {

    return (
        <Container>
            <Loading></Loading>
        </Container>
    );
}

export { Square };