import styled from "styled-components";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { timelineStore } from "../mobx/timeline/timeLine";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { NormalFontWOb } from "../components/norml/NormalFontW";
import { NormalActOb } from "../components/norml/NormalAct";
import { FourthNameOb } from "../components/fourth/FourthName";
import { FourthSpecOb } from "../components/fourth/FourthSpec";
import { NormalContextOb } from "../components/norml/NormalContext";
import { FourthListOb } from "../components/fourth/FourthList";
import { isRenderStore } from "../mobx/render/isRender";
import { useEffect, useState } from "react";
import { service } from "../mobx/service/service";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  position: relative;
  overflow: hidden;
`;

export function FourthPage() {
  const [peoData, setPeoData] = useState([]);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    service.editpage
      .getAllPersonMethod()
      .then((res) => {
        setPeoData(res.data.data);
      })
      .catch((error) => {
        alert("获取人员失败");
      });
    return () => {
      setPeoData([]);
    };
  }, []);
  return (
    <Container
      onWheel={(e) => {
        if (e.deltaY > 0) {
          isRenderStore.fourthRender.setUpOrDown(1);
        } else {
          isRenderStore.fourthRender.setUpOrDown(0);
        }
        isRenderStore.fourthRender.wheelDown();
      }}
    >
      {peoData.length >0 && (
        <>
          <NormalBlockOb
            $width={"100%"}
            $height={"25%"}
            number={8}
            islay={false}
            animation={timelineStore.fourthline.fourthBlockDo}
            colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
          ></NormalBlockOb>
          <NormalFontBOb
            $width={"65%"}
            $height={"25%"}
            $left={"0%"}
            $top={"6vh"}
            isFront={true}
            animation={timelineStore.fourthline.fourthFontDo}
            $letterspace={"0.4vw"}
            $fontsize={"6vw"}
          ></NormalFontBOb>
          <NormalFontWOb
            value={"KEY MEMBER"}
            $top={"-12%"}
            $width={"55%"}
            $height={"25%"}
            $right={"-10%"}
            animation={timelineStore.fourthline.fourthKeyDo}
            $fontsize={"6vw"}
          ></NormalFontWOb>
          <NormalActOb
            $width={"27%"}
            $height={"82%"}
            $left={"calc(53% + 100px)"}
            $top={"18%"}
            animation={timelineStore.fourthline.fourthActDo}
            $img={peoData[index].img}
          ></NormalActOb>
          <FourthNameOb
            value={peoData[index].name}
            animation={timelineStore.fourthline.fourthNameDo}
          ></FourthNameOb>
          <FourthSpecOb></FourthSpecOb>
          <NormalContextOb
            $width={"42%"}
            $height={"30%"}
            $left={"6%"}
            $top={"calc(31.5% + 100px)"}
            $fontSize={"1.4vw"}
            animation={timelineStore.fourthline.fourthContextDo}
            value={peoData[index].description}
          ></NormalContextOb>
          <FourthListOb
            index={index}
            setIndex={setIndex}
            peoData={peoData}
          ></FourthListOb>
        </>
      )}
    </Container>
  );
}
