import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgb(150, 146, 146.0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`;

function SpaceLoad({Context}) {

    return (
        <Container>
            {Context}
        </Container>
    );
}

export { SpaceLoad};