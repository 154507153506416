import styled from "styled-components";
import { gsap } from "gsap";
import oneurl from "../../assets/svg/firstfontone.svg";
import twourl from "../../assets/svg/firstfonttwo.svg";
import { useLayoutEffect, useRef } from "react";
import { observer } from "mobx-react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { isRenderStore } from "../../mobx/render/isRender";

const Font = styled.div`
  width: 94%;
  height: 100%;
  padding: 0 3% 0 3%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.$justify};
  align-items: ${(props) => props.$align};
`;
const FontOne = styled.div`
  font-family: jhun;
  font-size: 1vw;
  font-weight: 800;
  object {
    width: 1vw;
    height: 1vw;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const FontTwo = styled.div`
  font-family: art;
  font-size: ${(props) => props.$fontsize};
  font-weight: 550;
  color: ${(props) => props.$fontColor};
  letter-spacing: ${(props) => props.$letterspace};
`;
const FontContainer = styled.div`
  position: ${(props) => props.$position};
  opacity: 0;
  z-index: 3;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
`;

function NormalFontB({
  isFront,
  animation,
  $width,
  $height,
  $bottom,
  $right,
  $top,
  $left,
  $justify,
  $align,
  $letterspace,
  $fontsize,
  $fontColor = "black",
  $position = "absolute",
}) {
  const fontRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
        if(animation){
            animation(fontRef);
        }
    }, fontRef);
    return () => {
      ctx.revert();
    };
  }, [animation]);
  if (isRenderStore.secondRender.secondUnloadStart) {
    timelineStore.secondline.secondFontUnload(fontRef);
  }else if(isRenderStore.thirdRender.thirdUnloadStart){
    timelineStore.thirdline.thirdFontUnload(fontRef);
  }else if(isRenderStore.fourthRender.fourthUnloadStart){
    timelineStore.fourthline.fourthFontUnload(fontRef);
  }else if(isRenderStore.fifthRender.fifthUnloadStart){
    timelineStore.fifthLine.fifthFontUnload(fontRef);
  }


  return (
    <FontContainer
      ref={fontRef}
      $position={$position}
      $width={$width}
      $height={$height}
      $right={$right}
      $top={$top}
      $left={$left}
      $bottom={$bottom}
    >
      <Font $justify={$justify} $align={$align}>
        {isFront ? (
          <>
            <FontOne>
              <object
                data={oneurl}
                type="image/svg+xml"
                aria-label="First Font"
              ></object>
              &nbsp;&nbsp;JHUN&nbsp;
              <object
                data={twourl}
                type="image/svg+xml"
                aria-label="First Font"
              ></object>
              &nbsp;BLACK JACK
            </FontOne>
            <FontTwo $fontColor={$fontColor} $fontsize={$fontsize} $letterspace={$letterspace}>ART & AI TEAM</FontTwo>
          </>
        ) : (
          <>
            <FontTwo $fontColor={$fontColor} $letterspace={$letterspace} $fontsize={$fontsize}>ART & AI TEAM</FontTwo>
            <FontOne>
              <object
                data={oneurl}
                type="image/svg+xml"
                aria-label="First Font"
              ></object>
              &nbsp;&nbsp;JHUN&nbsp;
              <object
                data={twourl}
                type="image/svg+xml"
                aria-label="First Font"
              ></object>
              &nbsp;BLACK JACK
            </FontOne>
          </>
        )}
      </Font>
    </FontContainer>
  );
}
const NormalFontBOb = observer(NormalFontB);
export { NormalFontBOb };
