import styled from "styled-components";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { timelineStore } from "../mobx/timeline/timeLine";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { NormalFontWOb } from "../components/norml/NormalFontW";
import { SignIn } from "../components/sign/SignIn";
import { NormalInput } from "../components/norml/NormalInput";
import { SignTitle } from "../components/sign/SignTitle";
import { SignButton } from "../components/sign/SignButton";
import { SignCheck } from "../components/sign/SignCheck";
import { useEffect, useState } from "react";
import { SignOut } from "../components/sign/SignOut";
import { service } from "../mobx/service/service";
import { useNavigate } from "react-router-dom";
import { VerifyCode } from "../components/sign/VerifyCode";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;

function Sign() {
  const [isIn, setIsIn] = useState(true);
  const nagative = useNavigate();
  const [inState, setInState] = useState({
    username: "",
    password: "",
    code: "",
  });
  const [outState, setOutState] = useState({
    username: "",
    name: "",
    password: "",
    nickname: "",
    code: "",
  });
  const [isman, setIsman] = useState(true);

  const handleChange = (e, setfun, state, pro) => {
    setfun({ ...state, [pro]: e.target.value });
  };
  useEffect(() => {
    setInState({
      username: "",
      password: "",
      code: "",
    });
    setOutState({
      username: "",
      name: "",
      password: "",
      nickname: "",
      code: "",
    });
  }, [isIn]);
  return (
    <Container>
      <NormalBlockOb
        $width={"100%"}
        $height={"22%"}
        number={8}
        islay={false}
        animation={timelineStore.signline.signBlockDo}
        colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
      ></NormalBlockOb>
      <NormalFontWOb
        value={"JOIN US NOW"}
        $left={"-44%"}
        $top={"-1%"}
        $width={"50%"}
        $height={"25%"}
        $fontsize={"6vw"}
        animation={timelineStore.signline.signWhiteFontDo}
      ></NormalFontWOb>
      <NormalFontBOb
        isFront={false}
        $width={"50%"}
        $height={"25%"}
        $top={"-4.3%"}
        $right={"10%"}
        $justify={"flex-start"}
        $align={"flex-end"}
        $fontsize={"6vw"}
        animation={timelineStore.signline.signBlackFontDo}
      ></NormalFontBOb>
      {isIn ? (
        <SignIn
          Context={
            <>
              <NormalInput
                text={"学号"}
                placeholder={"请输入你的学号"}
                value={inState.username}
                onChange={(e) =>
                  handleChange(e, setInState, inState, "username")
                }
              ></NormalInput>
              <NormalInput
                text={"密码"}
                placeholder={"请输入你的密码"}
                value={inState.password}
                onChange={(e) =>
                  handleChange(e, setInState, inState, "password")
                }
              ></NormalInput>
              <VerifyCode
                Context={
                  <NormalInput
                    $width={"55%"}
                    text={"验证码"}
                    placeholder={"请输入你的验证码"}
                    value={inState.code}
                    onChange={(e) =>
                      handleChange(e, setInState, inState, "code")
                    }
                  ></NormalInput>
                }
              ></VerifyCode>
            </>
          }
          Title={<SignTitle text={"欢迎回来，请登录"}></SignTitle>}
          Button={
            <SignButton
              event={() => {
                service.sign.signInMethod({
                  ...inState,
                  uuid: localStorage.getItem("codeid"),
                });
              }}
              text={"登录"}
            ></SignButton>
          }
        ></SignIn>
      ) : (
        <SignOut
          isman={isman}
          setIsman={setIsman}
          Context={
            <>
              <NormalInput
                $width={"55%"}
                text={"学号"}
                placeholder={"请输入你的学号"}
                value={outState.username}
                onChange={(e) =>
                  handleChange(e, setOutState, outState, "username")
                }
              ></NormalInput>
              <NormalInput
                $width={"35%"}
                text={"姓名"}
                placeholder={"请输入你的姓名"}
                value={outState.name}
                onChange={(e) => handleChange(e, setOutState, outState, "name")}
              ></NormalInput>
            </>
          }
          Context2={
            <>
              <NormalInput
                $width={"55%"}
                text={"密码"}
                placeholder={"请输入你的密码"}
                value={outState.password}
                onChange={(e) =>
                  handleChange(e, setOutState, outState, "password")
                }
              ></NormalInput>
              <NormalInput
                $width={"35%"}
                text={"昵称"}
                placeholder={"请输入你的昵称"}
                value={outState.nickname}
                onChange={(e) =>
                  handleChange(e, setOutState, outState, "nickname")
                }
              ></NormalInput>
            </>
          }
          Context3={
            <VerifyCode
            Context={
              <NormalInput
                $width={"55%"}
                text={"验证码"}
                placeholder={"请输入你的验证码"}
                value={outState.code}
                onChange={(e) =>
                  handleChange(e, setOutState, outState, "code")
                }
              ></NormalInput>
            }
          ></VerifyCode>
          }
          Title={<SignTitle text={"创建一个账号并继续"}></SignTitle>}
          Button={
            <SignButton
              event={() => {
                service.sign.signUpMethod({
                  ...outState,
                  uuid: localStorage.getItem("codeid"),
                  gender: isman ? "男" : "女",
                });
              }}
              text={"注册"}
            ></SignButton>
          }
        ></SignOut>
      )}
      <SignCheck isIn={isIn} setIsIn={setIsIn} nagative={nagative}></SignCheck>
    </Container>
  );
}

export { Sign };
