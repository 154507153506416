import styled from 'styled-components';

const Left=styled.section({});
const Right=styled.section({});
const Container = styled.div`
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${Left}{
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: jhun;
      font-size: 1vw;
    }
    ${Right}{
      width: 55%;
      height: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      button {
        position: relative;
        width: 8%;
        height: 67%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgb(246, 248, 250);
        font-family: one;
        font-size: 0.7vw;
        font-weight: 600;
        cursor: pointer;
        border-radius: 0.5vh;
        border: 2px solid rgb(175, 188, 202);
        margin:0 0.7vh;
      }
    }
`;

function MannerTitle({font,Context}) {
  return (
    <Container>
        <Left>{font}</Left>
        <Right>{Context}</Right>
    </Container>
  );
}

export { MannerTitle };