import { useEffect, useState } from "react";
import styled from "styled-components";
import { service } from "../mobx/service/service";
import { PersonEdit } from "../components/manner/PersonEdit";
import { SpaceInfo } from "../components/space/SpaceInfo";
import { PersonDelete } from "../components/manner/PersonDelete";
import { PersonGroup } from "../components/manner/PersonGroup";
import { PersonRoleAdd } from "../components/manner/PersonRoleAdd";
import { PersonRoleUp } from "../components/manner/PersonRoleUp";

const Top = styled.div({});
const Bottom = styled.div({});
const BottomItem = styled.div({});
const BottomTitle = styled.div({});
const Container = styled.div`
  position: absolute;
  left: 20%;
  top: 25%;
  width: 75%;
  height: 72%;
  //background-color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${Top} {
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //border-bottom: 2px solid rgb(175, 188, 202);
    .topleft {
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: jhun;
      font-size: 1vw;
    }
    .topright {
      width: 55%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      input {
        border: 2px solid rgb(175, 188, 202);
        outline: none;
        background-color: transparent;
        width: 50%;
        height: 50%;
        border-radius: 0.5vh;
        padding: 0 1vh;
        font-family: one;
        font-size: 0.6vw;
        &:focus {
          box-shadow: 0 0 0 2px rgb(102, 127, 191);
        }
      }
      .topselect {
        position: relative;
        width: 13%;
        height: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgb(246, 248, 250);
        font-family: one;
        font-size: 0.7vw;
        font-weight: 600;
        cursor: pointer;
        border-radius: 0.5vh;
        border: 2px solid rgb(175, 188, 202);
        .sort {
          width: 0;
          height: 0;
          margin-left: 1vh;
          border-left: 0.5vh solid transparent;
          border-right: 0.5vh solid transparent;
          border-top: 0.5vh solid rgb(0, 0, 0);
        }
        &:hover {
          .choose {
            display: block;
            width: 100%;
            height: 10vh;
            background-color: rgb(246, 248, 250);
            top: 105%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            span {
              height: 33%;
            }
          }
        }
      }
      .choose {
        position: absolute;
        height: 0;
        //transition: all 0.5s;
        overflow: hidden;
        span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .chooseact {
          background-color: rgb(134, 137, 143);
          color: white;
        }
      }
      button {
        position: relative;
        width: 8%;
        height: 67%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgb(246, 248, 250);
        font-family: one;
        font-size: 0.7vw;
        font-weight: 600;
        cursor: pointer;
        border-radius: 0.5vh;
        border: 2px solid rgb(175, 188, 202);
      }
    }
  }
  ${Bottom} {
    width: 100%;
    height: 93%;
    //background-color: aliceblue;
    overflow: auto;
    scrollbar-width: none;
    padding: 2% 0 0 0;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    ${BottomTitle},${BottomItem} {
      border-bottom: 2px solid rgb(175, 188, 202);
      width: 100%;
      height: 4%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      section {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: one;
        font-size: 0.65vw;
        font-weight: 600;
        letter-spacing: 0.1vw;
        //background-color: aqua;
      }
      .school {
        width: 16%;
      }
      .name {
        width: 7%;
      }
      .level {
        width: 5%;
      }
      .group {
        width: 8%;
      }
      .forward {
        width: 12%;
      }
      .control {
        width: 18%;
      }
    }
    ${BottomItem} {
      height: 6%;
      .control {
        width: 18%;
        p {
          margin: 0 1vh;
          color: rgb(102, 127, 191);
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
`;

const type = [
  { name: "全部", act: 0 },
  { name: "学号搜索", act: 1 },
  { name: "年级搜索", act: 2 },
  { name: "姓名搜索", act: 3 },
  { name: "角色搜索", act: 4 },
];

const group = [
  { name: "全部", act: 0 },
  { name: "游戏组", act: 1 },
  { name: "美术组", act: 2 },
  { name: "普通成员", act: 3 },
];

const roleSolve = (role) => {
  switch (role) {
    case "art":
      return "美术组";
    case "game":
      return "游戏组";
    case "normal":
      return "普通成员";
    default:
      return "未知";
  }
};

function MannerPerson({permission, school}) {
  const [typeAct, setTypeAct] = useState(0);
  const [groupAct, setGroupAct] = useState(0);
  const [data, setData] = useState();
  const [groupData, setGroupData] = useState();
  const [forwardData, setForwardData] = useState();
  const [initData, setInitData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [control, setControl] = useState({
    isEdit: false,
    isDelete: false,
    isRole: false,
    isRoleAdd: false,
    isGroup: false,
  });

  useEffect(() => {
    service.user
      .getAllUserMethod()
      .then((response) => {
        setInitData(response.data.data.filter((item) => item.user_username !== school && item.user_permission !== "pro"));
        setData(response.data.data.filter((item) => item.user_username !== school && item.user_permission !== "pro"));
      })
      .catch((error) => {
        alert(error + "请联系负责人");
      });
    service.forole
      .mannerGetALLRolesMethod()
      .then((response) => {
        setGroupData(response.data.data);
      })
      .catch((error) => {
        alert(error + "请联系负责人");
      });
    service.forole
      .mannerGetALLForwardsMethod()
      .then((response) => {
        setForwardData(response.data.data);
      })
      .catch((error) => {
        alert(error + "请联系负责人");
      });
  }, [school]);

  const searchInit = () => {
    const handleMap = new Map();
    const first = ["all", "game", "art", "normal"];
    const last = [
      "all",
      "user_username",
      "user_level",
      "user_name",
      "user_forward",
    ];
    const dataFilter = (filter) => {
      return initData.filter((item) => {
        for (const key in filter) {
          if (item[key] !== filter[key]) {
            return false;
          }
        }
        return true;
      });
    };
    first.forEach((firstItem) => {
      last.forEach((lastItem) => {
        let filter = {};
        if (firstItem !== "all") {
          filter.user_role = firstItem;
        }
        if (lastItem !== "all") {
          filter[lastItem] = searchValue;
        }
        handleMap.set(
          `${first.indexOf(firstItem)},${last.indexOf(lastItem)}`,
          dataFilter(filter)
        );
      });
    });
    handleMap.set("0,0", initData);
    return handleMap;
  };

  return (
    <>
      <Container>
        <Top>
          <section className="topleft">成员管理</section>
          <section className="topright">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <div className="topselect">
              类型
              <div className="sort"></div>
              <div className="choose">
                {type.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={item.act === typeAct ? "chooseact" : ""}
                      onClick={() => setTypeAct(item.act)}
                    >
                      {item.name}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="topselect">
              分组
              <div className="sort"></div>
              <div className="choose">
                {group.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={item.act === groupAct ? "chooseact" : ""}
                      onClick={() => setGroupAct(item.act)}
                    >
                      {item.name}
                    </span>
                  );
                })}
              </div>
            </div>
            <button
              onClick={() => {
                const result = searchInit();
                setData(result.get(`${groupAct},${typeAct}`));
              }}
            >
              筛选
            </button>
            <button
              onClick={() => {
                setControl({ ...control, isRoleAdd: true });
              }}
            >角色</button>
          </section>
        </Top>
        <Bottom>
          <BottomTitle>
            <section className="school">学号</section>
            <section className="name">姓名</section>
            <section className="level">年级</section>
            <section className="group">组织</section>
            <section className="forward">角色</section>
            <section className="control">操作</section>
          </BottomTitle>
          {data &&
            data.map((item, index) => {
              return (
                <BottomItem key={index}>
                  <section className="school">{item.user_username}</section>
                  <section className="name">{item.user_name}</section>
                  <section className="level">{item.user_level}</section>
                  <section className="group">
                    {roleSolve(item.user_role)}
                  </section>
                  <section className="forward">{item.user_forward}</section>
                  <section className="control">
                    <p
                      onClick={() => {
                        setControl({ 
                          ...control, 
                          isEdit: true,
                          userid: item.user_id,
                          username: item.user_username,
                        });
                      }}
                    >
                      编辑
                    </p>
                    <p
                      onClick={() => {
                        setControl({ 
                          ...control, 
                          isDelete: true,
                          userid: item.user_id,
                          username: item.user_username, 
                        });
                      }}
                    >
                      删除
                    </p>
                    <p
                      onClick={() => {
                        setControl({ 
                          ...control, 
                          isRole: true,
                          userid: item.user_id,
                          permission: item.user_permission,
                          role: item.user_role,
                        });
                      }}
                    >
                      角色操作
                    </p>
                    <p
                      onClick={() => {
                        setControl({ 
                          ...control, 
                          isGroup: true,
                          nowGroup: roleSolve(item.user_role),
                          userid: item.user_id, 
                        });
                      }}
                    >
                      更改分组
                    </p>
                  </section>
                </BottomItem>
              );
            })}
        </Bottom>
      </Container>
      {control.isEdit && (
        <PersonEdit 
          control={control} 
          setControl={setControl}
          InfoUpdate={SpaceInfo}
          getinfo={service.info.mannerGetInfoMethod}
          ></PersonEdit>
      )}
      {control.isDelete && 
        <PersonDelete
          control={control} 
          setControl={setControl}
          deleteUser={service.user.mannerDeleteUserMethod}
        ></PersonDelete>
      }
      {
        control.isGroup &&
        <PersonGroup
          control={control} 
          setControl={setControl}
          groupData={groupData}
          roleSolve={roleSolve}
          update={service.forole.mannerUpdateRoleMethod}
        ></PersonGroup>
      }
      {
        control.isRoleAdd && 
        <PersonRoleAdd
          control={control} 
          setControl={setControl}
          groupData={groupData}
          roleSolve={roleSolve}
          add={service.forole.mannerAddForwardMethod}
        ></PersonRoleAdd>
      }
      {
        control.isRole && 
        <PersonRoleUp
          control={control} 
          setControl={setControl}
          group={group}
          forwardData={forwardData}
          roleSolve={roleSolve}
          update={service.forole.mannerUpdateForwardMethod}
          peUpdate={service.user.mannerUpdatePermissionMethod}
          permission={permission}
        ></PersonRoleUp>
      }
    </>
  );
}

export { MannerPerson };
