import styled from "styled-components";
import { gsap } from "gsap";
import { useEffect,  useRef } from "react";
import { observer } from "mobx-react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { isRenderStore } from "../../mobx/render/isRender";

const Container = styled.div`
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  opacity: 0;
  position: absolute;
  width: 0%;
  height: 30%;
  bottom: 4%;
  right: 52%;
  display: flex;
`;
const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: max-content;
`;

const ListChild = styled.div`
  position: relative;
  width: 9vw;
  height: 100%;
  margin-right: 1.5vw;
  background-image: url(${(props)=>props.$img});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  span {          
    font-family: one;
    position: absolute;
    bottom: 9%;
    left: 9%;
    width: 100%;
    height: 5%;
    font-size: 1.2vw;
    color: white;
  }
`;

let scroll = 0;
let count = 0;

function FourthList({peoData,setIndex,index}) {
  const listRef = useRef();
  const scrollRef = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      timelineStore.fourthline.fourthListDo(listRef);
    }, listRef);
    const id=setInterval(()=>{
      //  console.log(10.5*window.innerWidth/100);
      // console.log(listRef.current.scrollWidth);
      // console.log(scrollRef.current.offsetWidth);
      if(
        scroll+10.5*window.innerWidth/100>=listRef.current.scrollWidth-100
      ){
        scroll=0;
        count=0;
        setIndex(count);
        listRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }else{
        scroll+=10.5*window.innerWidth/100;
        count+=1;
        setIndex(count);
        listRef.current.scrollTo({
          top: 0,
          left: scroll,
          behavior: "smooth",
        });
      }
      //listRef.current.scrollTo(10.5*window.innerWidth/100,0);

    },4000);
    
    return async () => {
      await new Promise((resolve) => {clearInterval(id);resolve();});
      ctx.revert();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peoData]);
  if (isRenderStore.fourthRender.fourthUnloadStart) {
    timelineStore.fourthline.fourthListUnload(listRef);
  }
  return (
    <Container ref={listRef}>
      <ScrollWrapper ref={scrollRef}>
        {
          peoData.map((item,index)=>{
            return (
              <ListChild key={index} $img={item.img}>
                <span>{item.name}</span>
              </ListChild>
            );
          })
        }
      </ScrollWrapper>
    </Container>
  );
}
const FourthListOb = observer(FourthList);
export { FourthListOb };
