import styled from "styled-components";
import view from "../../assets/png/view.png";
import { useEffect } from "react";

const Child = styled.div({});
const Container = styled.div`
  width: 80%;
  min-height: 68vh;
  //height: 190vh;
  //background-color: aliceblue;
  margin-top: 25vh;
  margin-left: 15vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  //overflow-y: auto;
  ${Child} {
    width: 25vw;
    height: 34vh;
    flex-shrink: 0;
    background-color: aqua;
    margin: 1.2% 0.1% 1.2% 1.35%;
    font-family: one;
    border-radius: 0.5vh;
    cursor: pointer;
    &:hover {
      box-shadow: 0.5vh 0.5vh 0.8vh rgba(0, 0, 0, 0.2);
    }
    .front {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 70%;
      img {
        width: 100%;
        height: 100%;
        border-top-right-radius: 0.5vh;
        border-top-left-radius: 0.5vh;
      }
      span {
        width: 94%;
        padding: 0 3%;
        height: 5vh;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        font-size: 0.55vw;
        overflow: hidden;
        background-image: linear-gradient(to top, #202020, transparent);
        img {
          width: 1.5vw;
          height: 1.5vw;
          filter: drop-shadow(white 200px 0);
          transform: translateX(-200px);
          margin-right: 0.5vh;
        }
      }
    }
    .next {
      position: relative;
      z-index: 1;
      width: 94%;
      padding: 0 3%;
      height: 30%;
      background-color: white;
      border-bottom-right-radius: 0.5vh;
      border-bottom-left-radius: 0.5vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .title {
        font-size: 1.1vw;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info {
        color: rgb(137, 137, 137);
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          font-size: 0.65vw;
          margin-right: 0.5vw;
        }
      }
    }
  }
`;

function Projects({ projectData,navgation }) {
  useEffect(() => {}, [projectData]);
  return (
    <Container>
      {projectData.map((item, index) => {
        return (
          <Child key={index} onClick={()=>{navgation(`/projectperson/${item.id}`);}}>
            <section className="front">
              <img src={item.imgs.split(";")[0]} alt="" />
              <span>
                <img src={view} alt="" />
                {item.view}
              </span>
            </section>
            <section className="next">
              <div className="title">{item.title}</div>
              <div className="info">
                <span>{item.user.profile.name}-{item.user.profile.level}</span>
                <span>|</span>
                <span>{item.date}</span>
              </div>
            </section>
          </Child>
        );
      })}
    </Container>
  );
}

export { Projects };
