import { useEffect, useState } from "react";
import styled from "styled-components";

const Left = styled.div({});
const Right = styled.div({});
const Container = styled.div`
  position: relative;
  z-index: 3;
  width: 55%;
  height: 47%;
  background-color: rgba(49, 49, 49, 0.8);
  position: absolute;
  left: 10%;
  top: 10%;
  border-radius: 0.5vw;
  border: 0.2vw solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  ${Left} {
    width: 78%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video,img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  ${Right} {
    width: 22%;
    height: 100%;
    //background-color: green;
    border-left: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    .middle {
      width: 90%;
      height: 95%;
      border-radius: 0.3vh;
      overflow: auto;
      scrollbar-width: none;
      padding: 1% 0;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      img{
        width: 98%;
        height: 10vh;
        object-fit: cover;
        border: 1px solid white;
        border-radius: 0.3vh;
        margin-bottom: 0.5vh;
        cursor: pointer;
      }
      .videoCon{
        position: relative;
        width: 98%;
        border: 1px solid white;
        border-radius: 0.3vh;
        margin-bottom: 0.5vh;
        cursor: pointer;
        .control{
          position: absolute;
          width: 3vw;
          height: 3vw;
          top: calc(50% - 1.5vw);
          left: calc(50% - 1.5vw);
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 50%;

          &::after{
            content: "";
            position: absolute;
            top: calc(50% - 0.37vw);
            left: calc(50% - 0.2vw);
            border-top: 0.5vw solid transparent;
            border-bottom: 0.5vw solid transparent;
            border-left: 0.7vw solid white;
          }
        }
        video{
          width: 100%;
          height: 10vh;
          object-fit: cover;
        }
      }
      //background-color: yellow;
    }
  }
`;

function ProjectVimg({ video, imgs }) {
  const [listData,setListData] = useState([]);
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    video.split(";").map((item, index) => {
      if (item !== "") {
        setListData((data)=>{
          return [...data,{
            type: "video",
            url: item,
          }];
        });
      }
      return null;
    });
    imgs.split(";").map((item, index) => {
      if (item !== "") {
        setListData((data)=>{
          return [...data,{
            type: "img",
            url: item,
          }];
        });
      }

      return null;
    });
    
  }, [video, imgs]);
  return (
    <Container>
      <Left>
        {
          listData.length>0 && listData[current] && listData[current].type === "video" ? (
            <video key={current} controls>
              <source src={listData[current].url} type="video/mp4" />
            </video>
          ):listData.length>0 && listData[current] &&(
            <img src={listData[current].url} alt="img" />
          )
        }
      </Left>
      <Right>
        <section className="middle">
          {listData.map((item, index) => {
            if (item.type === "video") {
              return (
                <div className="videoCon" key={index} onClick={()=>{console.log(index);setCurrent(index);}}>
                  <div className="control"></div>
                  <video>
                    <source src={item.url} type="video/mp4" />
                  </video>
                </div>
              );
            } else {
              return <img key={index} src={item.url} onClick={()=>{setCurrent(index);}} alt="img" />;
            }
          })}
        </section>
      </Right>
    </Container>
  );
}

export { ProjectVimg };
