import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';
import { Flip } from "gsap/Flip";
gsap.registerPlugin(Flip);

class OptionTimeLineStore {

    optionTimeLine = gsap.timeline();
    oneState = null;
    fontState = null;
    rightState = null;
    lefState = null;
    upState = null;
    downState = null;
    twoState = null;
    twoFontState = null;
    constructor() {
        makeAutoObservable(this);
    }

    setOneState = (state) => {
        this.oneState = state;
    }
    setFontState = (state) => {
        this.fontState = state;
    }
    setRightState = (state) => {
        this.rightState = state;
    }
    setLefState = (state) => {
        this.lefState = state;
    }
    setUpState = (state) => {
        this.upState = state;
    }
    setDownState = (state) => {
        this.downState = state;
    }
    setTwoState = (state) => {
        this.twoState = state;
    }
    setTwoFontState = (state) => {
        this.twoFontState = state;
    }

   
    optionOneDo = (opRef) => {
        if (opRef.current) {
            this.setOneState(Flip.getState(opRef.current,{props: ['borderLeftWidth']}));
            opRef.current.style.width = "234%";
            opRef.current.style.height = "195%";
            opRef.current.style.borderLeftWidth = "0px";
            Flip.from(this.oneState, {
                duration: 0.5,
            });
        }
    }
    optionOneLeave = (opRef) => {
        if (opRef.current) {
            this.setOneState(Flip.getState(opRef.current,{props: ['borderLeftWidth']}));
            opRef.current.style.width = "87%";
            opRef.current.style.height = "70%";
            opRef.current.style.borderLeftWidth = "0.4vw";
            Flip.from(this.oneState, {
                duration: 0.5,
            });
        }
    }
    optionFontDo = (fontRef) => {
        if (fontRef.current) {
            this.setFontState(Flip.getState(fontRef.current,{props: ['opacity','display']}));
            fontRef.current.style.display = "none";
            Flip.from(this.fontState, {
                duration: 0.1,
            });
        }
    }
    optionFontLeave = (fontRef) => {
        if (fontRef.current) {
            this.setFontState(Flip.getState(fontRef.current,{props: ['opacity','display']}));
            fontRef.current.style.display = "flex";
            Flip.from(this.fontState, {
                duration: 0.1,
            });
        }
    }
    optionListDo = (lefRef) => {
        if (lefRef.current) {
            this.setLefState(Flip.getState(lefRef.current,{props: ['display','opacity']}));
            lefRef.current.style.opacity = "1";
            lefRef.current.style.display = "flex";
            Flip.from(this.lefState, {
                duration: 0.1,
            });
        }
    }
    optionListLeave = (lefRef) => {
        if (lefRef.current) {
            this.setLefState(Flip.getState(lefRef.current,{props: ['display','opacity']}));
            lefRef.current.style.opacity = "0";
            lefRef.current.style.display = "none";
            Flip.from(this.lefState, {
                duration: 0.1,
            });
        }
    }

    optionRightDo = (rightRef) => {
        if (rightRef.current) {
            this.setRightState(Flip.getState(rightRef.current,{props: ['borderTop','borderRight','borderBottom','borderLeft','top','left']}));
            rightRef.current.style.borderRight = "none";
            // rightRef.current.style.borderLeft = "1.5vw solid white";
            // rightRef.current.style.top = "40%";
            // rightRef.current.style.left="0";
            Flip.from(this.rightState, {
                duration: 0.05,
            });
        }
    }
    optionRightLeave = (rightRef) => {
        if (rightRef.current) {
            this.setRightState(Flip.getState(rightRef.current,{props: ['borderTop','borderRight','borderBottom','borderLeft','top','left']}));
            rightRef.current.style.borderRight = "1.5vw solid rgb(56, 56, 56)";
            rightRef.current.style.borderLeft = "none";
            rightRef.current.style.top = "65%";
            rightRef.current.style.left="-1vw";
            Flip.from(this.rightState, {
                duration: 0.05,
            });
        }
    }
    optionButtonDo=(upRef,downRef)=>{
        if (upRef.current) {
            this.setUpState(Flip.getState(upRef.current,{props: ['display']}));
            upRef.current.style.display = "block";
            Flip.from(this.upState, {
                duration: 0.1,
            });
        }
        if (downRef.current) {
            this.setDownState(Flip.getState(downRef.current,{props: ['display']}));
            downRef.current.style.display = "block";
            Flip.from(this.downState, {
                duration: 0.1,
            });
        }
    }
    optionButtonLeave=(upRef,downRef)=>{
        if (upRef.current) {
            this.setUpState(Flip.getState(upRef.current,{props: ['display']}));
            upRef.current.style.display = "none";
            Flip.from(this.upState, {
                duration: 0.1,
            });
        }
        if (downRef.current) {
            this.setDownState(Flip.getState(downRef.current,{props: ['display']}));
            downRef.current.style.display = "none";
            Flip.from(this.downState, {
                duration: 0.1,
            });
        }
    }

    optionTwoDo = (opRef) => {
        if (opRef.current) {
            this.setTwoState(Flip.getState(opRef.current));
            opRef.current.style.width = "280%";
            Flip.from(this.twoState, {
                duration: 0.3,
            });
        }
    }
    optionTwoLeave = (opRef) => {
        if (opRef.current) {
            this.setTwoState(Flip.getState(opRef.current));
            opRef.current.style.width = "87%";
            Flip.from(this.twoState, {
                duration: 0.3,
            });
        }
    }
    optionTwoFontDo = (fontRef) => {
        if (fontRef.current) {
            this.setTwoFontState(Flip.getState(fontRef.current,{props: ['display']}));
            fontRef.current.style.display = "block";
            Flip.from(this.twoFontState, {
                duration: 0.1,
            });
        }
    }
    optionTwoFontLeave = (fontRef) => {
        if (fontRef.current) {
            this.setTwoFontState(Flip.getState(fontRef.current,{props: ['display']}));
            fontRef.current.style.display = "none";
            Flip.from(this.twoFontState, {
                duration: 0.1,
            });
        }
    }
}

const optionTimeLine = new OptionTimeLineStore();
export { optionTimeLine };