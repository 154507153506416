import { gsap } from "gsap";
import { TextPlugin } from "gsap/all";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { isRenderStore } from "../../mobx/render/isRender";

gsap.registerPlugin(TextPlugin);

const Child = styled.span({});
const Container = styled.div`
  opacity: 0;
  width: 40%;
  height: 30%;
  position: absolute;
  left: 30%;
  bottom: calc(13% - 100px);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${Child} {
    cursor: pointer;
    --stop1: 0%;
    --stop2: 0%;
    width: 96%;
    padding: 0 2% 0 2%;
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: one;
    color: white;
    background: linear-gradient(
      to right,
      white var(--stop1),
      rgb(102, 127, 191) var(--stop2)
    );
    p {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
`;
// const actData = [
//   { id: 0, name: "历史活动_1", date: "xxxx/xx/xx" },
//   { id: 1, name: "历史活动_2", date: "xxxx/xx/xx" },
//   { id: 2, name: "历史活动_3", date: "xxxx/xx/xx" },
//   { id: 3, name: "历史活动_4", date: "xxxx/xx/xx" },
//   { id: 4, name: "历史活动_5", date: "xxxx/xx/xx" },
//   { id: 5, name: "历史活动_6", date: "xxxx/xx/xx" },
//   { id: 6, name: "历史活动_7", date: "xxxx/xx/xx" },
//   { id: 7, name: "历史活动_8", date: "xxxx/xx/xx" },
// ];
const cleanAct = [];

function SecondTitle({actData,nagative}) {
  const tiRef = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      const moveAct = (id) => {
        gsap.to(tiRef.current.children[id], {
          duration: 0.3,
          ease: "none",
          color: "black",
          "--stop1": "100%",
          "--stop2": "100%",
        });
        gsap.to(tiRef.current.children[id].children[1], {
          duration: 0.3,
          text: {
            value: "查看",
          },
        });
      };
      const leaveAct = (id) => {
        gsap.to(tiRef.current.children[id], {
          duration: 0.3,
          ease: "none",
          color: "white",
          "--stop1": "0%",
          "--stop2": "0%",
        });
        gsap.to(tiRef.current.children[id].children[1], {
          duration: 0.3,
          text: {
            value: actData[id].time,
          },
        });
      };
      actData.forEach((item, index) => {
        const child = tiRef.current.children[index];
        child.addEventListener("mousemove", () => {
          moveAct(index);
        });
        child.addEventListener("mouseleave", () => {
          leaveAct(index);
        });
        cleanAct.push({ child, moveAct, leaveAct });
      });
    }, tiRef);

    timelineStore.secondline.secondActTitleDo(tiRef);

    return () => {
      ctx.revert();
      cleanAct.forEach((item) => {
        item.child.removeEventListener("mousemove", item.moveAct);
        item.child.removeEventListener("mousemove", item.leaveAct);
      });
    };
  }, [actData]);

  if(isRenderStore.secondRender.secondUnloadStart){
    timelineStore.secondline.secondActTitleUnload(tiRef);
  }

  return (
    <Container ref={tiRef} onWheel={(e)=>{e.stopPropagation();}}>
      {actData.map((item, index) => {
        return (
          <Child key={item.id} onClick={()=>{
            nagative(`/detail/${item.id}`);
          }}>
            <p>{item.maintitle}</p>
            <p>{item.time}</p>
          </Child>
        );
      })}
    </Container>
  );
}
const SecondTitleOb = observer(SecondTitle);
export { SecondTitleOb };
