import { useEffect, useState } from "react";
import styled from "styled-components";

const Title = styled.div({});
const Container = styled.div`
  position: absolute;
  width: 51%;
  height: 34%;
  left: 0;
  background-color: #fff;
  overflow: hidden;
  border-radius: 1vh;
  padding: 0 2%;
  font-family: one;
  div {
    span {
      color: rgb(102, 127, 191);
      font-size: 0.8vw;
      font-weight: bold;
    }
    font-size: 0.8vw;
  }
  ${Title} {
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
    padding: 10px;
  }
`;

function extractOSInfo(userAgentString) {
  // 正则表达式匹配括号内的内容
  const regex = /\(([^)]+)\)/;
  const matches = userAgentString.match(regex);

  // 检查是否有匹配项，并返回结果
  if (matches && matches.length > 1) {
    return matches[1]; // 返回括号内的第一个匹配结果
  } else {
    return "No OS information found"; // 如果没有匹配项，返回默认消息
  }
}

function DeviceInfo() {
  const [batteryData, setBattery] = useState({});
    useEffect(()=>{
        navigator.getBattery().then((battery)=>{
            setBattery(battery);
        });
    });
  const canvas = document.createElement("canvas");
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
  const gpu = debugInfo
    ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
    : "Unavailable";
  return (
    <Container>
      <Title>当前设备信息</Title>
      <div>
        <span>最大性能处理核心 :</span> {navigator.hardwareConcurrency} 核
      </div>
      <div>
        <span>图形处理器 :</span> {gpu}
      </div>
      <div>
        <span>系统信息 :</span> {extractOSInfo(navigator.userAgent)}
      </div>
      <div>
        <span>当前浏览器语言 :</span> {navigator.language}
      </div>
        <div>
            <span>电池充电状态 :</span> {batteryData.charging ? "充电中" : "未充电"}
        </div>
        <div>
            <span>电池电量 :</span> {batteryData.level * 100}%
        </div>
    </Container>
  );
}

export { DeviceInfo };
