import styled from "styled-components";

const Avater =styled.img({});
const InfoOne=styled.div({});
const InfoTwo=styled.div({});
const Container = styled.div`
    width: 35%;
    height: 18%;
    position: absolute;
    left: 2.5%;
    top: 4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${Avater}{
        width: 6vw;
        height: 6vw;
        border-radius: 1vh;
        border: 4px solid white;
        flex-shrink: 0;
    }
    section{
        width: 78%;
        height: 6vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        ${InfoOne}{
            width: 100%;
            height: 1.5vw;
            margin-top: 0.5vw;
            color: rgb(106, 106, 106);
            display: flex;
            flex-direction: row;
            span{
                font-family: jhun;
                font-size: 1vw;
                display: block;
                background-color:rgb(208, 208, 208) ;
                border-radius: 0.4vw;
                padding: 0.2vw 0.5vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right:0.5vw;
            }
            .forward{
                background-color: rgb(81, 101, 150);
                color: white;
            }
        }
        ${InfoTwo}{
            width: 100%;
            height: 3.5vw;
            line-height: 3.5vw;
            span{
                font-size: 1.8vw;
                font-family: one;
                font-weight: 600;
            }
            .nick{
                color: rgb(48, 47, 47);
                font-size: 1.5vw;
            }
        }
    }
`;

function SpaceAvater({avater,name,nickname,role,forwards}) {
    return (
        <Container>
            <Avater src={avater}></Avater>
            <section>
                <InfoOne>
                    <span>{role}</span>
                    <span className="forward">{forwards}</span>
                </InfoOne>
                <InfoTwo>
                    <span>{name}&nbsp;</span>
                    <span className="nick">{nickname}</span>
                </InfoTwo>
            </section>
        </Container>
    );
}

export { SpaceAvater };