import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  width: 16%;
  left: 42%;
  bottom: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  span {
    font-size: 0.7vw;
    font-family: jhun;
    color: black;
  }
  button {
    border: none;
    background-color: transparent;
    font-size: 0.7vw;
    font-family: jhun;
    color: rgb(39, 114, 240);
    cursor: pointer;
  }
`;

function SignCheck({ isIn, setIsIn,nagative }) {
  return (
    <Container>
      <span>{isIn?'还没有一个账户？':'已经拥有账户？'}</span>
      <button
        onClick={() => {
          setIsIn(!isIn);
        }}
      >
        {isIn ? "注册" : "登录"}
      </button>
      <span>&nbsp;Or&nbsp;返回</span>
      <button
        onClick={()=>{
          nagative('/',{replace:true});
        }}
      >
        首页
      </button>
    </Container>
  );
}

export { SignCheck };
