import { makeAutoObservable } from 'mobx';
import {httpPort} from '../../utils/env';
import axios from 'axios';

class ForoleService {
    instance = axios.create({
        baseURL: `${httpPort}/foroles`,
        timeout: 5000,
        headers: { 'X-Custom-Header': 'foobar' }
    });
    constructor() {
        makeAutoObservable(this);
    }

    mannerGetALLRolesMethod = async () => {
        return await this.instance({
            method: 'get',
            url: '/mannergetroles',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        }).catch((error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }
    mannerGetALLForwardsMethod = async () => {
        return await this.instance({
            method: 'get',
            url: '/mannergetforwards',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        }).catch((error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }
    mannerUpdateRoleMethod = async (data) => {
        return await this.instance({
            method: 'post',
            url: '/mannerupdateroles',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data:data,
        }).then((response) => {
            if (response.data.issuccess) {
                alert(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            alert('更改分组失败');
            window.location.reload();
        });
    }

    mannerAddForwardMethod = async (data) => {
        return await this.instance({
            method: 'post',
            url: '/manneraddforwards',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data:data,
        }).then((response) => {
            if (response.data.issuccess) {
                alert(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            alert('添加角色失败');
            window.location.reload();
        });
    }

    mannerUpdateForwardMethod = async (data) => {
        return await this.instance({
            method: 'post',
            url: '/mannerupdateforwards',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data:data,
        }).then((response) => {
            if (response.data.issuccess) {
                alert(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            alert(error.response.data.message);
            window.location.reload();
        });
    }
}

const foroleService = new ForoleService();
export { foroleService };