import styled from "styled-components";
import qq from "../../assets/png/qq.png";
import wechat from "../../assets/png/wechat.png";


const Button = styled.div({});
const Container = styled.div`
  width: 80%;
  height: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  ${Button} {
    width: 100%;
    height: 4.2vh;
    border-radius: 1vh;
    background-color: rgb(39, 114, 240);
    color: white;
    text-align: center;
    line-height: 4.2vh;
    font-size: 0.8vw;
    font-family: jhun;
    letter-spacing: 0.1vw;
    cursor: pointer;
  }
  section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3vh;
  }
  span {
    font-size: 0.7vw;
    font-family: jhun;
    color: rgb(157, 164, 177);
  }
`;
const LogButton = styled.div`
  cursor: pointer;
  width: 45%;
  height: 3.8vh;
  background-color: rgb(254, 247, 236);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 1vh;
  img {
    width: 2.8vh;
    height: 2.8vh;
  }
`;
function redirectToOAuthProvider() {
    // window.location.href = 'https://oauths.code-xyyproject.cn/redirect';
    // localStorage.setItem('xzz','zyx');
}
function zyOauth() {
    //window.location.href = 'https://api.gabrlie.top/oauth/authorize?client_id=9cc6c0af-5f13-4a5c-88f9-95053bc9d3c8&redirect_uri=http://localhost:3000&response_type=code&scope=&state=123456&prompt=consent';
    // 'client_id' => '9cb30643-fccd-4928-977d-a0051b76fb97',
    //     'redirect_uri' => 'http://localhost:3000',
    //     'response_type' => 'code',
    //     'scope' => '',
    //     'state' => $state,
    //      'prompt' => 'consent', // "none", "consent", or "login"
    //localStorage.setItem('xzz','zy');
}

function SignButton({ text, event }) {
  return (
    <Container>
      <Button onClick={event}>{text}</Button>
      <span>其他方式{text}:</span>
      <section>
        <LogButton
          onClick={() => {
            redirectToOAuthProvider();
          }}
        >
          <img src={qq} alt="" />
          <span>QQ</span>
        </LogButton>
        <LogButton
          onClick={()=>{
            zyOauth();
          }}
        >
          <img src={wechat} alt="" />
          <span>WeChat</span>
        </LogButton>
      </section>
    </Container>
  );
}

export { SignButton };
