import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { isRenderStore } from "../../mobx/render/isRender";

const Activity = styled.div`
  width: 98%;
  height: 96%;
  background-color: black;
  background-image: url(${(props) => props.$img});
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: art;
  font-size: 5.5vw;
  letter-spacing: 0.2vw;
  color: white;
`;
const Container = styled.div`
  opacity: 0;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  background-color: white;
  position: absolute;
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  display: flex;
  align-items: center;
  justify-content: center;
`;

function SecondAct({ $width, $height, $left, $top, isDetail, isunload, $img }) {
  const actRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (!isDetail) {
        timelineStore.secondline.secondActTitleDo(actRef);
      } else {
        timelineStore.secondline.detailActDo(actRef);
      }
    });
    return () => ctx.revert();
  }, [isDetail]);

  if (isRenderStore.secondRender.secondUnloadStart && isunload) {
    timelineStore.secondline.secondActTitleUnload(actRef);
  }

  return (
    <Container
      ref={actRef}
      $width={$width}
      $height={$height}
      $left={$left}
      $top={$top}
    >
      <Activity $img={$img}>Activity</Activity>
    </Container>
  );
}

const SecondActOb = observer(SecondAct);
export { SecondActOb };
