import styled from "styled-components";
import { ActEdit } from "../components/manner/ActEdit";
import { MannerTitle } from "../components/manner/MannerTitle";
import { MannerList } from "../components/manner/MannerList";
import { useEffect, useState } from "react";
import { AboutEdit } from "../components/manner/AboutEdit";
import { PeopleEdit } from "../components/manner/PeopleEdit";
import { AddAct, AddPeople } from "../components/manner/AddRule";
import { service } from "../mobx/service/service";
// import { SpaceLoad } from "../components/space/SpaceLoad";
// import { Square } from "../components/loading/Square";

const Container = styled.div`
  position: absolute;
  left: 20%;
  top: 25%;
  width: 75%;
  height: 72%;
  //background-color: aliceblue;
`;

function MannerPage({nagative}) {
  const [active, setActive] = useState(0);
  const [addAct, setAddAct] = useState(false);
  const [addPeo, setAddPeo] = useState(false);
  const [actData, setActData] = useState(null);
  const [peoData, setPeoData] = useState(null);
  useEffect(()=>{
    service.editpage
      .getAllActivityMethod()
      .then((res) => {
        setActData(res.data.data);
      })
      .catch((error) => {
        alert("获取活动失败");
      });
    service.editpage
      .getAllPersonMethod()
      .then((res) => {
        res.data.data.forEach(item=>{
          delete item["img"];
        })
        setPeoData(
          res.data.data
        );
      })
      .catch((error) => {
        alert("获取人员失败");
      });
  },[]);
  return (
    <>
      <Container>
        {active === 0 && actData ?(
          <ActEdit
            ContextOne={
              <MannerTitle
                font="页面编辑/活动管理"
                Context={
                  <>
                    <button
                      onClick={() => {
                        setAddAct(true);
                      }}
                    >
                      添加
                    </button>
                    <button
                      onClick={() => {
                        setActive(1);
                      }}
                    >
                      下一个
                    </button>
                  </>
                }
              ></MannerTitle>
            }
            ContextTwo={
              <MannerList
                isView={true}
                widthData={["4%","16%", "16%", "16%", "18%"]}
                titleData={["活动ID","活动名称", "活动概要", "活动时间", "操作"]}
                itemData={actData}
                nagative={nagative}
                deleteMe={service.editpage.deleteActivityMethod}
              ></MannerList>
            }
          ></ActEdit>
        ):null}
        {active === 1 && (
          <AboutEdit
            updateMe={service.editpage.updateAboutMethod}
            Context={
              <MannerTitle
                font="页面编辑/关于管理"
                Context={
                  <>
                    <button
                      onClick={() => {
                        setActive(2);
                      }}
                    >
                      下一个
                    </button>
                  </>
                }
              ></MannerTitle>
            }
          ></AboutEdit>
        )}
        {active === 2 && peoData &&(
          <PeopleEdit
            ContextOne={
              <MannerTitle
                font="页面编辑/人员管理"
                Context={
                  <>
                    <button onClick={()=>{setAddPeo(true);}}>添加</button>
                    <button
                      onClick={() => {
                        setActive(0);
                      }}
                    >
                      回首页
                    </button>
                  </>
                }
              ></MannerTitle>
            }
            ContextTwo={
              <MannerList
                widthData={['6%',"16%", "26%", "18%"]}
                titleData={['人物ID',"人物名", "人物介绍", "操作"]}
                itemData={peoData}
                deleteMe={service.editpage.deletePersonMethod}
              ></MannerList>
            }
          ></PeopleEdit>
        )}
      </Container>
      {
        addAct&& 
        <AddAct
            close={() => {
                setAddAct(false);
            }}
            add={service.editpage.addActivityMethod}
        ></AddAct>
      }
      {
        addPeo&& 
        <AddPeople
            close={() => {
                setAddPeo(false);
            }}
            add={service.editpage.addPersonMethod}
        ></AddPeople>
      }
    </>
  );
}

export { MannerPage };
