import { useEffect, useRef } from "react";
import styled from "styled-components";
import { timelineStore } from "../../mobx/timeline/timeLine";
//import { gsap } from "gsap";

const Choose= styled.div`
    top:${(props) =>props.$top};
    background-color:${(props) =>props.$back};
`;
const Middle = styled.div({});
const Container = styled.div`
    width: 8%;
    height: 17vh;
    position: fixed;
    left: 2%;
    top: 25%;
    ${Middle} {
        position: absolute;
        right: 20%;
        width: 0.3vh;
        height: 100%;
        background-color:rgb(137, 137, 137);
    }
    ${Choose} {
        position: absolute;
        right: 20%;
        width: 3.5vw;
        height: 1.4vw;
        border-radius: 0.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.85vw;
        font-weight: 600;
        font-family: one;
        cursor: pointer;
    }
`;

function ProjectsCheck({setTeam}) {

    //const conRef=useRef(null);
    const gameRef=useRef(null);
    const artRef=useRef(null);
    useEffect(() => {
        timelineStore.projectline.projectChoose(gameRef,true);
      }, []);
    return (
        <Container>
            <Middle></Middle>
            <Choose
                ref={gameRef}
                $top={'20%'}
                onClick={()=>{
                    timelineStore.projectline.projectChoose(gameRef,true);
                    timelineStore.projectline.projectNoChoose(artRef,false);
                    setTeam('game');
                }}
            >
                游戏
            </Choose>
            <Choose 
                ref={artRef}
                $top={'50%'}
                onClick={()=>{
                    timelineStore.projectline.projectChoose(artRef,false);
                    timelineStore.projectline.projectNoChoose(gameRef,true);
                    setTeam('art');
                }}
            >
                美术
            </Choose>
        </Container>
    );
}

export { ProjectsCheck};