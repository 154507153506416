import styled from "styled-components";

import { timelineStore } from "../mobx/timeline/timeLine";
import { isRenderStore } from "../mobx/render/isRender";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { NormalFontWOb } from "../components/norml/NormalFontW";
import { NormalFontBOb } from "../components/norml/NormalFontB";
import { NormalActOb } from "../components/norml/NormalAct";
import { NormalContextOb } from "../components/norml/NormalContext";
import { useEffect, useState } from "react";
import { service } from "../mobx/service/service";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;

export function ThirdPage() {
  const [aboutData, setAboutData] = useState([]);
  useEffect(() => {
    service.editpage
      .getAboutMethod()
      .then((res) => {
        setAboutData(res.data.data);
      })
      .catch((error) => {
        alert("获取关于我们失败");
      });
  }, []);
  return (
    <Container
      onWheel={(e) => {
        if (e.deltaY > 0) {
          isRenderStore.thirdRender.setUpOrDown(1);
        } else {
          isRenderStore.thirdRender.setUpOrDown(0);
        }
        isRenderStore.thirdRender.wheelDown();
      }}
    >
      {aboutData.length >0 && (
        <>
          <NormalBlockOb
            $width={"100%"}
            $height={"25%"}
            isunload={true}
            number={8}
            islay={false}
            animation={timelineStore.thirdline.thirdBlockDo}
            colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
          ></NormalBlockOb>
          <NormalFontWOb
            value={"ABOUT  US"}
            $left={"-44%"}
            $top={"0%"}
            $width={"50%"}
            $height={"25%"}
            $fontsize={"6vw"}
            animation={timelineStore.thirdline.thirdFontAboDo}
          ></NormalFontWOb>
          <NormalFontBOb
            isFront={false}
            $width={"50%"}
            $height={"25%"}
            $top={0}
            $right={"10%"}
            $justify={"flex-start"}
            $align={"flex-end"}
            $fontsize={"6vw"}
            animation={timelineStore.thirdline.thirdFontDo}
          ></NormalFontBOb>
          <NormalActOb
            $width={"32%"}
            $height={"26%"}
            $right={"30%"}
            $top={"32%"}
            $img={aboutData ? aboutData[0].img : null}
            animation={timelineStore.thirdline.thirdActToRight}
          ></NormalActOb>
          <NormalActOb
            $width={"32%"}
            $height={"26%"}
            $left={"30%"}
            $bottom={"12%"}
            $img={aboutData ? aboutData[1].img : null}
            iscomplete={true}
            animation={timelineStore.thirdline.thirdActToLeft}
          ></NormalActOb>
          <NormalContextOb
            $width={"32%"}
            $height={"25%"}
            $top={"32%"}
            $left={"30%"}
            $color={"white"}
            $fontSize={"1.25vw"}
            animation={timelineStore.thirdline.thirdToLeft}
            value={aboutData ? aboutData[0].context : ""}
          ></NormalContextOb>
          <NormalContextOb
            $width={"32%"}
            $height={"25%"}
            $bottom={"12%"}
            $right={"30%"}
            $color={"white"}
            $fontSize={"1.25vw"}
            animation={timelineStore.thirdline.thirdToRight}
            value={aboutData ? aboutData[1].context : ""}
          ></NormalContextOb>
        </>
      )}
    </Container>
  );
}
