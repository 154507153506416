import styled from "styled-components";

const BottomItem = styled.div({});
const BottomTitle = styled.div({});
const Container = styled.div`
  width: 100%;
  height: 93%;
  //background-color: aliceblue;
  overflow: auto;
  scrollbar-width: none;
  padding: 2% 0 0 0;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${BottomTitle},${BottomItem} {
    border-bottom: 2px solid rgb(175, 188, 202);
    width: 100%;
    height: 4%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    section {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: one;
      font-size: 0.65vw;
      font-weight: 600;
      letter-spacing: 0.1vw;
      white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      //background-color: aqua;
    }
  }
  ${BottomItem} {
      height: 6%;
      .control {
        width: 18%;
        p {
          margin: 0 1vh;
          color: rgb(102, 127, 191);
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
`;

function MannerList({ titleData, itemData,widthData,nagative,deleteMe,isView=false }) {
  return (
    <Container>
      <BottomTitle>
        {titleData.map((title, index) => (
          <section key={index} style={{width:widthData[index]}}>{title}</section>
        ))}
      </BottomTitle>
      {itemData.map((item, index) => (
        <BottomItem key={index}>
            {Object.values(item).map((value, index) => (
                <section key={index} style={{width:widthData[index]}}>{value}</section>
            ))}
            <section className="control" style={{width:widthData[widthData.length-1]}}>
              {isView && <p onClick={()=>{nagative(`/detail/${item.id}`);}}>查看</p>}
              <p onClick={()=>{
                deleteMe({
                  id:item.id,
                  title:item.maintitle?item.maintitle:item.name
                });}}
              >删除</p>
            </section>
        </BottomItem>
      ))}
    </Container>
  );
}

export { MannerList };
