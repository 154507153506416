import gsap from "gsap";
import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { timelineStore } from "../../mobx/timeline/timeLine";

const InputBlock = styled.div({});
const GenderBlock = styled.div({});
const Container = styled.div`
  position: absolute;
  opacity: 0;
  width: 27%;
  height: 65%;
  left: 37.5%;
  top: 20%;
  background-color: white;
  border-radius: 1vw;
  box-shadow: 10px 10px 11px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  ${InputBlock} {
    width: 80%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    section {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
  }
  ${GenderBlock} {
    width: 100%;
    height: 4.2vh;
    border-radius: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #aeb4bf;
    span {
      display: block;
      width: 47%;
      font-size: 0.7vw;
      font-family: one;
      font-weight: 600;
      color: rgb(157, 164, 177);
      text-align: center;
      height: 4vh;
      line-height: 4vh;
      border-radius: 1vh;
      cursor: pointer;
      transition: all 0.3s;
    }
    .choose {
      background-color: rgb(39, 114, 240);
      color: white;
    }
    .choosen{
        background-color: rgb(249, 204, 226);
        color: white;
    }
  }
`;

function SignOut({ Title, Context, Context2,Context3, Button,isman, setIsman}) {
  const ref = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      timelineStore.signline.signInit(ref, true);
    }, ref);
    return () => {
      timelineStore.signline.signDelet(ref, true);
      ctx.revert();
    };
  }, []);

  return (
    <Container ref={ref}>
      {Title}
      <InputBlock>
        <section>{Context}</section>
        <section>{Context2}</section>
        <section>{Context3}</section>
        <GenderBlock>
          <span
            className={isman ? "choose" : null}
            onClick={() => {
              setIsman(true);
            }}
          >
           ♂ 男
          </span>
          <span
            className={isman ? null : "choosen"}
            onClick={() => {
              setIsman(false);
            }}
          >
            ♀ 女
          </span>
        </GenderBlock>
      </InputBlock>
      {Button}
    </Container>
  );
}

export { SignOut };
