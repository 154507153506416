import { makeAutoObservable, reaction } from 'mobx';
import { timelineStore } from '../timeline/timeLine';
import { pageIndex } from './pageIndex';

class IsFifthRenderStore {

    isFifthRender=false;
    fifthLoadDone=false;
    fifthUnloadStart=false;
    upOrdown=null;
    constructor() {
        makeAutoObservable(this);
    }
    wheelDown=()=>{
        if(this.fifthLoadDone){
            this.setFifthUnloadStart(true);
        }
    }
    setIsRender=(value)=>{
        this.isFifthRender=value;
    }
    setFifthUnloadStart=(value)=>{
        this.fifthUnloadStart=value;
    }
    setFifthLoadDone=(value)=>{
        this.fifthLoadDone=value;
    }
    init=()=>{
        this.setFifthUnloadStart(false);
        this.setFifthLoadDone(false);
        timelineStore.fifthLine.init();
    }
    setNextPage=()=>{
        if(this.upOrdown){
            
        }else{
            pageIndex.setPageIndex(pageIndex.index-1);
        }
    }
    setUpOrDown=(value)=>{
        this.upOrdown=value;
    }
}
const isFifth=new IsFifthRenderStore();
reaction(()=>timelineStore.fifthLine.isLoadDone,(value,oldvalue)=>{
    if(value){
        isFifth.setFifthLoadDone(true);
    }
    pageIndex.setIsSet(value);
});
reaction(()=>timelineStore.fifthLine.isTimeLineDone,(value,oldvalue)=>{
    if(value){
        isFifth.setIsRender(false);
        isFifth.setNextPage();
        isFifth.init();
    }
    pageIndex.setIsSet(value);
});
export { isFifth };