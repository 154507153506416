import styled from "styled-components";
import { isRenderStore } from "../mobx/render/isRender";
import {service} from '../mobx/service/service';

import { FirstFontOneOb } from "../components/first/FirstFontOne";
import { FirstFontTwoOb } from "../components/first/FirstFontTwo";
import { FirstImp } from "../components/first/FirstImp";
import { NormalBlockOb } from "../components/norml/NormalBlock";
import { timelineStore } from "../mobx/timeline/timeLine";
import { useEffect, useState } from "react";
import axios from "axios";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;

export function FirstHome() {
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, 200);
    // 获取URL中的授权码和状态码
    // const urlParams = new URLSearchParams(window.location.search);
    // const code = urlParams.get("code");
    // const state = urlParams.get("state");

    // 检查是否存在授权码和状态码
    // if (code && state) {
    //   console.log("Authorization Code:", code);
    //   console.log("State:", state);

    //   const baseUrl=localStorage.getItem('xzz')==='zyx'?'https://oauths.code-xyyproject.cn':'https://api.gabrlie.top';
    //   const baseId=localStorage.getItem('xzz')==='zyx'?'9cb30643-fccd-4928-977d-a0051b76fb97':'9cc6c0af-5f13-4a5c-88f9-95053bc9d3c8';
    //   const baseSecret=localStorage.getItem('xzz')==='zyx'?'dTF2aufcF7rwlHRilQEK7eg2vVgJHFTxftpALg8A':'KqqG9vu92gPNRUibOyvCptQyDNSZZge7ZeNwfg5a';
    //   const options = {
    //     method: 'POST',
    //     url: `${baseUrl}/oauth/token`,
    //     headers: {'content-type': 'application/x-www-form-urlencoded'},
    //     data: {
    //       grant_type: 'authorization_code',
    //       client_id: baseId,
    //       client_secret: baseSecret,
    //       redirect_uri: 'http://localhost:3000',
    //       code: code,
    //     }
    //   };
      
    //   axios.request(options).then(function (response) {
    //     console.log(response.data);
    //     localStorage.setItem('access_token', response.data.access_token);
    //     localStorage.setItem('refresh_token', response.data.refresh_token);
    //     const options2 = {
    //       method: 'GET',
    //       url: `${baseUrl}/api/user`,
    //       headers: {
    //         'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    //       }
    //     };
    //     console.log(options2);
    //     axios.request(options2).then(function (response) {
    //       console.log(response.data);
    //       service.sign.oauthTestMethod({
    //         username: response.data.schoolnumber?response.data.schoolnumber:'202208201999',
    //         name: response.data.name+'inzy',
    //         gender: response.data.sex?response.data.sex:'男',
    //         password: '123456',
    //         nickname: response.data.name+'inzy',
    //       });
    //     }).catch(function (error) {
    //       console.error(error);
    //     });
    //   }).catch(function (error) {
    //     console.error(error);
    //   });
    // } else {
    //  // console.error("Authorization code or state not found");
    // }

    return () => clearTimeout(timer);
  }, []);
  if (!isShown) {
    return null;
  }
  return (
    <Container
      onWheel={(e) => {
        if (e.deltaY > 0) {
          isRenderStore.firstRender.setUpOrDown(1);
          isRenderStore.firstRender.wheelDown();
        } else {
          isRenderStore.firstRender.setUpOrDown(0);
        }
      }}
    >
      <NormalBlockOb
        $width={"84%"}
        $height={"75%"}
        $position={"absolute"}
        $bottom={0}
        $right={0}
        colordata={["black", "rgb(96, 119, 175)", "rgb(102, 127, 191)"]}
        number={6}
        animation={timelineStore.firstline.firstBlockOneDofirst}
        islay={true}
        isunload={false}
        $zIndex={50}
      ></NormalBlockOb>
      <FirstFontOneOb></FirstFontOneOb>
      <NormalBlockOb
        $width={"84%"}
        $height={"23%"}
        $position={"absolute"}
        $top={"25%"}
        $left={0}
        $opacity={0}
        colordata={["black", "rgb(96, 119, 175)", "rgb(102, 127, 191)"]}
        number={6}
        animation={timelineStore.firstline.firstBlockTwoDo}
        islay={true}
        isunload={true}
      ></NormalBlockOb>
      <FirstFontTwoOb></FirstFontTwoOb>
      <FirstImp></FirstImp>
    </Container>
  );
}
