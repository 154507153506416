import styled from "styled-components";
import commit from "../../assets/png/commit.png";
import { useEffect, useState } from "react";

const Title = styled.div({});
const Tips = styled.div({});
const List = styled.div({});
const ListChild = styled.div({});
const Container = styled.div`
  width: 20%;
  height: 70%;
  position: absolute;
  right: 7%;
  top: 25%;
  font-family: one;
  ${Title} {
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.78vw;
      font-weight: 600;
      img {
        width: 1vw;
        height: 1vw;
        margin-right: 0.2vw;
        margin-bottom: 0.4vh;
      }
    }
  }
  ${Tips} {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid rgb(184, 184, 184);
    padding-bottom: 2%;
    margin-bottom: 2%;
    textarea {
      resize: none;
      outline: none;
      border-radius: 0.5vh;
      width: 64%;
      height: 82%;
      padding: 2% 3%;
      border: 1px solid rgb(184, 184, 184);
      font-family: one;
      font-size: 0.65vw;
    }
    button {
      width: 20%;
      height: 30%;
      border-radius: 0.5vh;
      border: 1px solid rgb(184, 184, 184);
      cursor: pointer;
      font-family: one;
      font-size: 0.65vw;
      font-weight: 600;
      background-color: white;
    }
  }
  ${List} {
    width: 100%;
    height: 74%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    ${ListChild} {
      margin-bottom: 2vh;
    }
    .front {
      width: 100%;
      height: 2.5vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1vh;
      img {
        width: 2vw;
        height: 2vw;
        border-radius: 0.5vh;
        border: 1px solid white;
        margin-right: 1.3vw;
        cursor: pointer;
      }
      .frontinfo {
        height: 2.5vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: one;
        font-size: 0.7vw;
        span {
          font-weight: 600;
        }
        .name {
          color: black;
          font-size: 0.8vw;
        }
        .date {
          color: rgb(98, 98, 99);
          font-size: 0.6vw;
        }
      }
    }
    .next {
      width: 80%;
      padding: 0 10%;
      border-left: 1px solid rgb(125, 125, 128);
      white-space: pre-wrap;
      word-break: break-all;
      font-size: 0.7vw;
    }
  }
`;
const Blur = styled.div`
  width: 100%;
  height: 20%;
  background-color: #fff;
  border-radius: 0.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  font-weight: 600;
  font-family: jhun;
  box-shadow: 5px 5px 5px 0 rgba(51, 50, 50, 0.5);
`;

function ProjectCommit({ id, insert, getData, getRole,nagative,userid }) {
  const [text, setText] = useState("");
  const [commitData, setCommitData] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [isHot, setIsHot] = useState(false);
  useEffect(() => {
    getData({ id: id })
      .then((response) => {
        //console.log(response);
        setCommitData(response.data);
        getRole().then((res) => {
          setRoleData(res.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getData, id, getRole, isHot]);
  return (
    <Container>
      {commitData ? (
        <>
          {roleData && roleData.rolename !== "normal" ? (
            <>
              <Title>
                <span>
                  <img src={commit} alt="" />
                  评论 &nbsp; {commitData.length}条
                </span>
              </Title>
              <Tips>
                <textarea
                  name="commit"
                  maxLength={255}
                  placeholder="最多输入255个字符."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
                <button
                  onClick={() => {
                    insert({
                      project_id: id,
                      content: text.toString(),
                      date: new Date().toLocaleDateString(),
                    });
                    setText("");
                    setIsHot(!isHot);
                  }}
                >
                  发表
                </button>
              </Tips>
            </>
          ) : (
            <Blur>仅团队用户评论作品</Blur>
          )}

          <List>
            {commitData.map((item, index) => {
              return (
                <ListChild key={index}>
                  <section className="front">
                    <img src={item.profile_avater} alt="" onClick={()=>{nagative(`/spaceperson/${userid}`);}}/>
                    <div className="frontinfo">
                      <span className="name">{`${item.profile_name}(${item.profile_nickname})`}</span>
                      <span className="date">{item.comment_date}</span>
                    </div>
                  </section>
                  <section className="next">{item.comment_content}</section>
                </ListChild>
              );
            })}
          </List>
        </>
      ) : (
        <Blur>评论区仅对登录用户开放</Blur>
      )}
    </Container>
  );
}

export { ProjectCommit };
