import styled from "styled-components";
import upload from "../../assets/png/upload.png";
import { useRef, useState } from "react";

const InputContainer = styled.div({});
const PartContainer = styled.div({});
const Container = styled.div`
  width: 100%;
  height: 100%;
  ${PartContainer} {
    width: 100%;
    height: 5%;
  }
  ${InputContainer} {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
    width: 40%;
    margin-bottom: 2.5vh;
    span {
      display: block;
      font-family: one;
      font-size: 0.85vw;
      font-weight: 600;
      margin-right: 1vw;
      width: 4.5vw;
    }
    section {
      position: relative;
      width: 16vw;
      height: 18vh;
      border-radius: 0.5vh;
      border: 1px solid rgb(177, 175, 175);
      overflow: hidden;
      input {
        position: absolute;
        visibility: hidden;
      }
      .reimg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .load {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(208, 208, 208);
        cursor: pointer;
        img {
          width: 5vh;
          height: 5vh;
        }
      }
    }
    textarea,input {
      border: none;
      outline: none;
      width: 80%;
      height: 5.5vh;
      font-family: one;
      font-size: 0.65vw;
      font-weight: 600;
      border: 1px solid rgb(177, 175, 175);
      padding-left: 0.5vh;
      border-radius: 0.5vh;
      resize: none;
    }
    button {
      width: 5vw;
      height: 3vh;
      background-color: rgb(39, 114, 240);
      color: white;
      font-size: 0.85vw;
      font-family: one;
      border: none;
      border-radius: 0.5vh;
      cursor: pointer;
    }
  }
`;

function ImgFilter({title,setData}) {
  const inputRef = useRef(null);
  const imgRef = useRef(null);
  return (
    <InputContainer>
      <span>{title}</span>
      <section>
        <input
          type="file"
          accept=".jpg, .jpeg, .png"
          ref={inputRef}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = function (e) {
                const arrayBuffer = e.target.result;
                const uint8Array = new Uint8Array(arrayBuffer);
                const blob = new Blob([uint8Array], { type: file.type });
                const url = URL.createObjectURL(blob);
                imgRef.current.src = url;
                imgRef.current.style.zIndex=4;
                setData({
                  name: file.name,
                  file: blob,
                });
              };
              reader.readAsArrayBuffer(file);
            }
          }}
        />
        <img src="" alt="" className="reimg" ref={imgRef} />
        <div
          className="load"
          onClick={() => {
            inputRef.current.click();
          }}
        >
          <img src={upload} alt="" />
          <p>上传图片</p>
        </div>
      </section>
    </InputContainer>
  );
}

function AboutEdit({ Context,updateMe }) {
  const [textOne, setTextOne] = useState('');
  const [textTwo, setTextTwo] = useState('');
  const [imgOne, setImgOne] = useState(null);
  const [imgTwo, setImgTwo] = useState(null);
  return (
    <Container>
      {Context}
      <PartContainer></PartContainer>
      <InputContainer>
        <span>关于描述一</span>
        <textarea value={textOne} onChange={(e)=>{setTextOne(e.target.value);}}></textarea>
      </InputContainer>
      <ImgFilter
        title={"描述图片一"}
        setData={setImgOne}
      ></ImgFilter>
      <InputContainer>
        <span>关于描述二</span>
        <textarea value={textTwo} onChange={(e)=>{setTextTwo(e.target.value);}}></textarea>
      </InputContainer>
      <ImgFilter
        setData={setImgTwo}
        title={"描述图片二"}
      ></ImgFilter>
      <InputContainer>
        <span></span>
        <button 
          onClick={()=>{
            if(textOne&&textTwo&&imgOne&&imgTwo){
              const formData = new FormData();
              formData.append("context", textOne);
              formData.append("context", textTwo);
              formData.append("imgname", imgOne.name);
              formData.append("imgname", imgTwo.name);
              formData.append("files", imgOne.file);
              formData.append("files", imgTwo.file);
              updateMe(formData);
            }else{
              alert("请填写完整信息");
              return;
            }
          }}
        >提交</button>
      </InputContainer>
    </Container>
  );
}

export { AboutEdit};
