import { makeAutoObservable } from 'mobx';
import { gsap } from 'gsap';

class SecondTimelineStore {
    secondTimeLine = gsap.timeline();
    unLoadTimeLine = gsap.timeline();
    isTimeLineDone = false; //卸载动画完成
    isLoadDone = false;//加载动画完成

    detailTimeLine = gsap.timeline();

    constructor() {
        makeAutoObservable(this);
    }
    setSecondTimeLineDone = (value) => {
        this.isTimeLineDone = value;
    }
    setSecondLoadDone = (value) => {
        this.isLoadDone = value;
    }

    init = () => {
        this.setSecondTimeLineDone(false);
        this.setSecondLoadDone(false);
        this.secondTimeLine.kill();
        this.unLoadTimeLine.kill();
        this.secondTimeLine = gsap.timeline();
        this.unLoadTimeLine = gsap.timeline();
    }


    secondBlockDo = (blockRef, colordata) => {
        this.secondTimeLine.add('secondfontstart', 0.2);
        if (blockRef.current.children) {
            for (let i = 0; i < blockRef.current.children.length; i++) {
                this.secondTimeLine.to(blockRef.current.children[i], {
                    keyframes: {
                        backgroundColor: colordata,
                    },
                    duration: 0.3,
                }, i > 0 ? '<+=0.1' : '');
            }
        }


    }

    secondFontDo = (fontRef) => {
        this.secondTimeLine.to(fontRef.current, {
            left: '1%',
            duration: 2,
            ease: 'power3.out',
        }, 'secondfontstart');
        this.secondTimeLine.to(fontRef.current, {
            opacity: 1,
            duration: 2,
            ease: 'power2.out',
        }, 'secondfontstart+=0.3').add('acttitlestart', '-=1');
    }

    secondActTitleDo = (Ref) => {
        this.secondTimeLine.to(Ref.current, {
            y: -100,
            opacity: 1,
            duration: 1,
            ease: 'power2.out',
            onComplete: () => {
                this.setSecondLoadDone(true);
            },
        }, 'acttitlestart');
    }

    detailFontDo = (fontRef) => {
        this.detailTimeLine.addLabel('detailfontstart', 0.6);
        this.detailTimeLine.to(fontRef.current, {
            left: '1%',
            duration: 2,
            ease: 'power3.out',
        }, 'detailfontstart');
        this.detailTimeLine.to(fontRef.current, {
            opacity: 1,
            duration: 2,
            ease: 'power2.out',
        }, 'detailfontstart+=0.3').add('detailactstart', '-=1.5');
    }

    detailActDo = (actRef) => {
        this.detailTimeLine.to(actRef.current, {
            y: -100,
            opacity: 1,
            duration: 1,
            ease: 'power2.out',
        }, 'detailactstart').add('detailtitlestart', '-=1.3');
    }
    detailTitleDo = (titleRef) => {
        this.detailTimeLine.to(titleRef.current.children[0], {
            left: '15%',
            duration: 1,
            ease: 'power2.out',
        }, 'detailtitlestart');
        this.detailTimeLine.to(titleRef.current.children[0], {
            opacity: 1,
            duration: 1,
            ease: 'power2.out',
        }, 'detailtitlestart+=0.3').add('detailtitlekeep', '-=1');
        this.detailTimeLine.to(titleRef.current.children[1], {
            left: '15%',
            duration: 1,
            ease: 'power2.out',
        }, 'detailtitlekeep');
        this.detailTimeLine.to(titleRef.current.children[1], {
            opacity: 1,
            duration: 1,
            ease: 'power2.out',
        }, 'detailtitlekeep+=0.3');
    }

    detailContextDo = (contextRef) => {
        this.detailTimeLine.to(contextRef.current, {
            opacity: 1,
            duration: 1,
            ease: 'power2.out',
        }, 'detailtitlekeep+=0.6').add('bannerstart', '-=1');

    }
    detailBanDo = (banRef) => {
        this.detailTimeLine.to(banRef.current.children[1], {
            opacity: 1,
            duration: 0.7,
            ease: 'power2.out',
        }, 'bannerstart');
        this.detailTimeLine.to(banRef.current.children[1], {
            left: 0,
            duration: 0.7,
            ease: 'power2.out',
        }, 'bannerstart+=0.3');
        this.detailTimeLine.to(banRef.current.children[0], {
            left: 0,
            duration: 0.7,
            ease: 'power2.out',
        }, 'bannerstart+=0.3');
        this.detailTimeLine.to(banRef.current.children[0], {
            opacity: 1,
            duration: 0.7,
            ease: 'power2.out',
        }, 'bannerstart+=0.3');
    }

    secondBlockUnload = (blockRef) => {
        this.unLoadTimeLine.add('acttitleunloadstart', 0.3);
        for (let i = 0; i < blockRef.current.children.length; i++) {
            this.unLoadTimeLine.to(blockRef.current.children[i], {
                keyframes: {
                    backgroundColor: ['black', 'rgb(28, 35, 54)', 'rgb(208, 208, 208)'],
                },
                duration: 0.3,
            }, i > 0 ? '<+=0.1' : '');
        }
    }
    secondActTitleUnload = (actRef) => {
        this.unLoadTimeLine.to(actRef.current, {
            duration: 0.1,
            backgroundColor: 'transparent',
            ease: 'power2.out',
        }, 'acttitleunloadstart');
        this.unLoadTimeLine.to(actRef.current, {
            y: -200,
            opacity: 0,
            duration: 1,
            backgroundColor: 'transparent',
            ease: 'power2.out',
        }, 'acttitleunloadstart');
    }
    secondFontUnload = (fontRef) => {
        this.unLoadTimeLine.to(fontRef.current, {
            left: '10%',
            opacity: 0,
            duration: 1,
            ease: 'power3.out',
            onComplete: () => {
                this.setSecondTimeLineDone(true);
            }
        }, 'acttitleunloadstart+=0.1');
    }
}

const secondTimeLine = new SecondTimelineStore();
export { secondTimeLine };