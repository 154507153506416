import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DeviceNet } from '../components/device/DeviceNet';
import { DeviceInfo } from '../components/device/DeviceInfo';
import { DeviceMeomy } from '../components/device/DeviceMeomy';
//import { service } from '../mobx/service/service';

const Button =styled.div({});
const Container = styled.div`
  position: absolute;
  left: 20%;
  top: 25%;
  width: 75%;
  height: 72%;
  //background-color: aqua;
  ${Button}{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 14%;
    height: 14%;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button{
        width: 100%;
        height: 45%;
        border: none;
        background-color: #fff;
        border-radius: 1vh;
        border: 1px solid rgba(51, 50, 50, 0.1);
        font-size: 0.8vw;
        font-weight: bold;
        font-family: one;
        cursor: pointer;
    }
  }
`;


function DeviceShow() {

    const fullref = useRef();
    const [netData, setNetData] = useState([]);
    const [meData, setMeData] = useState([]);
    useEffect(()=>{
        // service.socket.socket.emit("events",{
        //     netData,
        //     meData
        // });
        // return ()=>{
        //     service.socket.socket.off("events");
        // };

    },[netData,meData]);
    return (
        <Container>
            <DeviceInfo></DeviceInfo>
            <DeviceNet ref={fullref} data={netData} setData={setNetData}></DeviceNet>
            <DeviceMeomy ref={fullref} data={meData} setData={setMeData}></DeviceMeomy>
            <Button>
                <button
                    onClick={()=>{
                        fullref.current.fullNetScreen();
                    }}
                >全屏查看网络状态</button>
                <button
                    onClick={()=>{
                        fullref.current.fullMeScreen();
                    }}
                >全屏查看占用状态</button>
            </Button>
        </Container>
    );
}

export { DeviceShow };