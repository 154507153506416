import frontImg from "../../assets/png/prFront.png";
import nextImg from "../../assets/png/prNext.png";
import styled from "styled-components";

const IndexContainer = styled.div({});
const Container = styled.div`
  width: 80%;
  height: 10vh;
  margin-left: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  ${IndexContainer} {
    width: 25%;
    height: 50%;
    //background-color: aliceblue;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 2.5vh;
      height: 2.5vh;
      border-radius: 0.3vh;
      cursor: pointer;
      //margin: 0 1.5%;
    }
    .active{
        background-color: rgb(102, 127, 191);
        color: white;
    }
    .middle{
        display: flex;
        flex-direction: row;
        align-items: center;
        //width: 15vh;
    }
  }
`;
const NumberCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5vh;
  height: 2.5vh;
  border-radius: 0.3vh;
  cursor: pointer;
  font-family: jhun;
  border: 0.1vh solid rgba(102, 127, 191, 0.5);
  margin: 0 0.5vh;
`;

function middleSolve(index,last){
    let data=[];
    if(last-(index+3)>=2){
        if(index-1>3){
            return [index,index+1,index+2,index+3];
        }else{
            return [2,3,4,5];
        }
    }else{
        if(last<9){
            for(let i=2;i<last;i++){
                data.push(i);
            }
            return data;
        }
        return [last-4,last-3,last-2,last-1];
    }
}

function ProjectsNext({ indexNum, setIndex,count }) {
    //console.log(indexNum,indexNum - 1 > 3);

  return (
    <Container>
      <IndexContainer>
        <img src={frontImg} alt="" onClick={()=>{
            if(indexNum-1>0){
                setIndex(indexNum-1);
            }
        }}/>
        <NumberCount className={indexNum===1?'active':null}>1</NumberCount>
        {indexNum - 1 > 3 ? <NumberCount className="lsl">...</NumberCount> : null}
        <div className="middle">
            {
                middleSolve(indexNum,Math.ceil(count / 9)).map((item,index)=>{
                    return (
                        <NumberCount key={index} className={indexNum===item?'active':null}>{item}</NumberCount>
                    );
                })
            }
        </div>
        {Math.ceil(count / 9) - (indexNum + 3) >= 2 ? (
          <NumberCount className="lsl zz">...</NumberCount>
        ) : null}
        {
            Math.ceil(count / 9)>1?<NumberCount className={indexNum===Math.ceil(count / 9)?'active':null}>{Math.ceil(count / 9)}</NumberCount>:null
        }
        <img src={nextImg} alt="" onClick={()=>{
            if(indexNum+1<=Math.ceil(count / 9)){
                setIndex(indexNum+1);
            }
        }}/>
      </IndexContainer>
    </Container>
  );
}

export { ProjectsNext };
