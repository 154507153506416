import styled from "styled-components";

const DeleteContainer=styled.div({});
const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 95;
    background-color: rgba(58, 56, 56, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    ${DeleteContainer} {
        width: 26%;
        height: 30%;
        background-color: #fff;
        border-radius: 1vh;
        box-shadow: 0 0 10px 0 #000;
        font-family: one;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        overflow: hidden;
        .top{
            width: 100%;
            height: 10%;
            border-radius: 1vh;
            display: flex;
            justify-content: flex-end;
            .goback{
                border: none;
                border-top-right-radius: 1vh;
                border-left: 1px solid rgba(58, 56, 56, 0.15);
                border-bottom:1px solid rgba(58, 56, 56, 0.1) ;
                height: 100%;
                width: 10%;
                background-color: #fff;
                font-family: one;
                letter-spacing: 0.1vw;
                cursor: pointer;
            }
        }
        .middle{
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2vw;
            letter-spacing: 0.1vw;
        }
        .bottom{
            width: 50%;
            height: 3.5vh;
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-bottom: 4.5vh;
            button{
                border: none;
                font-family: one;
                height: 100%;
                width: 30%;
                border-radius: 1vh;
                box-shadow: 2px 2px 10px 0 #8a8787;
                cursor: pointer;
            }
            .yes{
                &:hover{
                    background-color: rgb(237,2,30);
                }
            }
            .no{
                &:hover{
                    background-color: rgb(2,147,237);
                }
            }
        }
    }
`;

function PersonDelete({control,setControl,deleteUser}) {
  return (
    <Container>
      <DeleteContainer>
            <section className="top">
                {/* <button className="goback">返回</button> */}
            </section>
            <section className="middle">
                确定删除该账户吗？
            </section>
            <section className="bottom">
                <button 
                    className="yes"
                    onClick={()=>{
                        deleteUser({
                            userid:control.userid,
                            username:control.username,
                        });
                    }}
                >
                    确认
                </button>
                <button 
                    className="no"
                    onClick={()=>{
                        setControl({...control,isDelete:false})
                    }}
                >
                    取消
                </button>
            </section>
      </DeleteContainer>
    </Container>
  );
}

export { PersonDelete};