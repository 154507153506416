import styled from 'styled-components';
import { NormalBlockOb } from '../components/norml/NormalBlock';
import { NormalFontBOb } from '../components/norml/NormalFontB';
import { timelineStore } from '../mobx/timeline/timeLine';
import { SpaceAvater } from '../components/space/SpaceAvater';
import { SpaceLoad } from '../components/space/SpaceLoad';
import { Square } from '../components/loading/Square';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { service } from '../mobx/service/service';
import { SpaceShow } from '../components/space/SpaceShow';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgb(208, 208, 208);
  overflow: hidden;
`;

function SpacePerson() {
  const [isDone, setIsDone] = useState(false);
  const [projectData, setProject] = useState([]);
  const [roleData, setRole] = useState();
  const [profileData, setProfileData] = useState(null);
  const params = useParams();
  const nagative = useNavigate();
  useEffect(()=>{
    service.info.getInfoMethod({userid:params.id}).then((res)=>{
      setProfileData(res.data);
    }).catch((error)=>{
      console.log(error);
    });
    service.sign.getRoleMethod({userid:params.id}).then((res)=>{
      setRole(res.data);
    }).catch((error)=>{
      console.log(error);
    });
    service.project.getUserProjectMethod({userid:params.id}).then((res)=>{
      setProject(res.data);
      setIsDone(true);
    }).catch((error)=>{
      console.log(error);
    });
  },[params]);
  return (
    <Container>
      <Container>
      {isDone && profileData &&roleData ? (
        <>
          <NormalBlockOb
            $width={"100%"}
            $height={"22%"}
            $shadow={"0px 0px 10px 5px rgba(0, 0, 0, 0.5)"}
            number={8}
            islay={false}
            animation={timelineStore.signline.signBlockDo}
            colordata={["white", "rgb(178, 193, 226)", "rgb(102, 127, 191)"]}
          ></NormalBlockOb>
          <NormalFontBOb
            isFront={false}
            $width={"50%"}
            $height={"25%"}
            $top={"-2%"}
            $right={"10%"}
            $justify={"flex-start"}
            $align={"flex-end"}
            $fontsize={"6vw"}
            animation={timelineStore.signline.signBlackFontDo}
          ></NormalFontBOb>
          <SpaceAvater
            role={roleData.rolename}
            forwards={roleData.forwardsname}
            name={profileData.name}
            nickname={profileData.nickname}
            avater={profileData.avater}
          ></SpaceAvater>
          <SpaceShow
            name={profileData.name}
            nickname={profileData.nickname}
            title={profileData.title}
            date={profileData.time}
            projects={projectData}
            level={profileData.level}
            nagative={nagative}
            isGuide={true}
          ></SpaceShow>
        </>
      ) : (
        <SpaceLoad Context={<Square></Square>}></SpaceLoad>
      )}
    </Container>
    </Container>
  );
}

export { SpacePerson };