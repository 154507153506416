import { makeAutoObservable } from 'mobx';
import {httpPort} from '../../utils/env';
import axios from 'axios';

class CommitService {
    instance = axios.create({
        baseURL: `${httpPort}/commit`,
        timeout: 15000,
        headers: { 'X-Custom-Header': 'foobar' }
    });
    constructor() {
        makeAutoObservable(this);
    }
    insertCommitMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: '/insert',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
    getProjectCommitMethod = async(data)=>{
        return await this.instance({
            method: 'get',
            url: '/getall',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data,
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
}

export const commitService = new CommitService();
export { CommitService };