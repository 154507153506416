import { makeAutoObservable, reaction } from 'mobx';
import { timelineStore } from '../timeline/timeLine';
import { pageIndex } from './pageIndex';

class IsFourthRenderStore {
    
    isFourthRender=false;
    fourthLoadDone=false;
    fourthUnloadStart=false;
    upOrdown=null;
    constructor() {
        makeAutoObservable(this);
    }
    wheelDown=()=>{
        if(this.fourthLoadDone){
            this.setFourthUnloadStart(true);
        }
    }
    setIsRender=(value)=>{
        this.isFourthRender=value;
    }
    setFourthUnloadStart=(value)=>{
        this.fourthUnloadStart=value;
    }
    setFourthLoadDone=(value)=>{
        this.fourthLoadDone=value;
    }
    init=()=>{
        this.setFourthUnloadStart(false);
        this.setFourthLoadDone(false);
        timelineStore.fourthline.init();
    }
    setNextPage=()=>{
        if(this.upOrdown){
            pageIndex.setPageIndex(pageIndex.index+1);
        }else{
            pageIndex.setPageIndex(pageIndex.index-1);
        }
    }
    setUpOrDown=(value)=>{
        this.upOrdown=value;
    }
}
const isFourth=new IsFourthRenderStore();
reaction(()=>timelineStore.fourthline.isLoadDone,(value)=>{
    if(value){
        isFourth.setFourthLoadDone(true);
    }
    pageIndex.setIsSet(value);
});
reaction(()=>timelineStore.fourthline.isTimeLineDone,(value)=>{
    if(value){
        isFourth.setIsRender(false);
        isFourth.setNextPage();
        isFourth.init();
    }
    pageIndex.setIsSet(value);
});
export {isFourth};