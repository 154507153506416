import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { timelineStore } from "../../mobx/timeline/timeLine";

const Left = styled.div({});
const Right = styled.div({});
const ImgBan = styled.div`
  width: 100%;
  height: 10%;
  background-image: url(${(props) => props.$wtf});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  filter: blur(3px);
  transition: height 0.5s;
`;
const ImgBen = styled.div`
  width: 100%;
  height: 10%;
  background-image: url(${(props) => props.$wtf});
  background-position: right top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  filter: blur(3px);
  transition: height 0.5s;
`;
const Container = styled.div`
  width: 27%;
  height: 44%;
  position: absolute;
  bottom: 6%;
  right: 13%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  ${Left} {
    position: relative;
    left: -100%;
    opacity: 0;
    width: 90%;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .choose {
      height: 70%;
      filter: blur(0px);
    }
  }
  ${Right} {
    position: relative;
    left: -94%;
    opacity: 0;
    width: 6%;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .choose {
      height: 70%;
      filter: blur(0px);
    }
  }
`;


export function SecondBanner({banner}) {

  const [focus, setFocus] = useState(0);
  const [imgdata, setImgdata] = useState(null);
  const banRef=useRef();
  useEffect(()=>{
    const data=banner.split(";")
    data.pop();
    setImgdata(data);
    //console.log(data);
  },[banner]);
  useLayoutEffect(()=>{
    let ctx=gsap.context(()=>{
            timelineStore.secondline.detailBanDo(banRef);
        }
    );
    return ()=>ctx.revert();
  },[]);
  return (
    <Container ref={banRef}>
      <Left
        onWheel={(e) => {
          if (e.deltaY > 0 && focus < imgdata.length - 1) {
            setFocus(focus + 1);
          } else if (e.deltaY < 0 && focus > 0) {
            setFocus(focus - 1);
          }
        }}
      >
        {imgdata&&imgdata.map((v, i) => (
          <ImgBan
            key={i}
            $wtf={v}
            className={focus === i ? "choose" : ""}
          ></ImgBan>
        ))}
      </Left>
      <Right>
        {imgdata&&imgdata.map((v, i) => (
          <ImgBen
            key={i}
            $wtf={v}
            className={focus === i ? "choose" : ""}
          ></ImgBen>
        ))}
      </Right>
    </Container>
  );
}
