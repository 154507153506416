import { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { observer } from "mobx-react";
import { isRenderStore } from "../../mobx/render/isRender";
import { timelineStore } from "../../mobx/timeline/timeLine";

const Container = styled.div`
  opacity: 0;
  font-family: jhun;
  width: 4%;
  height: 22%;
  position: absolute;
  top: calc(23% - 100px);
  right: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    display: block;
    font-size: 1.8vw;
    font-weight: 600;
    color: white;
  }
`;

function FourthName({ value, animation }) {
  const strarr = value.split("");
  const nameRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (animation) {
        animation(nameRef);
      }
    }, nameRef);
    return () => ctx.revert();
  }, [animation]);
  if(isRenderStore.fourthRender.fourthUnloadStart){
    timelineStore.fourthline.fourthNameUnload(nameRef);
  }
  return (
    <Container ref={nameRef}>
      {strarr.map((v, i) => {
        return <span key={i}>{v}</span>;
      })}
    </Container>
  );
}
const FourthNameOb = observer(FourthName);
export { FourthNameOb };
