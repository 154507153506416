import { useState } from "react";
import styled from "styled-components";

const AddContainer = styled.div({});
const AddInput = styled.section({});
const AddTips = styled.section({});
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 95;
  background-color: rgba(58, 56, 56, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  ${AddContainer} {
    width: 30%;
    height: 30%;
    background-color: #fff;
    border-radius: 1vh;
    box-shadow: 0 0 10px 0 #000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .top {
      width: 75%;
      height: 20%;
      //background-color: aqua;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .step1,
      .step2 {
        width: 2.5vw;
        height: 2.5vw;
        border-radius: 50%;
        border: 1px solid rgba(61, 60, 60, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.9vw;
        font-family: art;
        font-weight: bold;
      }
      .act {
        box-shadow: 0 0 20px 10px rgb(102, 127, 191);
      }
      .connect {
        width: 75%;
        height: 0.1vw;
        background-color: rgba(61, 60, 60, 0.3);
        box-shadow: 2px 2px 10px 0 rgb(102, 127, 191);
      }
    }
    .middle {
      width: 75%;
      height: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      ${AddTips} {
        span {
          font-size: 0.55vw;
          font-family: one;
          letter-spacing: 0.1vw;
        }
      }
      ${AddInput} {
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        input {
          width: 50%;
          height: 50%;
          border: 1px solid rgba(61, 60, 60, 0.3);
          border-radius: 0.6vh;
          font-size: 1vw;
          font-family: one;
          outline: none;
          padding-left: 0.5vw;
        }
        span {
          display: block;
          font-size: 1vw;
          font-family: one;
        }
        .choose {
          position: relative;
          border: 1px solid rgba(61, 60, 60, 0.3);
          background-color: #fff;
          border-radius: 0.6vh;
          width: 50%;
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          p {
            font-size: 1vw;
            font-family: one;
            font-weight: bold;
          }
          &::after {
            content: "";
            position: absolute;
            top: calc(50% - 0.25vw);
            right: 0.5vw;
            width: 0;
            height: 0;
            border: 0.5vw solid transparent;
            border-top-color: rgba(61, 60, 60, 0.5);
          }
          &:hover {
            .chooselist {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              position: absolute;
              top: 102%;
              width: 100%;
              height: 6vw;
              background-color: #fff;
              border: 1px solid rgba(61, 60, 60, 0.3);
              border-radius: 0.6vh;
            }
            .chooseact {
              background-color: rgba(61, 60, 60, 0.3);
              color: #fff;
            }
          }
          .chooselist {
            display: none;
            span {
              display: block;
              width: 100%;
              height: 30%;
              font-size: 1vw;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .bottom {
      width: 60%;
      height: 20%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      button {
        width: 20%;
        height: 60%;
        border: 1px solid rgba(61, 60, 60, 0.3);
        background-color: rgb(39, 114, 240);
        border-radius: 0.6vh;
        font-size: 1vw;
        color: #fff;
        font-family: one;
        cursor: pointer;
      }
    }
  }
`;

function PersonRoleAdd({ control, setControl, groupData, roleSolve, add }) {
  const [step, setStep] = useState(0);
  const [group, setGroup] = useState("game");
  const [role, setRole] = useState("");

  return (
    <Container>
      <AddContainer>
        <section className="top">
          <div className={step === 0 ? "step1 act" : "step1"}>1</div>
          <div className="connect"></div>
          <div className={step === 1 ? "step2 act" : "step2"}>2</div>
        </section>
        <section className="middle">
          {step === 0 && (
            <>
              <AddInput>
                <span>选择分组:</span>
                <div className="choose">
                  <p>{roleSolve(group)}</p>
                  <div className="chooselist">
                    {groupData.map((item, index) => (
                      <span
                        key={index}
                        onClick={() => {
                          setGroup(item.role);
                        }}
                        className={group === item.role ? "chooseact" : ""}
                      >
                        {roleSolve(item.role)}
                      </span>
                    ))}
                  </div>
                </div>
              </AddInput>
              <AddTips>
                <span>请选择你想添加的角色分组</span>
              </AddTips>
            </>
          )}
          {step === 1 && (
            <>
              <AddInput>
                <span>角色名:</span>
                <input
                  type="text"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                />
              </AddInput>
              <AddTips>
                <span>请输入你想添加的角色名,请先在角色操作查看是否已存在</span>
              </AddTips>
            </>
          )}
        </section>
        <section className="bottom">
          {step === 0 && (
            <button
              onClick={() => {
                setStep(1);
              }}
            >
              下一步
            </button>
          )}
          {step === 1 && (
            <button
              onClick={() => {
                add({
                  work:role,
                  role:group,
                });
              }}
            >
              确认
            </button>
          )}
          <button
            onClick={() => {
              setControl({
                ...control,
                isRoleAdd: false,
              });
            }}
          >
            取消
          </button>
        </section>
      </AddContainer>
    </Container>
  );
}

export { PersonRoleAdd };
