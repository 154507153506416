import { makeAutoObservable } from 'mobx';
import {httpPort} from '../../utils/env';
import axios from 'axios';

class EditpageService {
    instance = axios.create({
        baseURL: `${httpPort}/editpage`,
        timeout: 15000,
        headers: { 'X-Custom-Header': 'foobar' }
    });
    constructor() {
        makeAutoObservable(this);
    }
   addActivityMethod = async(data)=>{
       return await this.instance({
            method: 'post',
            url: '/addactivty',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            alert("添加成功");
            window.location.reload();
        }).catch((error)=>{
            alert("添加失败");
            window.location.reload();
        });
    }
    getAllActivityMethod = async()=>{
        return await this.instance({
            method: 'get',
            url: '/getallactivty',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
    getActivityMethod = async(data)=>{
        return await this.instance({
            method: 'get',
            url: '/getactivtybyid',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params: data,
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }

    deleteActivityMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: 'deleteactivty',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            alert("删除成功");
            window.location.reload();
        }).catch((error)=>{
            alert("删除失败");
            window.location.reload();
        });
    }

    updateAboutMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: 'updateabout',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            alert("修改成功");
            window.location.reload();
        }).catch((error)=>{
            alert("修改失败");
            window.location.reload();
        });
    }

    getAboutMethod = async()=>{
        return await this.instance({
            method: 'get',
            url: '/getallabout',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
    
    addPersonMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: 'addperson',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            alert("添加成功");
            window.location.reload();
        }).catch((error)=>{
            alert("添加失败");
            window.location.reload();
        });
    }

    getAllPersonMethod = async()=>{
        return await this.instance({
            method: 'get',
            url: '/getallperson',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((response)=>{
            return new Promise((resolve,reject)=>{
                resolve(response);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
    deletePersonMethod = async(data)=>{
        return await this.instance({
            method: 'post',
            url: 'deleteperson',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response)=>{
            alert("删除成功");
            window.location.reload();
        }).catch((error)=>{
            alert("删除失败");
            window.location.reload();
        });
    }
}

const editpageService = new EditpageService();
export { editpageService };