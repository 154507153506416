import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const GenderBlock = styled.div({});
const Child = styled.div({});
const Children = styled.div`
  height: ${(props) => props.$height || "5vh"};
`;
const Container = styled.div`
  position: absolute;
  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${Children} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    p {
      font-size: 0.85vw;
      margin-right: 1vw;
      margin-top: 0.5vh;
      font-weight: 600;
      font-family: one;
    }
    ${Child} {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      font-family: one;
      margin-right: 2vw;
      span {
        font-size: 0.85vw;
        margin-right: 1vw;
        margin-top: 0.5vh;
        font-weight: 600;
      }
      input {
        font-family: one;
        font-size: 0.65vw;
        outline: none;
        border: 1px solid rgb(177, 175, 175);
        width: 12vw;
        height: 2.5vh;
        padding-left: 0.5vh;
        border-radius: 0.5vh;
      }
      textarea {
        resize: none;
        outline: none;
        border: 1px solid rgb(177, 175, 175);
        border-radius: 0.5vh;
        width: 17vw;
        height: 7vh;
        padding: 0.5vh 0.5vh;
        font-family: one;
      }
      .avater {
        position: absolute;
        left: 3vw;
        width: 7vh;
        height: 7vh;
        padding: 0;
        border-radius: 0.5vh;
        border: 2px solid white;
      }
      img{
        background-color: black;
      }
    }
    ${GenderBlock} {
      width: 70%;
      height: 3.8vh;
      border-radius: 1vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #aeb4bf;
      span {
        display: block;
        width: 47%;
        font-size: 0.7vw;
        font-family: one;
        font-weight: 600;
        color: rgb(157, 164, 177);
        text-align: center;
        height: 3.4vh;
        line-height: 4vh;
        border-radius: 1vh;
        cursor: pointer;
        transition: all 0.3s;
      }
      .choose {
        background-color: rgb(39, 114, 240);
        color: white;
      }
      .choosen {
        background-color: rgb(249, 204, 226);
        color: white;
      }
    }
    button {
      width: 5vw;
      height: 3vh;
      background-color: rgb(39, 114, 240);
      color: white;
      font-size: 0.85vw;
      font-family: one;
      border: none;
      border-radius: 0.5vh;
      cursor: pointer;
      transition: all 0.3s;
      margin-left: 2.65vw;
      &:hover {
        background-color: rgb(249, 204, 226);
        color: rgb(39, 114, 240);
      }
    }
  }
`;

function SpaceInfo({
  update,
  isman,
  userid,
  isManner=false,
  avater,
  name,
  nickname,
  school,
  level,
  title,
  genderr,
  permission,
  $top='27%',
  $width='45%',
  $height='65%',
  $left='24%',
}) {
  const [gender, setGender] = useState(isman);
  const [data, setData] = useState({
    name: name,
    nickname: nickname,
    school: school,
    level: level,
    title: title,
    gender: isman,
    avaterData: null,
    userid: userid,
    isManner: isManner,
  });
  const avaterRef = useRef();
  const imgRef = useRef();
  useEffect(() => {
    setGender(genderr);
  }, [genderr]);
  return (
    <Container
      $top={$top}
      $width={$width}
      $height={$height}
      $left={$left}
    >
      <Children $height={"10vh"}>
        <Child>
          <span>头像</span>
          <input
            style={{ visibility: "hidden" }}
            ref={avaterRef}
            type="file"
            className="avater"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onload = function (e) {
                  const arrayBuffer = e.target.result;
                  const uint8Array = new Uint8Array(arrayBuffer);
                  const blob = new Blob([uint8Array.buffer], { type: file.type });
                  const url = URL.createObjectURL(blob);
                  imgRef.current.src = url;
                  // const formData = new FormData();
                  // formData.append("file", blob);
                  setData({ ...data,avaterName:file.type?file.type.split('/')[1]:'.png', file: blob });
                };
                reader.readAsArrayBuffer(file);
              }
            }}
          />
          <img
            onClick={() => {
              avaterRef.current.click();
            }}
            src={avater}
            ref={imgRef}
            alt=""
            className="avater"
          />
        </Child>
      </Children>
      <Children>
        <Child>
          <span>昵称</span>
          <input
            type="text"
            placeholder={nickname}
            onChange={(e) => {
              setData({ ...data, nickname: e.target.value });
            }}
          />
        </Child>
        <Child>
          <span>姓名</span>
          <input
            type="text"
            placeholder={name}
            readOnly={permission === "pro" ? "" : "readOnly"}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
        </Child>
      </Children>
      <Children>
        <Child>
          <span>学号</span>
          <input
            type="text"
            placeholder={school}
            readOnly={permission === "pro" ? "" : "readOnly"}
            onChange={(e) => {
              setData({ ...data, school: e.target.value });
            }}
          />
        </Child>
        <Child>
          <span>年级</span>
          <input
            type="text"
            placeholder={level}
            readOnly={permission === "pro" ? "" : "readOnly"}
            onChange={(e) => {
              setData({ ...data, level: e.target.value });
            }}
          />
        </Child>
      </Children>
      <Children>
        <p>性别</p>
        <GenderBlock>
          <span
            className={gender === "男" ? "choose" : null}
            onClick={() => {
              setGender("男");
              setData({ ...data, gender: "男" });
            }}
          >
            ♂ 男
          </span>
          <span
            className={gender === "女" ? "choosen" : null}
            onClick={() => {
              setGender("女");
              setData({ ...data, gender: "女" });
            }}
          >
            ♀ 女
          </span>
        </GenderBlock>
      </Children>
      <Children $height={"10vh"}>
        <Child>
          <span>介绍</span>
          <textarea
            placeholder={title}
            onChange={(e) => {
              setData({ ...data, title: e.target.value });
            }}
          ></textarea>
        </Child>
      </Children>
      <Children>
        <button onClick={() => {
          console.log(data);
          update(data);
        }}>提交修改</button>
      </Children>
    </Container>
  );
}

export { SpaceInfo };
