import './assets/font/font.css';
import { PersonSpace } from './modules/PersonSpace';
// import {fontstr} from './assets/font/font';
import { SecondDetail } from './modules/SecondDetail';
import { Sign } from './modules/Sign';
import { AllOb } from './pages/All';
import {createBrowserRouter,RouterProvider} from 'react-router-dom';
import { AuthOb } from './pages/Auth';
import { ProjectPerson } from './modules/ProjectPerson';
import { ProjectsShow } from './modules/ProjectsShow';
import { SpacePerson } from './modules/SpacePerson';
// import { useEffect } from 'react';

const router=createBrowserRouter([
  {
    path:'/',
    element:<AllOb/>
  },
  {
    path:'/detail/:id',
    element:<SecondDetail></SecondDetail>
  },
  {
    path:'/sign',
    element:<Sign></Sign>
  },
  {
    path:'/projects',
    element:<ProjectsShow></ProjectsShow>
  },
  {
    path:'/space',
    element:<AuthOb children={()=>{
      return <PersonSpace></PersonSpace>
    }}></AuthOb>
  },
  {
    path:'/projectperson/:id',
    element:<ProjectPerson></ProjectPerson>
    // <AuthOb children={()=>{
    //   return 
    // }}></AuthOb>
  },
  {
    path:'/spaceperson/:id',
    element:<AuthOb children={()=>{
      return <SpacePerson></SpacePerson>
    }}></AuthOb>
  }
]);

function App() {
  // useEffect(()=>{
  //   const style=document.createElement('style');
  //   style.innerHTML=fontstr;
  //   document.head.appendChild(style);
  // },[]);
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
