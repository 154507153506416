import styled from "styled-components";
import { gsap } from "gsap";
import { useLayoutEffect, useRef} from "react";

import { observer } from "mobx-react";
import { timelineStore } from "../../mobx/timeline/timeLine";
import { pageIndex } from "../../mobx/render/pageIndex";
import avater from '../../assets/png/avater.png';

const OpLeft = styled.div({});
const OpList = styled.div({});
const ListChild = styled.div({});
const OpRight = styled.div({});
const OpLog = styled.div({});
const OpOne = styled.div`
  position: absolute;
  bottom: 90px;
  right: 0;
  bottom: 30%;
  width: 87%;
  height: 70%;
  background-color: rgb(56, 56, 56);
  border-left: 0.4vw solid rgb(135, 152, 198);
  box-shadow: -10px 10px 9px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  ${OpLeft} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    span {
      display: block;
      height: 1.2vw;
      line-height: 1.2vw;
      font-size: 1vw;
      font-family: one;
      font-weight: 900;
    }
  }
  ${OpLog} {
    display: none;
    opacity: 0;
    position: absolute;
    bottom: 5%;
    width: 100%;
    height: 30%;
    display:flex ;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    img{
      width: 2vw;
      height: 2vw;
      border-radius: 50%;
      border: 0.1vw solid white;
    }
    span{
      font-size: 0.8vw;
      font-weight: 500;
      font-family: one;
    }
    section{
      width: 100%;
      font-size: 0.8vw;
      font-weight: 500;
      font-family: one;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      button{
        background-color: transparent;
        border: none;
        width: 45%;
        font-size: 0.75vw;
        font-weight: 500;
        font-family: one;
        color: aliceblue;
        cursor: pointer;
      }
    }
  }
  ${OpList} {
    display: none;
    opacity: 0;
    width: 100%;
    height: 45%;
    margin-top: 3vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .choose {
      span {
        background-color: rgb(101, 127, 191);
        box-shadow: 0px 10px 11px rgba(0, 0, 0, 0.4);
      }
      font-weight: 600;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -0.5vw;
        border-top: 0.8vw solid transparent;
        border-left: 1.45vw solid white;
        border-bottom: 0.8vw solid transparent;
      }
    }
    ${ListChild} {
      padding-left: 10%;
      width: 90%;
      height: 20%;
      letter-spacing: 0.05vw;
      cursor: pointer;
      display: flex;
        flex-direction: row;
        align-items: center;
      span {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 0.8vw;
        font-family: one;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
  ${OpRight} {
    position: absolute;
    z-index: 90;
    top: 65%;
    left: -1vw;
    border-top: 2vh solid transparent;
    border-right: 1.5vw solid rgb(56, 56, 56);
    border-bottom: 2vh solid transparent;
  }
`;

const pageList = [
  { name: "首页", index: 0, id: 0, front: 4, next: 1 },
  { name: "活动详情", index: 1, id: 1, front: 0, next: 2 },
  { name: "关于我们", index: 2, id: 2, front: 1, next: 3 },
  { name: "人员介绍", index: 3, id: 3, front: 2, next: 4 },
  { name: "作品展示", index: 4, id: 4, front: 3, next: 0 },
];

function OptionOne({isLog,nagative,data,logOut}) {
  const opRef = useRef();
  const fontRef = useRef();
  const rightRef = useRef();
  const listRef = useRef();
  const logRef = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {}, opRef);
    return () => ctx.revert();
  }, []);

  return (
    <OpOne
      ref={opRef}
      onMouseEnter={() => {
        timelineStore.optionline.optionOneDo(opRef);
        timelineStore.optionline.optionFontDo(fontRef);
        timelineStore.optionline.optionRightDo(rightRef);
        timelineStore.optionline.optionListDo(listRef);
        timelineStore.optionline.optionListDo(logRef);
      }}
      onMouseLeave={() => {
        timelineStore.optionline.optionOneLeave(opRef);
        timelineStore.optionline.optionFontLeave(fontRef);
        timelineStore.optionline.optionRightLeave(rightRef);
        timelineStore.optionline.optionListLeave(listRef);
        timelineStore.optionline.optionListLeave(logRef);
      }}
    >
      <OpRight ref={rightRef}></OpRight>
      <OpLeft ref={fontRef}>
        <span>O</span>
        <span>P</span>
        <span>T</span>
        <span>I</span>
        <span>O</span>
        <span>N</span>
      </OpLeft>
      <OpList ref={listRef}>
        {pageList.map((item) => {
          return (
            <ListChild
              key={item.id}
              className={pageIndex.index === item.id ? "choose" : null}
              onClick={() => {
                if (pageIndex.isSetIndex) {
                  pageIndex.setPageIndex(item.index);
                }
              }}
            >
              <span>{item.name}</span>
            </ListChild>
          );
        })}
      </OpList>
      <OpLog ref={logRef}>
        {isLog ? <>
          <img onClick={()=>{nagative('/space',{replace:true});}} src={data.avater} alt="" />
          <span>{data.name}</span>
          <section>
            <button onClick={logOut}>注销</button>
          </section>
        </>:<>
          <img onClick={()=>{nagative('/sign',{replace:true});}} src={avater} alt="" />
        </>}
      </OpLog>
    </OpOne>
  );
}

const OptionOneOb = observer(OptionOne);
export { OptionOneOb };
