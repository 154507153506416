import { forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

const Title = styled.div({});
const Container = styled.div`
  position: absolute;
  width: 55%;
  height: 65%;
  left: 0;
  bottom: 0;
  background-color: #fff;
  overflow: hidden;
  border-radius: 1vh;
  ${Title} {
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
    padding: 10px;
    font-family: one;
  }
`;


function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const DeviceNet =forwardRef(({data, setData},ref)=>{
  // const [data, setData] = useState([]);
  const conRef =useRef();
  useImperativeHandle(ref,()=>({
    ...ref.current,
    fullNetScreen(){
      conRef.current.requestFullscreen();
    }
  }));
  useEffect(() => {
    const time = new Date().toLocaleTimeString();
    setData([
      {
        date: time,
        type: "delay",
        delay: getRandomInteger(0, 40),
        download: getRandomInteger(0, 20),
        upload: getRandomInteger(0, 80),
      },
    ]);
    const intervalId = setInterval(() => {
      setData((currentData) => {
        const newTime = new Date().toLocaleTimeString();
        const newData = [
          ...currentData,
          {
            date: newTime,
            type: "delay",
            delay: getRandomInteger(10, 40),
            download: getRandomInteger(1, 20),
            upload: getRandomInteger(30, 80),
          },
        ];
        return newData.length > 8 ? newData.slice(1) : newData;
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [setData]);

  return (
    <Container ref={conRef}>
        <Title>当前设备网络状态</Title>
      <ResponsiveContainer width="100%" height="90%">
        <LineChart
          
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip 
            formatter={(value,name)=>{
                if(name === 'delay'){
                    return value + ' Ms';
                }
                return value + ' Mbps';
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="delay"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="download" stroke="#82ca9d" />
          <Line type="monotone" dataKey="upload" stroke="#cf9413" />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
});


export { DeviceNet };
