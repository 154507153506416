import styled from "styled-components";
import { gsap } from "gsap";
import { useLayoutEffect, useRef, useState} from "react";
import { timelineStore } from "../mobx/timeline/timeLine";
import { observer } from "mobx-react";

import { OptionOneOb } from "../components/option/OptionOne";
import { OptionTwo } from "../components/option/OptionTwo";
import { service } from "../mobx/service/service";
import { useNavigate } from "react-router-dom";

const OpContainer = styled.div`
  opacity: 0;
  position: absolute;
  z-index: 93;
  bottom: 15%;
  right: 0;
  width: 2.8%;
  height: 22.6%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function Option() {

  const nagative=useNavigate();
  const [isLog, setIsLog] = useState(false);
  const [data, setData] = useState({});
  const opRef = useRef();
  useLayoutEffect(() => {
    if(localStorage.getItem('token')){
      service.info.getInfoMethod().then(
        (res)=>{
          setData(res.data);
          setIsLog(true);
          service.setSignIn(true);
        }
      ).catch((error)=>{
        setIsLog(false);
        service.setSignIn(false);
      });
    }
    let ctx = gsap.context(() => {
      timelineStore.firstline.firstOptionDo(opRef);
    }, opRef);
    return () => ctx.revert();
  }, []);

  return (
    <OpContainer ref={opRef}>
      <OptionOneOb logOut={()=>{
        localStorage.removeItem('token');
        setIsLog(false);
        window.location.reload();
      }} isLog={isLog} data={data} nagative={nagative}></OptionOneOb>
      <OptionTwo></OptionTwo>
    </OpContainer>
  );
}

const OptionOb = observer(Option);
export { OptionOb };
