import { makeAutoObservable} from 'mobx';

class PageIndex {

    index=0;
    isSetIndex=false;
    constructor() {
        makeAutoObservable(this);
    }
    setPageIndex=(value)=>{ 
        if(value<0){
            return;
        }
        this.index=value;
    }
    setIsSet=(value)=>{
        this.isSetIndex=value;
    }
}

const pageIndex = new PageIndex();
export { pageIndex };