import { makeAutoObservable } from 'mobx';
import {httpPort} from '../../utils/env';
import axios from 'axios';

class SignService {
    instance = axios.create({
        baseURL: `${httpPort}/user`,
        timeout: 5000,
        headers: { 'X-Custom-Header': 'foobar' }
    });
    constructor() {
        makeAutoObservable(this);
    }
    oauthTestMethod = async (inputData) => {
        await this.instance({
            method: 'post',
            url: '/test',
            data: inputData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if(response.data.issuccess){
                localStorage.setItem('token',response.data.token);
                window.location.replace('/');
            }
        }).catch((error)=>{
            alert('注册失败'+error.response.data.message);
            window.location.reload();
        });
    }
    getVerifyCodeMethod = async () => {
        return await this.instance({
            method: 'get',
            url: '/verifycode',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            localStorage.setItem('codeid',response.data.uuid);
            return new Promise((resolve, reject) => {
                resolve(response.data.code);
            });
        }).catch((error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }
    signInMethod = async(inputData)=>{
        await this.instance({
            method: 'post',
            url: '/signin',
            data: inputData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if(response.data.issuccess){
                localStorage.setItem('token',response.data.token);
                window.location.replace('/');
            }
        }).catch((error)=>{
            alert(error.response.data.message);
            window.location.reload();
        });
    }
    signUpMethod = async(inputData)=>{
        await this.instance({
            method: 'post',
            url: '/signup',
            data: inputData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if(response.data.issuccess){
                alert('注册成功');
                window.location.reload();
            }
        }).catch((error)=>{
            alert('注册失败'+error.response.data.message);
            window.location.reload();
        });
    }
    getPermissionMethod = async()=>{
        return await this.instance({
            method:'post',
            url:'/permission',
            data:{},
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then((res)=>{
            return new Promise((resolve,reject)=>{
                resolve(res);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
    getRoleMethod = async(data={})=>{
        return await this.instance({
            method:'post',
            url:'/role',
            data:data,
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        }).then((res)=>{
            return new Promise((resolve,reject)=>{
                resolve(res);
            });
        }).catch((error)=>{
            return new Promise((resolve,reject)=>{
                reject(error);
            });
        });
    }
}

const signService = new SignService();
export { signService};