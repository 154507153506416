import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { service } from "../../mobx/service/service";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Top = styled.div({});
const Bottom = styled.div({});
const BottomItem = styled.div`
  height: ${(props) => props.$height};
`;
const BottomTitle = styled.div({});
const Container = styled.div`
  position: absolute;
  left: 20%;
  top: 25%;
  width: 75%;
  height: 72%;
  ${Top} {
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .topleft {
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: jhun;
      font-size: 1vw;
    }
    .topright {
      width: 55%;
      height: 100%;
      border-bottom: 1px solid rgba(51, 50, 50, 0.4);
    }
  }
  ${Bottom} {
    width: 100%;
    height: 93%;
    //background-color: aliceblue;
    overflow: auto;
    scrollbar-width: none;
    padding: 2% 0 0 0;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    ${BottomTitle},${BottomItem} {
      border-bottom: 2px solid rgb(175, 188, 202);
      width: 100%;
      height: 4%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      section {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: one;
        font-size: 0.65vw;
        font-weight: 600;
        letter-spacing: 0.1vw;
        //background-color: aqua;
      }
      .id {
        width: 16%;
      }
      .ip {
        width: 8%;
      }
      .info {
        width: 30%;
      }
      .control {
        width: 20%;
      }
    }
    ${BottomItem} {
      height: 6%;
      .control {
        p {
          margin: 0 1vh;
          color: rgb(102, 127, 191);
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
`;
const Charts = styled.div`
  transition: all 0.5s;
  width: 100%;
  height: 0;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow:0px 0px 10px 0px rgba(0,0,0,0.4);
  border-radius: 1vh;
  .left {
    height: 100%;
    width: 58%;
  }
  .right{
    height: 100%;
    width: 38%;
  }
`;

function Items({ data, id }) {
  const [isShow, setIsShow] = useState(false);
  const ref=useRef();
  useEffect(()=>{
    if(isShow){
      ref.current.style.height="24vh";
    }else{
      ref.current.style.height="0";
    }
  },[isShow]);
  return (
    <>
      <BottomItem>
        <section className="id">{id}</section>
        <section className="ip">{data.ip}</section>
        <section className="info">展开设备查看详细状态</section>
        <section className="control">
          <p
            onClick={() => {
              setIsShow(!isShow);
            }}
          >
            {isShow ? "收起" : "展开"}
          </p>
        </section>
      </BottomItem>
      <Charts ref={ref}>
        <div className="left">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data.data.netData}
              margin={{
                top: 20,
                right: 30,
                left: 10,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip
                formatter={(value, name) => {
                  if (name === "delay") {
                    return value + " Ms";
                  }
                  return value + " Mbps";
                }}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="delay"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="download" stroke="#82ca9d" />
              <Line type="monotone" dataKey="upload" stroke="#cf9413" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="right">
          <ResponsiveContainer width="100%" height="90%">
            <RadarChart
              cx="50%"
              cy="50%"
              outerRadius="80%"
              data={data.data.meData}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis angle={30} domain={[0, 150]} />
              <Radar
                name="最低占用"
                dataKey="A"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
              <Radar
                name="瞬时占用"
                dataKey="B"
                stroke="#82ca9d"
                fill="#82ca9d"
                fillOpacity={0.6}
              />
              <Legend />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </Charts>
    </>
  );
}

function DeviceManner() {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    service.socket.socket.on("devices", (data) => {
        if(data.length===0){
          setDevices([]);
          return;
        }
      new Map(data).forEach((value, key) => {
        setDevices((cudata) => {
          let index = cudata.findIndex((item) => item.id === key);
          if (index === -1) {
            // 设备尚未存在，添加新设备
            return [...cudata, { id: key, value: value }];
          } else {
            // 设备已存在，更新该设备的值
            return cudata.map((item, idx) =>
              idx === index ? { ...item, value: value } : item
            );
          }
        });
      });
    });
    const intervalId = setInterval(() => {
      service.socket.socket.emit("events");
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <Container>
      <Top>
        <div className="topleft">设备管理</div>
        <div className="topright"></div>
      </Top>
      <Bottom>
        <BottomTitle>
          <section className="id">ID</section>
          <section className="ip">IP</section>
          <section className="info">信息</section>
          <section className="control">操作</section>
        </BottomTitle>
        {devices.map((item, index) => {
          return <Items data={item.value} key={item.id} id={item.id}></Items>;
        })}
      </Bottom>
    </Container>
  );
}

export { DeviceManner };
