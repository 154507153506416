import { useEffect, useRef, useState } from "react";
import upload from "../../assets/png/upload.png";
import styled from "styled-components";

const InputContainer = styled.div({});
const PartContainer = styled.div`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
`;
const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 44, 44, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  ${PartContainer} {
    background-color: white;
    border-radius: 1vh;
    box-shadow: 0 0 1vh 0.5vh rgba(0, 0, 0, 0.1);
    padding: 2vh;
    padding-bottom: 0;
    ${InputContainer} {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: flex-start;
      //width: 40%;
      margin-bottom: 2.5vh;
      span {
        display: block;
        font-family: one;
        font-size: 0.85vw;
        font-weight: 600;
        margin-right: 1vw;
        width: 4.5vw;
      }
      section {
        position: relative;
        width: 16vw;
        height: 18vh;
        border-radius: 0.5vh;
        border: 1px solid rgb(177, 175, 175);
        overflow: hidden;
        margin-right: 1vh;
        input {
          position: absolute;
          visibility: hidden;
        }
        .reimg {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .load {
          position: absolute;
          z-index: 3;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: rgb(208, 208, 208);
          cursor: pointer;
          img {
            width: 5vh;
            height: 5vh;
            cursor: pointer;
          }
        }
      }
      textarea,
      input {
        border: none;
        outline: none;
        width: 60%;
        height: 6.5vh;
        font-family: one;
        font-size: 0.65vw;
        font-weight: 600;
        border: 1px solid rgb(177, 175, 175);
        padding-left: 0.5vh;
        border-radius: 0.5vh;
        resize: none;
      }
      input {
        height: 2.5vh;
      }
      button {
        width: 5vw;
        height: 3vh;
        background-color: rgb(39, 114, 240);
        color: white;
        font-size: 0.85vw;
        font-family: one;
        border: none;
        border-radius: 0.5vh;
        cursor: pointer;
        margin-right: 1vh;
      }
    }
  }
`;

function ImgFilter({
  width,
  height,
  imgData,
  setImgData,
  setName,
  ismain = false,
}) {
  const inputRef = useRef(null);
  const imgRef = useRef(null);
  return (
    <section style={{ width: width, height: height }}>
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={inputRef}
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
              const arrayBuffer = e.target.result;
              const uint8Array = new Uint8Array(arrayBuffer);
              const blob = new Blob([uint8Array], { type: file.type });
              const url = URL.createObjectURL(blob);
              imgRef.current.src = url;
              imgRef.current.style.zIndex = 4;
              setImgData([
                ...imgData,
                {
                  file: blob,
                  name: file.name,
                  type: ismain ? "main" : "child",
                },
              ]);
              setName(file.name);
            };
            reader.readAsArrayBuffer(file);
          }
        }}
      />
      <img
        src=""
        alt=""
        className="reimg"
        ref={imgRef}
        onClick={() => {
          inputRef.current.click();
        }}
      />
      <div
        className="load"
        onClick={() => {
          inputRef.current.click();
        }}
      >
        <img src={upload} alt="" />
        <p>上传图片</p>
      </div>
    </section>
  );
}

function AddAct({ close, add }) {
  const [textData, setTextData] = useState({
    maintitle: null,
    childtitle: null,
    context: '',
  });
  const [imgData, setImgData] = useState([]);
  const [mainName, setMainName] = useState(null);
  const [childName1, setChildName1] = useState(null);
  const [childName2, setChildName2] = useState(null);
  const [childName3, setChildName3] = useState(null);
  const [isHot, setIsHot] = useState(false);
  useEffect(()=>{},[isHot]);
  return (
    <Container>
      <PartContainer $width={"60%"} $height={"70%"}>
        <InputContainer>
          <span>活动标题</span>
          <input
            type="text"
            value={textData.maintitle}
            onChange={(e) => {
              setTextData({ ...textData, maintitle: e.target.value });
            }}
          />
        </InputContainer>
        <InputContainer>
          <span>活动副标题</span>
          <input
            type="text"
            value={textData.childtitle}
            onChange={(e) => {
              setTextData({ ...textData, childtitle: e.target.value });
            }}
          />
        </InputContainer>
        <InputContainer>
          <span>活动介绍</span>
          <textarea
            value={textData.context}
            onChange={(e) => {
              setTextData({ ...textData, context: e.target.value });
            }}
          ></textarea>
        </InputContainer>
        <InputContainer>
          <span>封面图</span>
          <ImgFilter
            setName={setMainName}
            imgData={imgData}
            setImgData={setImgData}
            ismain={true}
          ></ImgFilter>
        </InputContainer>
        <InputContainer>
          <span>子图</span>
          <ImgFilter
            setName={setChildName1}
            imgData={imgData}
            setImgData={setImgData}
          ></ImgFilter>
          <ImgFilter
            setName={setChildName2}
            imgData={imgData}
            setImgData={setImgData}
          ></ImgFilter>
          <ImgFilter
            setName={setChildName3}
            imgData={imgData}
            setImgData={setImgData}
          ></ImgFilter>
        </InputContainer>
        <InputContainer>
          <span></span>
          <button
            onClick={() => {
              if (
                mainName &&
                childName1 &&
                childName2 &&
                childName3 &&
                textData.maintitle &&
                textData.childtitle &&
                textData.context
              ) {
                const formData = new FormData();
                // console.log(textData);
                // console.log(imgData);
                const realImgData = imgData.filter(
                  (item) =>
                    item.name === mainName ||
                    item.name === childName1 ||
                    item.name === childName2 ||
                    item.name === childName3
                );
                Object.keys(textData).forEach((key) => {
                  formData.append(key, textData[key]);
                });
                realImgData.forEach((item) => {
                  if (item.type === "main") {
                    formData.append("mainimg", item.name);
                    formData.append("main", item.file);
                  } else {
                    formData.append("bannerimg", item.name);
                    formData.append("child", item.file);
                  }
                });
                add(formData);
                setIsHot(!isHot);
              } else {
                alert("请填写完整信息");
                return;
              }
            }}
          >
            提交
          </button>
          <button
            onClick={() => {
              close();
            }}
          >
            取消
          </button>
        </InputContainer>
      </PartContainer>
    </Container>
  );
}

function AddPeople({ close,add }) {
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState('');
  const [imgData, setImgData] = useState([]);
  const [mainName, setMainName] = useState(null);
  const [isHot, setIsHot] = useState(false);
  useEffect(()=>{
  },[isHot]);
  return (
    <Container>
      <PartContainer $width={"30vw"} $height={"52%"}>
        <InputContainer>
          <span>姓名</span>
          <input value={name} onChange={(e)=>{setName(e.target.value);}} type="text" />
        </InputContainer>
        <InputContainer>
          <span>人员介绍</span>
          <textarea value={desc} onChange={(e)=>{setDesc(e.target.value);}}></textarea>
        </InputContainer>
        <InputContainer>
          <span>封面图</span>
          <ImgFilter
            setName={setMainName}
            imgData={imgData}
            setImgData={setImgData}
            width={"9vw"}
            height={"15vw"}
          ></ImgFilter>
        </InputContainer>
        <InputContainer>
          <span></span>
          <button 
            onClick={()=>{
              if(name&&desc&&mainName){
                const data={
                  name,
                  description:desc,
                  imgname:mainName,
                  file:imgData[0].file
                };
                add(data);
                setIsHot(!isHot);
              }
              else{
                alert("请填写完整信息");
                return;
              }
            }}
          >提交</button>
          <button
            onClick={() => {
              close();
            }}
          >
            取消
          </button>
        </InputContainer>
      </PartContainer>
    </Container>
  );
}

export { AddAct, AddPeople };
