import styled from 'styled-components';
import { gsap } from 'gsap';
import { useLayoutEffect, useRef } from 'react';
import {timelineStore} from '../../mobx/timeline/timeLine';
import {isRenderStore} from '../../mobx/render/isRender';
import {observer} from 'mobx-react';
import oneurl from '../../assets/svg/firstfontone.svg';
import twourl from '../../assets/svg/firstfonttwo.svg';


const FontLeft = styled.div({});
const LeftOne = styled.span({});
const LeftTwo = styled.span({});
const FontRight = styled.div({});
const RightOne = styled.span({});
const RightTwo = styled.span({});
const FontContainer = styled.div`
    position: absolute;
    z-index: 77;
    top:25%;
    right: 0;
    width: 84%;
    height: 22%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    ${FontLeft}{
        opacity: 0;
        width: 16%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        ${LeftOne}{
            font-family: one;
            width: 5vw;
            font-size: 1vw;
            font-weight: 800;
            color: black;
            text-align: left;
            margin-left:30%;
            margin-bottom: 20px;
        }
        ${LeftTwo}{
            font-family: one;
            display: -webkit-box; /* 必须指定display属性 */
            width: 6vw;
            -webkit-line-clamp: 2; /* 控制最大行数 */
            -webkit-box-orient: vertical; /* 垂直布局 */
            overflow: hidden; /* 超过部分隐藏 */
            font-size: 2.2vw;
            font-weight: 800;
            color: black;
            white-space: pre-wrap;
            word-wrap: break-word;
            text-align: center;
            line-height: 3vw;
            margin-left:30%;
        }
    }
    ${FontRight}{
        opacity: 0;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 2vw;
        ${RightOne}{
            font-family: jhun;
            font-size: 1vw;
            font-weight: 800;
            object{
                width: 1vw;
                height: 1vw;
            }
            display: flex;
            flex-direction: row;
            align-items: center;

        }
        ${RightTwo}{
            font-family: art;
            font-size: 5.3vw;
            font-weight: 550;
            color: black;
            
        }
    }  
`;

function FirstFontOne() {

    const fontRef = useRef();
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            timelineStore.firstline.firstFontOneDo(fontRef);
        }, fontRef);
        return () => ctx.revert();
    }, []);
    if(isRenderStore.firstRender.firstUnloadStart){
        timelineStore.firstline.firstFontOneUnload(fontRef);
    }
    return (
        <FontContainer ref={fontRef}>
            <FontLeft>
                <LeftOne>江汉大学</LeftOne>
                <LeftTwo>美人团队</LeftTwo>
            </FontLeft>
            <FontRight>
                <RightOne>
                    <object data={oneurl} type="image/svg+xml" aria-label="First Font"></object>
                    &nbsp;JHUN&nbsp;
                    <object data={twourl} type="image/svg+xml" aria-label="First Font"></object>
                    &nbsp;BLACK JACK
                </RightOne>
                <RightTwo>ART & AI TEAM</RightTwo>
            </FontRight>
        </FontContainer>
    );
}

const FirstFontOneOb = observer(FirstFontOne);
export { FirstFontOneOb };