import styled from "styled-components";
import { observer } from "mobx-react";
import { gsap } from "gsap";
import { useLayoutEffect, useRef } from "react";
import { isRenderStore } from "../../mobx/render/isRender";
import { timelineStore } from "../../mobx/timeline/timeLine";

const Font = styled.div`
  font-family: art;
  color: ${(props) => props.$fontColor};
  width: 100%;
  height: 100%;
  text-align: start;
  line-height: 35vh;
  font-size: ${(props) => props.$fontsize};
  font-weight: ${(props) => props.$fontWeight};
  letter-spacing: 0.8vw;
`;
const FontContainer = styled.div`
  position: ${(props) => props.$position};
  opacity: 0;
  z-index: 3;
  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  padding: 0 1% 0 1%;
`;

function NormalFontW({
  value,
  animation,
  $left,
  $top,
  $right,
  $bottom,
  $width,
  $height,
  $fontsize,
  $fontColor="white",
  $fontWeight=650,
  $position = "absolute",
}) {
  const fontRef = useRef();
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (animation) {
        animation(fontRef);
      }
    }, fontRef);
    return () => {
      ctx.revert();
    };
  }, [animation]);

  if (isRenderStore.thirdRender.thirdUnloadStart) {
    timelineStore.thirdline.thirdFontAboUnload(fontRef);
  }else if(isRenderStore.fourthRender.fourthUnloadStart){
    timelineStore.fourthline.fourthKeyUnload(fontRef);
  }else if(isRenderStore.fifthRender.fifthUnloadStart){
    timelineStore.fifthLine.fifthTeamUnload(fontRef);
  }

  return (
    <FontContainer
      ref={fontRef}
      $left={$left}
      $position={$position}
      $bottom={$bottom}
      $right={$right}
      $top={$top}
      $width={$width}
      $height={$height}
    >
      <Font $fontWeight={$fontWeight} $fontsize={$fontsize} $fontColor={$fontColor}>{value}</Font>
    </FontContainer>
  );
}

const NormalFontWOb = observer(NormalFontW);
export { NormalFontWOb };
