import { makeAutoObservable } from 'mobx';
import {httpPort} from '../../utils/env';
import axios from 'axios';

class UserService {
    instance = axios.create({
        baseURL: `${httpPort}/user`,
        timeout: 5000,
        headers: { 'X-Custom-Header': 'foobar' }
    });
    constructor() {
        makeAutoObservable(this);
    }

    getAllUserMethod = async () => {
        return await this.instance({
            method: 'post',
            url: '/mannerall',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        }).catch((error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }
    getAllUserNormalMethod = async () => {
        return await this.instance({
            method: 'get',
            url: '/getalluser',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        }).catch((error) => {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
    }
    mannerUpdatePasswordMethod = async (data) => {
        return await this.instance({
            method: 'post',
            url: '/mannerpassword',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response) => {
            if (response.data.issuccess) {
                alert(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            alert('更改密码失败');
            window.location.reload();
        });
    }
    mannerDeleteUserMethod = async (data) => {
        return await this.instance({
            method: 'post',
            url: '/mannerdelete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response) => {
            if (response.data.issuccess) {
                alert(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            alert('删除用户失败');
            window.location.reload();
        });
    }

    mannerUpdatePermissionMethod = async (data) => {
        return await this.instance({
            method: 'post',
            url: '/mannerpermission',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data,
        }).then((response) => {
            if (response.data.issuccess) {
                alert(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            alert('更改权限失败');
            window.location.reload();
        });
    }
}

const userService = new UserService();
export { userService };