import { useEffect, useState } from "react";
import styled from "styled-components";

const PeContainer = styled.div({});
const UpContainer = styled.div({});
const Top = styled.div({});
const Bottom = styled.div({});
const BottomItem = styled.div({});
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 95;
  background-color: rgba(58, 56, 56, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${UpContainer} {
    width: 45%;
    height: 45%;
    padding: 1vw;
    background-color: #fff;
    border-radius: 1vh;
    box-shadow: 0 0 10px 0 #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ${Top} {
      position: relative;
      width: 100%;
      height: 7%;
      border-bottom: 1px solid rgba(61, 60, 60, 0.3);
      .topselect {
        position: absolute;
        right: 9vh;
        width: 13%;
        height: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgb(246, 248, 250);
        font-family: one;
        font-size: 0.7vw;
        font-weight: 600;
        cursor: pointer;
        border-radius: 0.5vh;
        border: 2px solid rgb(175, 188, 202);
        .sort {
          width: 0;
          height: 0;
          margin-left: 1vh;
          border-left: 0.5vh solid transparent;
          border-right: 0.5vh solid transparent;
          border-top: 0.5vh solid rgb(0, 0, 0);
        }
        &:hover {
          .choose {
            display: block;
            width: 100%;
            height: 10vh;
            background-color: rgb(246, 248, 250);
            top: 105%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            span {
              height: 33%;
            }
          }
        }
      }
      .choose {
        position: absolute;
        height: 0;
        //transition: all 0.5s;
        overflow: hidden;
        span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .chooseact {
          background-color: rgb(134, 137, 143);
          color: white;
        }
      }
      .goback {
        width: 8%;
        right: 1vh;
      }
    }
    ${Bottom} {
      width: 100%;
      height: 92%;
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      ${BottomItem} {
        width: calc(100% - 4vh);
        padding: 0 2vh;
        height: 3.5vh;
        border-bottom: 1px solid rgba(61, 60, 60, 0.3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: one;
        .forwardname {
          font-size: 0.7vw;
          color: rgb(39, 114, 240);
          font-weight: bold;
        }
        button {
          border: 1px solid rgba(61, 60, 60, 0.3);
          background-color: #fff;
          border-radius: 0.6vh;
          height: 80%;
          padding: 0 1vh;
          font-family: one;
          cursor: pointer;
        }
      }
    }
  }
  ${PeContainer} {
    width: 15%;
    height: 25%;
    margin-top: 10.5%;
    margin-left: 1%;
    padding: 1vw;
    background-color: #fff;
    border-radius: 1vh;
    box-shadow: 3px 3px 10px 0 #3a3939;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: one;
    .nowpe {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: start;
      span {
        font-size: 0.8vw;
      }
    }
    .newpe {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: end;
      .peitem {
        width: 100%;
        height: 3vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        span {
          font-size: 0.8vw;
        }
        .pechoose {
          position: relative;
          width: 1.5vh;
          height: 1.5vh;
          border-radius: 50%;
          background-color: #fff;
          border: 1px solid #757272;
          cursor: pointer;
          margin-left: 1vh;
        }
        .act {
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0.9vh;
            height: 0.9vh;
            border-radius: 50%;
            background-color: rgb(39, 114, 240);
          }
        }
      }
    }
    .pebottom{
      width: 100%;
      display: flex;
      justify-content: center;
      button{
        width: 50%;
        height: 3vh;
        background-color: rgb(39, 114, 240);
        color: white;
        border: none;
        border-radius: 0.5vh;
        font-size: 0.8vw;
        font-family: one;
        cursor: pointer;
      }
    }
  }
`;
const perSolve = (permission) => {
  switch (permission) {
    case "pro":
      return "超级管理员";
    case "admin":
      return "管理员";
    case "person":
      return "普通用户";
    default:
      return "未知";
  }
};

function PersonRoleUp({
  permission,
  control,
  setControl,
  group,
  forwardData,
  roleSolve,
  update,
  peUpdate,
}) {
  const [groupAct, setGroupAct] = useState(0);
  const [data, setData] = useState(forwardData);
  const [newpe, setNewpe] = useState(control.permission);
  useEffect(() => {
    switch (groupAct) {
      case 0:
        setData(forwardData);
        break;
      case 1:
        setData(forwardData.filter((item) => item.roles.role === "game"));
        break;
      case 2:
        setData(forwardData.filter((item) => item.roles.role === "art"));
        break;
      case 3:
        setData(forwardData.filter((item) => item.roles.role === "normal"));
        break;
      default:
        break;
    }
  }, [groupAct, forwardData]);

  return (
    <Container>
      <UpContainer>
        <Top>
          <div className="topselect">
            分组
            <div className="sort"></div>
            <div className="choose">
              {group.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={item.act === groupAct ? "chooseact" : ""}
                    onClick={() => setGroupAct(item.act)}
                  >
                    {item.name}
                  </span>
                );
              })}
            </div>
          </div>
          <div
            className="topselect goback"
            onClick={() => {
              setControl({ ...control, isRole: false });
            }}
          >
            返回
          </div>
        </Top>
        <Bottom>
          {data.map((item, index) => {
            return (
              <BottomItem key={index}>
                <div className="forwardname">
                  {item.works + " - " + roleSolve(item.roles.role)}
                </div>
                <button
                  onClick={() => {
                    update({
                      userid: control.userid,
                      newforward: item.id,
                      role:control.role,
                    });
                  }}
                >
                  更改为当前角色
                </button>
              </BottomItem>
            );
          })}
        </Bottom>
      </UpContainer>
      {permission === "pro" && (
        <PeContainer>
          <section className="nowpe">
            <span>该用户当前权限为:{perSolve(control.permission)}</span>
            <span>可更改为</span>
          </section>
          <section className="newpe">
            <div className="peitem">
              <span>管理员</span>
              <div
                className={newpe === "admin" ? "pechoose act" : "pechoose"}
                onClick={() => {
                  setNewpe("admin");
                }}
              ></div>
            </div>
            <div className="peitem">
              <span>普通用户</span>
              <div
                className={newpe === "person" ? "pechoose act" : "pechoose"}
                onClick={() => {
                  setNewpe("person");
                }}
              ></div>
            </div>
          </section>
          <section className="pebottom">
            <button 
              onClick={() => {
                console.log(newpe,control.permission);
                if(control.permission !== newpe){
                  peUpdate({
                    userid: control.userid,
                    permission: newpe,
                  });
                }else{
                  alert("新权限与当前权限相同");
                }
              }}
            >确认</button>
          </section>
        </PeContainer>
      )}
    </Container>
  );
}

export { PersonRoleUp };
